import React,{ useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginActions } from '../../../actions/admin/LoginActions';

function Logo(props) {
    const sendbirdUser = JSON.parse(localStorage.getItem('s_user'));
    const navigate = useNavigate();
    const [userInfo] = useState(sendbirdUser);
    /**退出登录 */
    const logout = () => {
        //退出前先删除channel句柄
        props.sendbird.removeChannelHandler(props.uuid);
        
        LoginActions.logout();
        navigate('/admin/login');
    }

    return (
        <div className="d-flex justify-content-between align-items-center mb-4">
            <h3 className="mb-0 text-primary">
                <img className="logo-img" src={require('../../../assets/common/img/logo.png')} alt="" />
            </h3>
            <h6 style={{marginTop:'20px'}}>{userInfo.user_id}</h6>
            <div>
                <button className="btn btn-dark" onClick={logout}>로그아웃</button>
            </div>
        </div>
    );
}

const mapStateToProps = ({
    sendbirdReducer: { sendbird, uuid }
}) => {
    return {
        sendbird, uuid
    }
}

export default connect(mapStateToProps)(Logo);