import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import LeavingSearch from '../../eggdome/sidebar/leaving/LeavingSearch';
import Words from './Words';

function Sidebar(props) {
    const defaultClass = 'nav-link mb-2 text-center rightbar-link pointer';

    const [tabPos, setTabPos] = useState('-400px');
    const [currentTab, setCurrentTab] = useState('');
    const [app, setApp] = useState('');

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) setApp(token.application);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('keydown', keyboardEvent);
    }, [app]);// eslint-disable-line react-hooks/exhaustive-deps

    //处理键盘事件
    const keyboardEvent = (e) => {
        if (app === 'eggdome' && e.ctrlKey && e.keyCode === 81) {
            const btnElement = document.querySelector('#eggdome-leaving');
            tabView(btnElement, 'LeavingSearch');
        }

        if (e.keyCode === 27) {
            tabHide();
        }
    }

    /**打开右侧抽屉 */
    const tabView = (e, tab) => {
        let rightbars = document.querySelectorAll('b.rightbar-link');
        for (let i=0;i<rightbars.length;i++) {
            rightbars[i].className = defaultClass;
        }

        if (e.currentTarget) {
            e.currentTarget.className += ' active';
        } else {
            e.classList.add('active')
        }

        setTabPos('54px');
        setCurrentTab(tab);

        if (app === 'eggdome' && tab === 'LeavingSearch') {
            props.setWidth('70%');
        }
    }

    /**关闭右侧抽屉，这个方法在抽屉组件调用 */
    const tabHide = () => {
        let rightbars = document.querySelectorAll('b.rightbar-link');
        for (let i=0;i<rightbars.length;i++) {
            rightbars[i].className = defaultClass;
        }

        setTabPos('-400px');
        setCurrentTab('');

        props.setWidth('100%');
    }

    /**根据被点击菜单定义抽屉组件 */
    const setTab = () => {
        let tab;

        switch (currentTab) {
            case 'Words':
                tab = <Words tabHide={tabHide} />;
                break;
            case 'LeavingSearch':
                tab = <LeavingSearch tabHide={tabHide} />;
                break;
            default:
                tab = '';
                break;
        }

        return tab;
    }

    return (
        <div className={`rightbar d-none d-md-block`}>

            <div className="nav flex-column nav-pills border-start py-xl-4 py-3 h-100">
                <Tooltip placement="left" title="자주쓰는 문구">
                    <b className={defaultClass} onClick={(e)=>{tabView(e, 'Words')}}>
                        <i className="icon iconfont">&#xe6b5;</i>
                    </b>
                </Tooltip>
                
                {app === 'eggdome' && 
                <Tooltip placement="left" title="문의내역 검색 Ctrl+q">
                    <b className={defaultClass} id="eggdome-leaving" onClick={(e)=>{tabView(e, 'LeavingSearch')}}>
                        <i className="icon iconfont">&#xe694;</i>
                    </b>
                </Tooltip>
                }
                
            </div>

            <div className="tab-content py-xl-4 py-3 px-xl-4 px-3" style={{right: tabPos}}>
                {setTab()}
            </div>
            
        </div>
    );
}

export default Sidebar;