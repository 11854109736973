import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { ManagerActions } from '../../../actions/admin/ManagerActions';
import { _CONFIRM_ } from '../../../System';
import styles from './chat.module.css';
import { connect } from 'react-redux';
import { ChannelActions } from '../../../actions/admin/ChannelActions';

function ManagerAdd(props) {
    const [managerList, setManagerList] = useState([]);//管理员原始数据
    const [renderManagers, setRenderManagers] = useState([]);//要渲染的管理员数据
    const [types, setTypes] = useState({});//channel类型
    const [searchKey, setSearchKey] = useState('');//筛选关键字

    const { confirm } = Modal;

    useEffect(() => {
        initTypes();
        getManagers();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**按昵输入的称筛选管理员 */
    useEffect(() => {
        if (searchKey !== '') {
            setRenderManagers(managerList.filter(item=>item.nickname.indexOf(searchKey) !== -1));
        } else {
            setRenderManagers(managerList);
        }
    }, [managerList, searchKey]);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取所有管理员 */
    const getManagers = async () => {
        const res = await ManagerActions.getAllManagers();
        if (res) {
            setManagerList(res.result);
        }
    }

    /**channel 类型数据 */
    const initTypes = () => {
        const types = JSON.parse(localStorage.getItem('types'));
        let _types = {};

        for (let i in types) {
            _types[types[i]['key']] = types[i]['label'];
        }
        
        setTypes(_types);
    }

    /**channel中添加管理员 */
    const addManager = (user_id) => {
        confirm(_CONFIRM_({
            title: '상담원을 추가하시겠습니까?',
            ok: async () => {
                const channel_url = props.current_channel.channel_url;
                const user_ids = [user_id];

                const res = await ChannelActions.inviteChannel(channel_url, user_ids);
                
                if (res.code) {
                    message.error(res.message);
                    return false;
                }

                message.success('SUCCESS');
            }
        }));
    }

    return (
        <div className="d-flex flex-column">
            <div className="header border-bottom pb-4 d-flex justify-content-between">
                <div>
                    <h6 className="mb-0 font-weight-bold">상담원추가</h6>
                    <span className="text-muted">담당 상담원을 추가해주세요</span>
                </div>
                <div>
                    <button className="btn btn-link text-muted close-chat-sidebar" onClick={props.tabHide}><i className="icon iconfont">&#xe64d;</i></button>
                </div>
            </div>
            <div className="body mt-4">

                <div className="form-group input-group-lg search mb-3">
                    <i className={`icon iconfont ${styles.search_icon}`}>&#xe694;</i>
                    <input type="text" className="form-control" placeholder="Search..." onChange={(e) => { setSearchKey(e.target.value) }} />
                </div>

                <ul className="chat-list">
                    {renderManagers.map(item=>(
                        <li key={item.user_id}>
                            <div className="hover_action">
                                <button  className="btn btn-link text-info" onClick={() => {addManager(item.user_id)}}><i className="icon iconfont">&#xe602;</i></button>
                            </div>
                            <b className="card">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="avatar me-3">
                                            <img className="avatar rounded-circle" src={item.headerimg !== '' ? item.headerimg : require('../../../assets/admin/img/headerimg.png')} alt="avatar" />
                                        </div>
                                        <div className="media-body overflow-hidden">
                                            <div className="d-flex align-items-center mb-1">
                                                <h6 className="text-truncate mb-0 me-auto">{item.nickname}</h6>
                                            </div>
                                            <div className="text-truncate">
                                                {item.channel_type_id > 0 ? types[item.channel_type_id] : '총관리자'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b>
                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = ({
    channelReducer: { current_channel }
}) => {
    return {
        current_channel
    }
}

export default connect(mapStateToProps)(ManagerAdd);