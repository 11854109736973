import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';
import { allowedMIME } from '../../Constants';

export const CommonActions = {
    fileUpload: (file, callback) => {
        if (!allowedMIME.includes(file.type) || file.size === 0) {
            callback && callback(false);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            let resStr = reader.result;

            const fileRes = await Requests.post({
                url: EgchatApi.upload_file,
                withAuth: true,
                datas: {
                    file: resStr
                }
            });

            callback && callback(fileRes);
        }

        reader.onerror = (error) => {
            callback && callback(false);
        }
    }
};