import React from 'react';
import styles from './messages.module.css';

function ConsultLeavingOverview(props) {
    if (props.isAdmin) {
        return <div className="msg_spec" id={'msg-content-' + props.message.messageId}>{props.message.message}</div>;
    }

    const messageData = JSON.parse(props.message.data);

    const HOME_PATH = 'https://eggdome.ggook.com/home';
    const overviewData = messageData.hasOwnProperty('consultLeavingOverview') && messageData['consultLeavingOverview'].hasOwnProperty('leavingOverview') ? messageData['consultLeavingOverview']['leavingOverview'] : {};

    return (
        <div className="msg_spec" id={'msg-content-' + props.message.messageId}>
            <div className={styles.overview}>
                <h3>{props.message.message}</h3>
                <div className={styles.overview_list}>
                    <a href={HOME_PATH + '/leaving/list.php?s=41'} target="_blank" rel="noreferrer">
                        <span>견적대기</span>
                        <strong>{overviewData['AA']}</strong>
                    </a>
                    <a href={HOME_PATH + '/leaving/list.php?s=43'} target="_blank" rel="noreferrer">
                        <span>상품결제대기</span>
                        <strong>{overviewData['AC']}</strong>
                    </a>
                    <a href={HOME_PATH + '/leaving/list.php?s=32'} target="_blank" rel="noreferrer">
                        <span>입고대기</span>
                        <strong>{overviewData['BB']}</strong>
                    </a>
                    <a href={HOME_PATH + '/leaving/list.php?s=34'} target="_blank" rel="noreferrer">
                        <span>물류결제대기</span>
                        <strong>{overviewData['BE']}</strong>
                    </a>
                    <a href={HOME_PATH + '/leaving/list.php?s=37'} target="_blank" rel="noreferrer">
                        <span>출고완료</span>
                        <strong>{overviewData['BK']}</strong>
                    </a>
                    <a href={HOME_PATH + '/leaving/list.php?s=31'} target="_blank" rel="noreferrer">
                        <span>미완료신청건</span>
                        <strong>{overviewData['BA']}</strong>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ConsultLeavingOverview;