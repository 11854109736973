import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, message, Radio, Modal } from 'antd';
import DrawLeft from '../../../components/admin/draw/DrawLeft';
import { ChannelTypeActions } from '../../../actions/admin/ChannelTypeAction';

import { _CONFIRM_ } from '../../../System';

function ChannelType(props) {
    const [channelTypeData, setChannelTypeData] = useState([]);//channel type 数据
    const [mode, setMode] = useState('add');//表单模式，add添加，edit编辑
    const [editItem, setEditItem] = useState(null);//待编辑数据
    const [drawOpen, setDrawOpen] = useState(false);//表单打开状态
    const formData = useRef(null);

    const { confirm } = Modal;

    let sending = 0;

    useEffect(() => {
        getChannelTypeData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取channel type数据 */
    const getChannelTypeData = async () => {
        const res = await ChannelTypeActions.list();
        if (res) {
            setChannelTypeData(res.result);
        }
    }

    /**打开数据添加表单 */
    const addForm = () => {
        setMode('add');
        setDrawOpen(true);
    }

    /**打开数据编辑表单 */
    const editForm = (item) => {
        setMode('edit');
        setDrawOpen(true);
        setEditItem(item);
    }

    /**表单关闭 */
    const closeForm = () => {
        setMode('add');
        setDrawOpen(false);
        setEditItem(null);
    }

    /**表单提交，添加和编辑分别处理 */
    const onSubmit = () => {
        formData.current.validateFields().then(async res => {
            if (sending === 1) return false;
            sending = 1;

            if (res.mode === 'add') {
                const response = await ChannelTypeActions.store(res);
                if (response) {
                    message.success(response.message);
                    closeForm();
                    setChannelTypeData([...channelTypeData, response.result]);
                }
            } else {
                const response = await ChannelTypeActions.update(res);
                if (response) {
                    message.success(response.message);
                    closeForm();
                    setChannelTypeData(channelTypeData.map(item=>item.id === response.result.id ? response.result : item)); 
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    /**数据删除 */
    // eslint-disable-next-line
    const delData = (data) => {
        confirm(_CONFIRM_({
            title: '데이터를 삭제하시겠습니까?',
            ok: async () => {
                if (sending === 1) return false;
                sending = 1;

                const res = await ChannelTypeActions.del(data);
                if (res) {
                    message.success(res.message);
                    setChannelTypeData(channelTypeData.filter(item=>item.id !== data.id));
                }

                sending = 0;
            }
        }));
    }

    const form = (
        <Form layout="vertical" hideRequiredMark ref={formData}>
            <Form.Item name="mode" initialValue={mode} hidden={true}><Input /></Form.Item>
            <Form.Item name="id" initialValue={editItem ? editItem.id : ''} hidden={true}><Input /></Form.Item>

            <Form.Item
                name="type_name"
                label="채널분류명(한글)"
                initialValue={editItem ? editItem.type_name : ''}
                rules={[
                    {
                        required: true,
                        message: '한글 채널분류명을 입력해주세요',
                    },
                ]}
            >
                <Input placeholder="한글 채널분류명" disabled={mode === 'edit'} autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="type_name_en"
                label="채널분류명(영문)"
                initialValue={editItem ? editItem.type_name_en : ''}
                rules={[
                    {
                        required: true,
                        message: '영문 채널분류명을 입력해주세요',
                    },
                ]}
            >
                <Input placeholder="영문 채널분류명" disabled={mode === 'edit'} autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="sort"
                label="순서배열"
                initialValue={editItem ? editItem.sort : 9}
                rules={[
                    {
                        type: 'number',
                        message: '숫자를 입력해주세요',
                    },
                ]}
            >
                <InputNumber min={1} max={255} />
            </Form.Item>
            
            <Form.Item name="is_use" label="사용여부" initialValue={editItem ? editItem.is_use : 1}>
                <Radio.Group name="radiogroup">
                    <Radio value={1}>사용함</Radio>
                    <Radio value={0}>사용안함</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    );

    return (
        <div className="tab-pane fade show active" id="channel-type" role="tabpanel">
            <div className="row">
                <div className="col-12">

                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-title mb-0">문의유형</h6>
                        </div>
                        <div className="table-responsive">
                            <table
                                className="table table-border table-hover table-nowrap card-table mb-0">
                                <thead>
                                    <tr>
                                        <th>유형명(한글)</th>
                                        <th>유형명(영문)</th>
                                        <th>순서배열</th>
                                        <th>사용여부</th>
                                    </tr>
                                </thead>
                                <tbody className="font-size-base">
                                    {channelTypeData.map(item=>(
                                        <tr key={item.id}>
                                            <td>{item.type_name}</td>
                                            <td>{item.type_name_en}</td>
                                            <td>{item.sort}</td>
                                            <td>
                                                {item.is_use === 1
                                                    ? <span className="badge bg-success">사용함</span>
                                                    : <span className="badge bg-secondary">사용안함</span>
                                                }
                                            </td>
                                            <td>
                                                <b className="manager-btn pointer mr8" onClick={() => {editForm(item)}}>
                                                    <i className="icon iconfont">&#xe6b0;</i>
                                                </b>
                                                {/* <b className="manager-btn pointer" onClick={() => {delData(item)}}>
                                                    <i className="icon iconfont">&#xe611;</i>
                                                </b> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-4">
                <b className="btn btn-sm btn-primary" onClick={addForm}>문의유형추가</b>
            </p>
            <DrawLeft
                status={drawOpen}
                change={()=>{sending=0}}
                title={`문의유형 ${mode === 'add' ? '추가' : '수정'}`}
                form={form}
                close={closeForm}
                submit={onSubmit}
                btn_name={mode === 'add' ? '추가하기' : '수정하기'}
            />
        </div>
    );
}

export default ChannelType;