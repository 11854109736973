const API_HOST = 'https://egchat.ggook.com';

export const EggdomeApi = {
    leaving_list: API_HOST + '/api/admin/leaving_list',//get
    leaving_view: API_HOST + '/api/admin/leaving_view',//get
    save_memo: API_HOST + '/api/admin/save_memo',//get
    send_budget: API_HOST + '/api/admin/send_budget',//post
    check_level: API_HOST + '/api/admin/check_level',//get
    udpate_level: API_HOST + '/api/admin/udpate_level',//post
    remove_channels: API_HOST + '/api/admin/remove_channels',//delete
    chat_info: API_HOST + '/api/admin/chat_info',//get
    update_channel_url: API_HOST + '/api/admin/update_channel_url',//put
};