import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import SendBird from 'sendbird';
import Main from '../pages/admin/main/Main';
import Chat from '../pages/admin/chat/Chat';
import Setting from '../pages/admin/setting/Setting';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../redux/store';
import '../assets/admin/css/style.min.css';
import '../assets/iconfont/iconfont.css';
import '../assets/admin/css/custom.css';
import { generateUUID } from '../utils/functions';
import ResetChannel from '../pages/admin/main/ResetChannel';

function RouterAdmin(props) {
    const [channelUUID, setChannelUUID] = useState('');//当前channel句柄id

    // 这个Hook 中连接 socket，并把 channelHandler 监听事件传到redux
    useEffect(() => {
        const sendbirdConfig = JSON.parse(localStorage.getItem('sendbird'));
        const sendbirdUser = JSON.parse(localStorage.getItem('s_user'));
        const sendbird = new SendBird({ appId: sendbirdConfig.app_id });
        const channelHandler = new sendbird.ChannelHandler();
        const _channelUUID = generateUUID();

        //首先清除已有的socket句柄
        sendbird.removeChannelHandler(channelUUID);

        // sendbird 对象设置为全局状态
        store.dispatch({
            type: 'set_sendbird',
            payload: sendbird
        });

        // sendbird 的uuid设置为全局状态
        store.dispatch({
            type: 'set_uuid',
            payload: _channelUUID
        });

        // 连接 socket
        sendbird.connect(sendbirdUser.user_id, sendbirdUser.access_token, (user, error) => {
            // console.log('connect error:', error);
            // console.log('socket connected:', user);
        });

        //监听消息
        channelHandler.onMessageReceived = (channel, message) => {
            store.dispatch({
                type: 'set_message_receive',
                payload: {channel, message}
            });
        }

         //监听发送到channel
        channelHandler.onDeliveryReceiptUpdated = (channel) => {
            store.dispatch({
                type: 'set_delivery_receipt',
                payload: {channel}
            });
        }

        //监听对方已读
        channelHandler.onReadReceiptUpdated = (channel) => {
            store.dispatch({
                type: 'set_read_receipt',
                payload: {channel}
            });
        }

        // channelHandler.onMessageUpdated = (channel, message) => {}

          //监听退回消息
        channelHandler.onMessageDeleted = (channel, messageId) => {
            store.dispatch({
                type: 'set_message_delete',
                payload: {channel, messageId}
            });
        }

        //监听channel字段变更(custom_type)
        channelHandler.onChannelChanged  = (channel) => {
            store.dispatch({
                type: 'set_channel_change',
                payload: {channel}
            });
        }

         //监听聊天群 添加一个人
        channelHandler.onUserReceivedInvitation  = (channel, user) => {
            store.dispatch({
                type: 'set_user_invited',
                payload: {channel}
            });
        }

        //监听聊天群 踢出一人
        channelHandler.onUserLeft  = (channel, user) => {
            store.dispatch({
                type: 'set_user_left',
                payload: {channel}
            });
        }

        setChannelUUID(_channelUUID);
        sendbird.addChannelHandler(_channelUUID, channelHandler);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Routes>
                    <Route exact path="/" element={<Main />} />
                    <Route exact path="/chat/:state" element={<Chat />} />
                    <Route exact path="/setting" element={<Setting />} />
                    <Route exact path="/reset/:c_id" element={<ResetChannel />} />
                </Routes>
            </PersistGate>
        </Provider>
    );
}

export default RouterAdmin;