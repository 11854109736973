const API_HOST = 'https://egchat.ggook.com';

export const EgchatApi = {
    //登录授权相关api
    login: API_HOST + '/api/admin/auth',//post
    refresh_token: API_HOST + '/api/admin/refresh_token',//post
    fcm_token: API_HOST + '/api/admin/fcm_token',//post

    //首页相关api
    profile: API_HOST + '/api/admin/profile',//get
    daily_chat: API_HOST + '/api/admin/daily_chat',//get

    //常用句子api
    words_list: API_HOST + '/api/admin/words',//get
    words_store: API_HOST + '/api/admin/words',//post
    words_del: id => API_HOST + `/api/admin/words/${id}`,//delete

    //channel相关api
    channel_type_list: API_HOST + '/api/admin/channel_type',//get
    channel_type_store: API_HOST + '/api/admin/channel_type',//post
    channel_type_update: id => API_HOST + `/api/admin/channel_type/${id}`,//put
    channel_type_del: id => API_HOST + `/api/admin/channel_type/${id}`,//delete
    channel_state: API_HOST + '/api/admin/channel_state',//post
    channel_state_delete: API_HOST + '/api/admin/channel_state_delete',//delete

    //管理员相关api
    manager_list: API_HOST + '/api/admin/manager',//get
    manager_store: API_HOST + '/api/admin/manager',//post
    manager_update: id => API_HOST + `/api/admin/manager/${id}`,//put
    manager_del: id => API_HOST + `/api/admin/manager/${id}`,//delete
    replace_managers: API_HOST + '/api/admin/replace_managers',//get
    all_managers: API_HOST + '/api/admin/all_managers',//get

    //自动回复相关api
    bot_message_list: API_HOST + '/api/admin/bot_message',//get
    bot_message_store: API_HOST + '/api/admin/bot_message',//post
    bot_message_update: id => API_HOST + `/api/admin/bot_message/${id}`,//put
    bot_message_del: id => API_HOST + `/api/admin/bot_message/${id}`,//delete

    //chat相关api
    upload_file: API_HOST + '/api/admin/upload_file',//post
    send_fcm: API_HOST + '/api/admin/send_fcm',//post
};