import React, { useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/admin/login/Login';
import RouterAdmin from './RouterAdmin';
import RouterUser from './RouterUser';

function Router(props) {
    const [adminAuth, setAdminAuth] = useState(localStorage.getItem('token'));

    return (
        <HashRouter>
            <Routes>
                <Route exact path="/admin/login" element={<Login set_admin_auth={setAdminAuth} />} />
                <Route path="/admin/*" element={
                    adminAuth
                    ?<RouterAdmin />
                    :<Navigate to="/admin/login" />}
                />
                <Route path="/user/*" element={<RouterUser />} />
            </Routes>
        </HashRouter>
    );
}

export default Router;