import { message } from 'antd';
import { allowedMIME } from '../../Constants';
import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';
import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';

export const ChatActions = {
    /**
     * 删除已发消息
     * @returns 
     */
    deleteMessage: async (channel_url, message_id) => {
        const response = await SendbirdReq.delete({
            url: SendbirdApi.delete_message(channel_url, message_id),
        });

        if (response.code) {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 获取表情包
     * @returns 
     */
    emojis: async () => {
        const response = await SendbirdReq.get({
            url: SendbirdApi.emojis,
        });

        return response.emojis.filter(item => parseInt(item.key));
    },

    /**
     * 发送消息
     * @param {*} channel_url 
     * @param {*} msgs 
     * @param {*} callback 
     * @returns 
     */
    sendMessage: async (channel_url, msgs, yy_obj, callback) => {
        if (msgs.length === 0) {
            callback();
            return;
        }

        let msg = msgs.shift();
        if(Object.keys(yy_obj).length > 0) {
           const yyMsg = JSON.stringify({ yyInfo : yy_obj});
           msg = {...msg,data:yyMsg};
        }
        const sendbirdUser = JSON.parse(localStorage.getItem('s_user'));

        if (msg['type'] === 'text') {
            await ChatActions._sendText(channel_url, sendbirdUser.user_id, msg);
            await ChatActions.sendMessage(channel_url, msgs, yy_obj, callback);
        }

        if (msg['type'] === 'image') {
            await ChatActions._sendImage(channel_url, sendbirdUser.user_id, msg);
            await ChatActions.sendMessage(channel_url, msgs, yy_obj, callback);
        }

        if (msg['type'] === 'file') {
            await ChatActions._sendFile(channel_url, sendbirdUser.user_id, msg);
            await ChatActions.sendMessage(channel_url, msgs, yy_obj, callback);
        }
    },

    /**发送文本消息 */
    _sendText: async (channel_url, user_id, msg) => {
        return await SendbirdReq.post({
            url: SendbirdApi.messages(channel_url),
            datas: {
                message: msg['value'],
                message_type: 'MESG',
                user_id: user_id,
                custom_type: 'msg',
                data:msg['data'] ? msg['data'] : ''
            }
        });
    },

    /**发送截图 */
    _sendImage: async (channel_url, user_id, msg) => {
        const imageRes = await Requests.post({
            url: EgchatApi.upload_file,
            withAuth: true,
            datas: {
                file: msg['value']
            }
        });

        if (imageRes.code !== '') return false;
        let yyMsg;
        if (msg['data']){
            const yyObj = JSON.parse(msg['data']);
            yyMsg = JSON.stringify({...yyObj, clipboardImages: [imageRes.result.url]});
        }else {
            yyMsg = JSON.stringify({ clipboardImages: [imageRes.result.url] })
        }
        return await SendbirdReq.post({
            url: SendbirdApi.messages(channel_url),
            datas: {
                message: '',
                message_type: 'MESG',
                user_id: user_id,
                custom_type: 'clipboardImages',
                data: yyMsg
            }
        });
    },

    /**发送文件 */
    _sendFile: async (channel_url, user_id, msg) => {
        const file = msg['value'];

        if (!allowedMIME.includes(file.type) || file.size === 0) {
            return false;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            let resStr = reader.result;

            const fileRes = await Requests.post({
                url: EgchatApi.upload_file,
                withAuth: true,
                datas: {
                    file: resStr
                }
            });

            if (fileRes.code !== '') return false;

            await SendbirdReq.post({
                url: SendbirdApi.messages(channel_url),
                datas: {
                    message: fileRes.result.url,
                    message_type: 'FILE',
                    user_id: user_id,
                    custom_type: 'attachmentFilename',
                    url: fileRes.result.url,
                    file_name: file.name,
                    file_type: file.type,
                    data:msg['data'] ? msg['data'] : ''
                }
            });
        }

        reader.onerror = (error) => {
            console.log(error);
        };
    },

    /**发送fcm */
    sendFcm: async (param) => {
        const response = await Requests.post({
            url: EgchatApi.send_fcm,
            datas: param,
            withAuth: true,
        });

        if (response.code) {
            message.error(response.message);
            return false;
        }
    }
};