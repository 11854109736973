export const CONSTANTS = {
    'HOST': window.location.origin,
};

//允许上传的文件（用于获取文件图标）
export const allowedFiles = {
    'plain': require('./assets/common/img/txt.png'),
    'doc': require('./assets/common/img/word.png'),
    'docx': require('./assets/common/img/word.png'),
    'xls': require('./assets/common/img/excel.png'),
    'xlsx': require('./assets/common/img/excel.png'),
    'ppt': require('./assets/common/img/ppt.png'),
    'pptx': require('./assets/common/img/ppt.png'),
    'pdf': require('./assets/common/img/pdf.png'),
    'zip': require('./assets/common/img/zip.png'),
    'rar': require('./assets/common/img/rar.png')
};

//允许上传的视频（用于获取文件图标）
export const allowedVideo = ['mov', 'quicktime', 'avi', 'mp4'];

//允许上传的mime类型
export const allowedMIME = [
    'text/plain',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/webp',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'video/quicktime',
    'video/avi',
    'video/mp4',
    'application/x-zip-compressed',
    'application/zip',
    'application/x-rar-compressed',
    'application/rar',
];

//表情图片
export const EMOJI = {
    symbol: '#^.^',
    symbolReg: '#\\^\\.\\^',
    link: (key) => {
        const emoji = key.replace(EMOJI.symbol, '');
        return '<img src="https://emojikeyboard.io/img/img-apple-64/1f'+emoji+'.png" class="emoji" alt="" />';
    }
};