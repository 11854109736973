export const unreadMessageReducer = (prevState={unread:false}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_unread':
            let newState = {...prevState};
            newState.unread = payload;
            return newState;
        default: 
            return prevState;
    }
}