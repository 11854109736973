import React, { useEffect, useState } from 'react';
import { message, DatePicker } from 'antd';
import Waiting from './Waiting';
import styles from './leaving.module.css';
import { EggdomeActions } from '../../../../actions/admin/EggdomeAction';
import LeavingList from './LeavingList';
import LeavingView from './LeavingView';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { fillZero, timeFormat } from '../../../../utils/functions';

function LeavingSearch(props) {
    const endTimeStamp = new Date().getTime();
    const startTimeStamp = endTimeStamp - 86400 * 1000 * 30;

    const [keyword, setKeyword] = useState('');
    const [listLeavings, setListLeavings] = useState([]);
    const [leavingData, setLeavingData] = useState([]);
    const [searchStatus, setSearchStatus] = useState('');
    const [startTime, setStartTime] = useState(timeFormat(startTimeStamp));
    const [endTime, setEndTime] = useState(timeFormat(endTimeStamp));

    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';

    dayjs.extend(customParseFormat);

    useEffect(() => {
        document.querySelector('#leaving-keyword').focus();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const searchLeaving = async () => {
        // setSearchStatus('search');
        if (keyword === '') {
            message.error('문의번호를 입력해주세요.');
            setSearchStatus('');
            return false;
        }

        const res = await EggdomeActions.leavingList({keyword: keyword, starttime: startTime, endtime: endTime});

        if (!res || res.result.length === 0) {
            message.error('검색된 데이터가 없습니다.');
            setSearchStatus('');
            return false;
        }

        setListLeavings(res.result);
        setSearchStatus('list');
    }

    const leavingView = async (li_id) => {
        const res = await EggdomeActions.leavingView({li_id});

        if (res) {
            setLeavingData(res.result);
            setSearchStatus('view');
        }
    }

    const loadComponent = () => {
        let component;
        switch (searchStatus) {
            case 'list':
                component = <LeavingList datas={listLeavings} leavingView={leavingView} />;
                break;
            case 'view':
                component = <LeavingView data={leavingData} reback={searchLeaving} />;
                break;
            default:
                component = <Waiting />;
        }

        return component;
    }

    /**  键盘事件，enter查询数据 */
     const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchLeaving();
        }
    }

    /**设置搜索时间范围 */
    const setTime = (date) => {
        const _start = date[0]['$y'] + '-' + fillZero(date[0]['$M'] + 1) + '-' + fillZero(date[0]['$D']);
        const _end = date[1]['$y'] + '-' + fillZero(date[1]['$M'] + 1) + '-' + fillZero(date[1]['$D']);

        setStartTime(_start);
        setEndTime(_end);
    }

    return (
        <div id="tab-leaving" role="tabpanel" className={`${styles.tab} scroll-light`}>
            <div className={styles.tab_head}>
                <div className="header border-bottom d-flex justify-content-between align-items-center p-1">
                    <div>
                        <h6 className="mb-0 font-weight-bold">문의내역 검색</h6>
                    </div>
                    <div>
                        <button className="btn btn-link close-sidebar text-muted" onClick={props.tabHide}><i className="icon iconfont">&#xe64d;</i></button>
                    </div>
                </div>
                <div className="body mt-1">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="form-group mt-3 mb-3">
                                <input type="text" value={keyword} className="form-control" id="leaving-keyword" placeholder="문의번호,신청자명,ID,상호명 검색가능" onInput={(e) => {setKeyword(e.target.value)}} onKeyDown={_handleKeyDown} />
                            </div>
                            <div className="form-group mt-3 mb-3">
                                <RangePicker size="large" defaultValue={[dayjs(startTime, dateFormat), dayjs(endTime, dateFormat)]} format={dateFormat} onChange={(date) => {setTime(date)}} style={{width: '100%'}}/>
                            </div>
                            <div className="align-right">
                                <button className="btn btn-primary" onClick={searchLeaving}>검색</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {loadComponent()}

        </div>
    );
}

export default LeavingSearch;