import { legacy_createStore, combineReducers } from 'redux';
import { channelReducer } from './reducers/ChannelReducer';
import { sendbirdReducer } from './reducers/SendbirdReducer';
import { messageReceiveReducer } from './reducers/CH_MessageReceiveReducer';
import { deliveryReceiptReducer } from './reducers/CH_DeliveryReceiptReducer';
import { readReceiptReducer } from './reducers/CH_ReadReceiptReducer';
import { messageDeleteReducer } from './reducers/CH_MessageDeleteReducer';
import { wordReducer } from './reducers/WordReducer';
import { unreadMessageReducer } from './reducers/UnreadMessageReducer';
import { channelChangeReducer } from './reducers/CH_ChannelChangeReducer';
import { userInvitedReducer } from './reducers/CH_UserInvitedReducer';
import { userLeftReducer } from './reducers/CH_UserLeftReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
//引入redux可视化谷歌插件
import {composeWithDevTools} from 'redux-devtools-extension';

const persistConfig = {
    key: 'egchat_reducers',
    storage,
    whitelist: [
        'channelReducer'
    ]
}

const reducer = combineReducers({
    channelReducer,
    sendbirdReducer,
    messageReceiveReducer,
    deliveryReceiptReducer,
    readReceiptReducer,
    messageDeleteReducer,
    wordReducer,
    unreadMessageReducer,
    channelChangeReducer,
    userInvitedReducer,
    userLeftReducer
});

//reducer进行持久化处理
const persistedReducer = persistReducer(persistConfig, reducer)

const store = legacy_createStore(persistedReducer,composeWithDevTools());
const persistor = persistStore(store);

export { store, persistor };