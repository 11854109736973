import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import DrawLeft from '../../../components/admin/draw/DrawLeft';
import { BotMessageActions } from '../../../actions/admin/BotMessageActions';

import { _CONFIRM_ } from '../../../System';

function ChatBot(props) {
    const [botMessages, setBotMessages] = useState([]);//channel type 数据
    const [channelTypeData, setChannelTypeData] = useState([]);//channel type 数据
    const [channelTypeMap, setChannelTypeMap] = useState([]);//channel type 映射表
    const [mode, setMode] = useState('add');//表单模式，add添加，edit编辑
    const [editItem, setEditItem] = useState(null);//待编辑数据
    const [drawOpen, setDrawOpen] = useState(false);//表单打开状态
    const formData = useRef(null);

    const { confirm } = Modal;
    const { TextArea } = Input;

    let sending = 0;

    useEffect(() => {
        getBotMessageData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取manager数据 */
    const getBotMessageData = async () => {
        const res = await BotMessageActions.list();
        if (res) {
            setBotMessages(res.result.messages);

            const _channelType = res.result.types;
            setChannelTypeData(_channelType);

            let _channelTypeMap = {};
            _channelType.forEach((item) => {
                _channelTypeMap[item.value] = item.label;
            });
            setChannelTypeMap(_channelTypeMap);
        }
    }

    /**打开数据添加表单 */
    const addForm = () => {
        setMode('add');
        setDrawOpen(true);
    }

    /**打开数据编辑表单 */
    const editForm = (item) => {
        setMode('edit');
        setDrawOpen(true);
        setEditItem(item);
    }

    /**表单关闭 */
    const closeForm = () => {
        setMode('add');
        setDrawOpen(false);
        setEditItem(null);
    }

    /**表单提交，添加和编辑分别处理 */
    const onSubmit = () => {
        formData.current.validateFields().then(async res => {
            if (sending === 1) return false;
            sending = 1;

            if (res.mode === 'add') {
                const response = await BotMessageActions.store(res);
                if (response) {
                    message.success(response.message);
                    closeForm();
                    setBotMessages([response.result, ...botMessages]);
                }
            } else {
                const response = await BotMessageActions.update(res);
                if (response) {
                    message.success(response.message);
                    closeForm();
                    setBotMessages(botMessages.map(item => item.id === response.result.id ? response.result : item));
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    /**数据删除 */
    const delData = (data) => {
        confirm(_CONFIRM_({
            title: '데이터를 삭제하시겠습니까?',
            ok: async () => {
                if (sending === 1) return false;
                sending = 1;

                const res = await BotMessageActions.del(data);
                if (res) {
                    message.success(res.message);
                    setBotMessages(botMessages.filter(item => item.id !== data.id));
                }

                sending = 0;
            }
        }));
    }

    const form = (
        <Form layout="vertical" hideRequiredMark ref={formData}>
            <Form.Item name="mode" initialValue={mode} hidden={true}><Input /></Form.Item>
            <Form.Item name="id" initialValue={editItem ? editItem.id : ''} hidden={true}><Input /></Form.Item>

            <Form.Item
                name="channel_type_id"
                label="문의유형"
                initialValue={editItem ? editItem.channel_type_id : ''}
                rules={[
                    {
                        required: true,
                        message: '문의유형을 선택해주세요',
                    },
                ]}
            >
                <Select options={channelTypeData} />
            </Form.Item>

            <Form.Item
                name="question"
                label="문의내용"
                initialValue={editItem && editItem.question !== '__init__' ? editItem.question : ''}
            >
                <TextArea placeholder="문의내용 (입력하지 않으면 인사메세지로 발송됩니다)" allowClear />
            </Form.Item>

            <Form.Item
                name="answer"
                label="답변내용"
                initialValue={editItem ? editItem.answer : ''}
                rules={[
                    {
                        required: true,
                        message: '답변내용을 입력해주세요',
                    },
                ]}
            >
                <TextArea placeholder="답변내용" style={{ height: 120 }} allowClear />
            </Form.Item>
        </Form>
    );

    return (
        <div className="tab-pane fade show active" id="chat-bot" role="tabpanel">
            <div className="row">
                <div className="col-12">

                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-title mb-0">자동응답</h6>
                        </div>
                        <div className="list-group custom list-group-flush my-md-3">

                            {botMessages.map(item=>(
                                <div className="list-group-item" key={item.id}>
                                    <div className="row align-items-center">
                                        <div className="col-5 ms-2">
                                            <h6 className="mb-1">{item.question !== '__init__' ? item.question : '인사메세지'}</h6>
                                            <p className="small text-muted mb-0">{item.answer}</p>
                                        </div>
                                        <div className="col-auto ms-auto mr-md-3">{channelTypeMap[item.channel_type_id]}</div>
                                        <div className="col-auto">
                                            <b className="manager-btn pointer mr8" onClick={() => { editForm(item) }}>
                                                <i className="icon iconfont">&#xe6b0;</i>
                                            </b>
                                            <b className="manager-btn pointer" onClick={() => { delData(item) }}>
                                                <i className="icon iconfont">&#xe611;</i>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-4">
                <b className="btn btn-sm btn-primary" onClick={addForm}>자동응답추가</b>
            </p>
            <DrawLeft
                status={drawOpen}
                change={() => { sending = 0 }}
                title={`자동응답 ${mode === 'add' ? '추가' : '수정'}`}
                form={form}
                close={closeForm}
                submit={onSubmit}
                btn_name={mode === 'add' ? '추가하기' : '수정하기'}
            />
        </div>
    );
}

export default ChatBot;