import { message } from 'antd';
import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';
import { store } from '../../redux/store';

export const LoginActions = {
    /**
     * 管理员登录
     * @param {*} params {app, username, password}
     * @returns 
     */
    auth: async (params) => {
        let app = params.app;
        let username = params.username;
        let password = params.password;

        if (username === '') {
            message.error('아이디를 입력해 주세요.');
            return false;
        }

        if (password === '') {
            message.error('비밀번호를 입력해 주세요.');
            return false;
        }

        const response = await Requests.post({
            url: EgchatApi.login,
            datas: {app, username, password}
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        localStorage.setItem('sendbird', JSON.stringify(response.result.sendbird));

        //服务器授权成功后获取sendbird用户信息
        const resSendbird = await LoginActions.getSendbirdUser(username);
        if (resSendbird.code !== '') {
            message.error(resSendbird.message);
            localStorage.removeItem('sendbird');
            return false;
        }

        localStorage.setItem('token', JSON.stringify(response.result.token));
        localStorage.setItem('types', JSON.stringify(response.result.types));
        localStorage.setItem('channel_type', JSON.stringify(response.result.channel_type));

        //登录后更新 fcm token
        const fcmConfig = response.result.fcm;
        LoginActions.updateFCMToken(fcmConfig);

        return response;
    },

    /**
     * 更新用户的 fcm token
     * @param String accessToken 
     * @param {*} config 
     */
    updateFCMToken: async (config) => {
        const app_vapid = config.app_vapid;
        delete config.app_vapid;

        initializeApp(config);

        const messaging = getMessaging();

        await getToken(messaging, { vapidKey: app_vapid })
            .then(async (currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    // console.log('fcm token: '+currentToken);
                    await Requests.post({
                        url: EgchatApi.fcm_token,
                        withAuth: true,
                        datas: {'fcm_web_token': currentToken}
                    });
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
    },

    /**
     * 获取sendbird用户数据（没有创建一个），并保存在本地
     * @param String app 
     * @param String username 
     */
    getSendbirdUser: async (username) => {
        let response;
        let datas = {
            'code': '',
            'message': 'SUCCESS'
        };

        try {
            response = await SendbirdReq.get({
                url: SendbirdApi.get_user(username)
            });
        } catch (err) {
            datas['code'] = err.response.data.code;
            datas['message'] = err.response.data.message;
            return datas;
        }
        
        localStorage.setItem('s_user', JSON.stringify(response));

        return datas;
    },

    /**
     * 退出登录
     */
    logout: () => {
        store.dispatch({
            type: 'set_current_channel',
            payload: null
        });

        store.dispatch({
            type: 'set_channel_change',
            payload: null
        });

        store.dispatch({
            type: 'set_delivery_receipt',
            payload: null
        });
        
        store.dispatch({
            type: 'set_message_receive',
            payload: null
        });

        store.dispatch({
            type: 'set_read_receipt',
            payload: null
        });

        store.dispatch({
            type: 'set_message_receive',
            payload: null
        });

        store.dispatch({
            type: 'set_user_left',
            payload: null
        });

        store.dispatch({
            type: 'set_sendbird',
            payload: null
        });

        store.dispatch({
            type: 'set_uuid',
            payload: null
        });

        store.dispatch({
            type: 'set_unread',
            payload: false
        });

        store.dispatch({
            type: 'set_word',
            payload: null
        });
        
        localStorage.removeItem('token');
        localStorage.removeItem('sendbird');
        localStorage.removeItem('types');
        localStorage.removeItem('channel_type');
        localStorage.removeItem('s_user');
    }
};