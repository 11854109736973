import axios from 'axios';
import { CONSTANTS } from '../Constants';
import { EgchatApi } from './egchat.api';
import { jsonToUrlParam } from './functions';

export const Requests = {
    /**
     * 组合 header 数据
     * @param {*} params {extraHeader, withAuth}
     * @returns Json Object
     */
    headers: (params) => {
        let extraHeader = params.extraHeader ? params.extraHeader : {};

        let headerData = {
            ...extraHeader,
            'Content-Type': 'application/json; charset=utf8'
        }

        if (params.withAuth) {
            let token = JSON.parse(localStorage.getItem('token'));
            let access_token = token ? token.access_token : '';
            let authorization = 'bearer ' + access_token;

            headerData['Authorization'] = authorization;
        }

        return headerData;
    },

    /**
     * refresh token 认证失败，删除 token 等数据后跳转到登录页面
     */
    authFailed: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('sendbird');
        localStorage.removeItem('types');
        localStorage.removeItem('channel_type');
        localStorage.removeItem('s_user');
        window.location.href = CONSTANTS.HOST + '/#/admin/login';
    },

    /**
     * 用 refresh_token 重新请求 access_token，并保存在 localStorage
     */
    refreshToken: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
            
        if (!token || !token.refresh_token) {
            Requests.authFailed();
            return;
        }

        let refreshRes = await axios.post(
            EgchatApi.refresh_token,
            {refresh_token: token.refresh_token},
            {headers: Requests.headers({extraHeader: {}, withAuth: false})}
        );

        let refreshData = refreshRes.data;

        if (refreshData.code !== 'S200000' && refreshData.code !== '') {
            Requests.authFailed();
            return;
        }

        if (refreshData.code === 'S200000') {//之前的请求已经更新了token, 无需做其他操作
            return;
        }

        localStorage.setItem('token', JSON.stringify(refreshData.result.token));
        localStorage.setItem('sendbird', JSON.stringify(refreshData.result.sendbird));
        localStorage.setItem('types', JSON.stringify(refreshData.result.types));
        localStorage.setItem('channel_type', JSON.stringify(refreshData.result.channel_type));
    },

    /**
     * GET 模式请求 api
     * @param {*} params {url, headers, withAuth}
     * @returns Json Object
     */
    get: async (params, depth=0) => {
        //递归深度保护，递归2次后就中断请求
        if (depth++ > 1) return {code: 'E400101', message: 'auth failed'};

        //url参数组合
        let url = params.url;
        if (params.datas) {
            url += '?'+jsonToUrlParam(params.datas)
        }

        let result = await axios.get(
            url,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        );

        let datas = result.data;

        if (datas.code === 'E400101') {//code 为 E400101 表示 access_token 无效，需要用 refresh_token 重新请求 access_token
            await Requests.refreshToken();
            return await Requests.get(params, depth);
        }
        return datas;
    },

    /**
     * POST 模式请求 api
     * @param {*} params {url, datas, headers, withAuth}
     * @returns Json Object
     */
    post: async (params, depth=0) => {
        //递归深度保护，递归2次后就中断请求
        if (depth++ > 1) return {code: 'E400101', message: 'auth failed'};

        let result = await axios.post(
            params.url,
            params.datas,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        );

        let datas = result.data;

        if (datas.code === 'E400101') {//code 为 E400101 表示 access_token 无效，需要用 refresh_token 重新请求 access_token
            await Requests.refreshToken();
            return await Requests.post(params, depth);
        }

        return datas;
    },

    /**
     * PUT 模式请求 api
     * @param {*} params {url, datas, headers, withAuth}
     * @returns Json Object
     */
    put: async (params, depth=0) => {
        //递归深度保护，递归2次后就中断请求
        if (depth++ > 1) return {code: 'E400101', message: 'auth failed'};

        let result = await axios.put(
            params.url,
            params.datas,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        );

        let datas = result.data;

        if (datas.code === 'E400101') {//code 为 E400101 表示 access_token 无效，需要用 refresh_token 重新请求 access_token
            await Requests.refreshToken();
            return await Requests.put(params, depth);
        }

        return datas;
    },

    /**
     * DELETE 模式请求 api
     * @param {*} params {url, datas, headers, withAuth}
     * @returns Json Object
     */
    delete: async (params, depth=0) => {
        //递归深度保护，递归2次后就中断请求
        if (depth++ > 1) return {code: 'E400101', message: 'auth failed'};

        let param = params.datas ? params.datas : {}
        let result = await axios.delete(
            params.url,
            {
                data: param,
                headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})
            }
        );

        let datas = result.data;

        if (datas.code === 'E400101') {//code 为 E400101 表示 access_token 无效，需要用 refresh_token 重新请求 access_token
            await Requests.refreshToken();
            return await Requests.delete(params, depth);
        }

        return datas;
    }
}