import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Dropdown } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Logo from '../../../components/admin/logo/Logo';
import styles from './chat.module.css';
import { ChannelActions } from '../../../actions/admin/ChannelActions';
import { formatChannelData, getChannelHeaderImage, getUserId, timestampToTime } from '../../../utils/functions';
import { connect } from 'react-redux';

function Channel(props) {
    const params = useParams();
    const [channels, setChannels] = useState([]);
    const [expiredChannels, setExpiredChannels] = useState([]);
    const [renderChannels, setRenderChannels] = useState([...channels]);
    const [loading, setLoading] = useState(true);
    const [channelState, setChannelState] = useState({
        total: 0,
        wait: 0,
        going: 0,
        end: 0,
    });
    const [search, setSearch] = useState('');//channel筛选词
    const [currentType, setCurrentType] = useState('전체');//channel筛选词

    const channel_type = JSON.parse(localStorage.getItem('channel_type'));
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    useEffect(() => {
        getChannelData();
        initCurrentChannel();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        channelFilterAndRender(channels);
    }, [channels, params, search, currentType]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理已过期（180天以上没有聊天记录）的channel */
    useEffect(() => {
        removeChannel(expiredChannels);
    }, [expiredChannels]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听接收消息 */
    useEffect(() => {
        messageReceive();
    }, [props.message_receive]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听channel状态修改 */
    useEffect(() => {
        channelChange();
    }, [props.channel_change]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听channel中的管理员更新 */
    useEffect(() => {
        memberChange(props.user_invited_channel);
    }, [props.user_invited_channel]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听channel中的管理员更新 */
    useEffect(() => {
        memberChange(props.user_left_channel);
    }, [props.user_left_channel]);// eslint-disable-line react-hooks/exhaustive-deps

    /**加载时current_channel的未读消息为0[不删除标记] */
    const initCurrentChannel = async () => {
        if (props.current_channel) {
            props.current_channel.unread_message_count = 0;
            setCurrentChannel(props.current_channel);
        }
    }

    /**处理接收新消息事件 */
    const messageReceive = () => {
        if (!props.message_receive) return false;

        const channelListen = formatChannelData(props.message_receive.channel);
        const channelExists = channels.find(item => item.channel_url === channelListen.channel_url);

        if (channelExists) {//处理列表中已存在的channel
            if (props.current_channel && channelExists.channel_url === props.current_channel.channel_url) {//新消息channel和当前channel相同时，对于管理员群体未读消息设置为0
                channelListen.unread_message_count = 0;
                const members = channelExists.members.filter(item => item.user_id !== getUserId(channelExists));
                ChannelActions.markAsRead(channelExists.channel_url, members);
            } else {
                channelListen.unread_message_count++;
            }

            setChannels([channelListen, ...channels.filter(item => item.channel_url !== channelExists.channel_url)]);
        } else {
            setChannels([channelListen, ...channels]);
        }
    }

    /**处理改变channel状态事件 */
    const channelChange = () => {
        if (!props.channel_change) return false;

        const channelListen = formatChannelData(props.channel_change.channel);
        let _channelState = { ...channelState };

        //对应增加channel状态数量（新）
        channelListen.custom_type === 'wait' && _channelState.wait++;
        channelListen.custom_type === 'going' && _channelState.going++;
        (channelListen.custom_type === 'end' || channelListen.custom_type === '') && _channelState.end++;

        setChannels(channels.map(item => {
            if (item.channel_url === channelListen.channel_url) {
                //对应增加channel状态数量（原）
                item.custom_type === 'wait' && _channelState.wait--;
                item.custom_type === 'going' && _channelState.going--;
                (item.custom_type === 'end' || item.custom_type === '') && _channelState.end--;

                //重写原channel状态
                item.custom_type = channelListen.custom_type;
                item.data = channelListen.data;
            }

            return item;
        }));

        setChannelState(_channelState);

        //修改redux中的current_channel
        if (props.current_channel && channelListen.channel_url === props.current_channel.channel_url) {
            let currentChannel = { ...props.current_channel };
            currentChannel.custom_type = channelListen.custom_type;
            // currentChannel.data = channelListen.data;
            currentChannel.name = channelListen.name;
            // currentChannel.members = [...channelListen.members];

            props.setCurrentChannel(currentChannel);
        }
    }

    /**处理channel中管理员切换事件 */
    const memberChange = (changed_channel) => {
        if (!props.current_channel || !changed_channel) return false;

        const channelListen = formatChannelData(changed_channel.channel);

        //修改redux中的current_channel
        if (props.current_channel && channelListen.channel_url === props.current_channel.channel_url) {
            let currentChannel = { ...props.current_channel };
            currentChannel.members = [...channelListen.members];

            props.setCurrentChannel(currentChannel);
        }
    }

    /**channel 列表及状态统计数据 */
    const getChannelData = async () => {
        const channelData = await ChannelActions.channelList();

        setChannels(channelData.channels);
        setExpiredChannels(channelData.expired);
        setChannelState({
            total: channelData.total,
            wait: channelData.wait,
            going: channelData.going,
            end: channelData.end,
        });
        setLoading(false);
    }

    /**channel 根据条件筛选并渲染 */
    const channelFilterAndRender = (channelData) => {
        setRenderChannels(channelData.filter((item) => {
            let result = {
                state: true,
                search: true,
                type: true
            };

            if (['wait', 'going'].includes(params.state) && item.custom_type !== params.state) {
                result.state = false;
            }

            if (params.state === 'end' && !['', 'end'].includes(item.custom_type)) {
                result.state = false;
            }

            if (search !== '' && item.name.indexOf(search) === -1) {
                result.search = false;
            }

            const channel_data = item.data.split('|');
            if (!['', '전체'].includes(currentType) && channel_data[1] !== currentType) {
                result.type = false;
            }

            return (
                result.state
                && result.search
                && result.type
            );
        }));
    }

    /**
     * 点击channel事件，未读消息设置为0，重新设置current_channel
     * @param {*} channel 
     */
    const setCurrentChannel = async (channel) => {
        //未读消息设置为0
        setChannels(channels.map(item => {
            if (item.channel_url === channel.channel_url) {
                item.unread_message_count = 0;
            }
            return item;
        }));

        //对于用户发送的消息，sendbird中点击channel的未读消息设置为0
        const members = [...channel.members];
        await ChannelActions.markAsRead(channel.channel_url, members.filter(item => item.user_id !== getUserId(channel)));

        props.setCurrentChannel(channel);
    }

    /**把文字改为可编辑模式 */
    const editChannelName = (e) => {
        let obj = e.target;

        obj.style = '';
        obj.setAttribute('contentEditable', 'true');
        obj.focus();
    }


    /** 更新 sendbird 中的 channel_name */
    const updateChannelName = async (e) => {
        const obj = e.target;
        const channel = JSON.parse(obj.getAttribute('channel'));
        const regex = /(<([^>]+)>)/ig
        let channel_name = obj.innerHTML;
        channel_name = channel_name.replace(regex, '');

        await ChannelActions.updateChannel(channel, { name: channel_name });

        obj.setAttribute('contentEditable', 'false');
        obj.style.overflow = 'hidden';
        obj.style.whiteSpace = 'nowrap';
        obj.style.textOverflow = 'ellipsis';
    }

    /**页面渲染后以10%的概率清除已过期的channel */
    const removeChannel = async (_channels) => {
        if (_channels.length === 0) return false;

        const randNum = Math.floor(Math.random() * 10);
        if (randNum !== 0) return false;

        await ChannelActions.removeChannel(_channels);
    }

    //channel类型下拉菜单
    const items = JSON.parse(localStorage.getItem('types'));
    items.unshift({
        key: 0,
        label: '전체'
    });
    const menuProps = {
        items,
        onClick: (e) => {
            const target = e.domEvent.target.innerHTML;
            setCurrentType(target.replace(/<[^>]+>/ig, ''));
        },
    };

    return (
        <div className={`tab-pane fade show active ${styles.channels}`} id="nav-tab-chat" role="tabpanel">

            <div className={`${styles.channel_head}`}>
                <Logo />

                <div className="form-group input-group-lg search mb-3">
                    <i className={`icon iconfont ${styles.search_icon}`}>&#xe694;</i>
                    <input type="text" className="form-control" placeholder="Search..." onChange={(e) => { setSearch(e.target.value) }} />
                </div>

                <div className={`d-flex justify-content-between align-items-center mb-4`}>
                    <Link to="/admin/chat/all" className={`${styles.chat_status} ${params.state === 'all' ? styles.active : ''}`}>
                        <i className={`icon iconfont ${styles.chat_status_icon}`}>&#xe643;</i>
                        <span>전체({channelState.total})</span>
                    </Link>
                    <Link to="/admin/chat/wait" className={`${styles.chat_status} ${params.state === 'wait' ? styles.active : ''}`}>
                        <i className={`icon iconfont ${styles.chat_status_icon}`}>&#xe665;</i>
                        <span>대기중({channelState.wait})</span>
                    </Link>
                    <Link to="/admin/chat/going" className={`${styles.chat_status} ${params.state === 'going' ? styles.active : ''}`}>
                        <i className={`icon iconfont ${styles.chat_status_icon}`}>&#xe626;</i>
                        <span>진행중({channelState.going})</span>
                    </Link>
                    <Link to="/admin/chat/end" className={`${styles.chat_status} ${params.state === 'end' ? styles.active : ''}`}>
                        <i className={`icon iconfont ${styles.chat_status_icon}`}>&#xe642;</i>
                        <span>완료({channelState.end})</span>
                    </Link>
                </div>
                <section className="header d-flex justify-content-between ps-3 pe-3 mb-1">
                    <span>대화 리스트</span>

                    <div className="d-flex justify-content-between">
                        <b className="mr4">{currentType}</b>
                        {channel_type.type_id === 0 &&
                            <div className="dropdown">
                                <b className="btn btn-link px-1 py-0 border-0 text-muted">
                                    <Dropdown menu={menuProps} placement="bottomLeft" trigger="click">
                                        <i className="icon iconfont">&#xe610;</i>
                                    </Dropdown>
                                </b>
                            </div>}
                    </div>

                </section>
            </div>

            <Spin spinning={loading} indicator={antIcon}>
                <ul className="chat-list">
                    {renderChannels.map((channel) => {
                        let msg = '';

                        if (channel.last_message) {
                            switch (channel.last_message.custom_type) {
                                case 'attachmentFilename':
                                    let filePath = channel.last_message.file ? channel.last_message.file.url.split('.') : ['new'];
                                    msg = '파일 ' + filePath[filePath.length - 1];
                                    break;

                                case 'clipboardImages':
                                    let jsonData = JSON.parse(channel.last_message.data);
                                    let imagePath = jsonData.clipboardImages[0].split('.');
                                    msg = '이미지 ' + imagePath[imagePath.length - 1];
                                    break;

                                default:
                                    msg = channel.last_message.message;
                                    break;
                            }
                        }

                        return (
                            <li className="pointer" key={channel.channel_url}>
                                <b className={`card ${styles.card_list} ${props.current_channel && props.current_channel.channel_url === channel.channel_url ? styles.channel_active : ''}`} onClick={() => { setCurrentChannel(channel) }}>
                                    <div className="card-body">
                                        <div className="media">
                                            <div className="avatar me-3">
                                                {channel.unread_message_count > 0 && <span className="unread rounded-circle">{channel.unread_message_count}</span>}
                                                <img className="avatar rounded-circle" src={channel && channel.members && getChannelHeaderImage(channel)} alt="avatar" />
                                            </div>
                                            <div className="media-body overflow-hidden">
                                                <div className="d-flex align-items-center mb-1">
                                                    <h6
                                                        className="text-truncate mb-0 me-auto"
                                                        contentEditable="false"
                                                        channel={JSON.stringify(channel)}
                                                        onDoubleClick={(e) => { editChannelName(e) }}
                                                        onBlur={(e) => { updateChannelName(e) }}
                                                        suppressContentEditableWarning
                                                    >{channel.name}</h6>
                                                    <p className="small text-muted text-nowrap ms-4 mb-0">{channel.last_message && timestampToTime(channel.last_message.created_at)}</p>
                                                </div>
                                                <div className="text-truncate fs12 grey">{msg}</div>
                                            </div>
                                        </div>
                                    </div>
                                </b>
                            </li>
                        );
                    })}
                </ul>
            </Spin>
        </div>
    );
}

/**
 connect(
    //mapStateToProps //使组件监听某个状态的改变
    //mapDispatchToProps //组件改变某个状态
    //以上两个connect参数会分配到props属性中
 )(被包装的组件)
 */

const mapStateToProps = ({
    channelReducer: { current_channel },
    messageReceiveReducer: { message_receive },
    channelChangeReducer: { channel_change },
    userInvitedReducer: { user_invited_channel },
    userLeftReducer: { user_left_channel }
}) => {
    return {
        current_channel,
        message_receive,
        channel_change,
        user_invited_channel,
        user_left_channel
    }
}

const mapDispatchToProps = {
    setCurrentChannel(channel) {
        return {
            type: 'set_current_channel',
            payload: channel
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Channel);