import React from 'react';
import { addComma } from '../../../utils/functions';
import styles from './messages.module.css';

function Utis(props) {
    const messageData = JSON.parse(props.message.data);

    if (messageData === '') {
        return <div className="msg_spec" id={'msg-content-' + props.message.messageId}></div>;
    }

    let utisProductUrl = 'https://eggdome.ggook.com/home/product/utisD.php?id=' + messageData['id'];

    return (
        <div className="msg_spec" id={'msg-content-' + props.message.messageId}>
            <h3 className={styles.product_title}>{props.message.message}</h3>
            <a href={utisProductUrl} target="_blank" rel="noreferrer" className={styles.product}>
                <img src={messageData.image} alt="" />
                <div className={styles.product_info}>
                    <h4 title={messageData.title}>{messageData.title}</h4>
                    <section>
                        <div className={styles.product_price}>
                            <strong>{messageData.unit_price ? addComma(messageData.unit_price) : ''}</strong>
                            {messageData.unit_price ? <em>원</em> : ''}
                        </div>
                    </section>
                </div>
            </a>
        </div>
    );
}

export default Utis;