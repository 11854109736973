import React from 'react';
import styles from '../chat.module.css';

function SearchList(props) {
    return (
        <ol className="activity-feed p-0 mb-0 pt-3">
            {props.messages.map(item=>{
                const msg = item.message.replace(props.keyword, '<span style="color:#b70b1b;font-weight:bold;">'+props.keyword+'</span>');

                return (
                    <li key={item.message_id} className="feed-item d-flex mb-3 pl-lg-4" data-time="2022-12-16 09:11"
                data-color="yellow">
                        <div className="card mb-3">
                            <div className="card-body">
                                <label className="mb-0" onClick={()=>{props.listMessage(item.message_id)}}>
                                    <b className={styles.msg_lead}>{item.user.nickname}:</b>
                                    <span dangerouslySetInnerHTML={{ __html: msg }}></span>
                                </label>
                            </div>
                        </div>
                    </li>
                );
            })}
            
        </ol>
    );
}

export default SearchList;