import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, message, Upload } from 'antd';
import { MainActions } from '../../../actions/admin/MainActions';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Logo from '../logo/Logo';
import styles from './profile.module.css';
import DrawLeft from '../draw/DrawLeft';
import { ManagerActions } from '../../../actions/admin/ManagerActions';
import { CommonActions } from '../../../actions/admin/CommonActions';

function Profile(props) {
    const [profileInfo, setProfileInfo] = useState({});
    const [adminType, setAdminType] = useState('--');
    const [drawOpen, setDrawOpen] = useState(false);//表单打开状态
    const [loading, setLoading] = useState(false);
    const [headerimg, setHeaderimg] = useState(null);
    const formData = useRef(null);

    let sending = 0;

    useEffect(() => {
        getProfile();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取管理员用户数据 */
    const getProfile = async () => {
        const res = await MainActions.profile();
        setProfileInfo(res.result);
        setHeaderimg(res.result.headerimg);

        //设置管理员类型
        if (res.result.is_admin === 1 && res.result.channel_type_id === 0) {
            setAdminType('-총관리자-');
        } else if (res.result.is_admin === 1 && res.result.channel_type_id === 1) {
            setAdminType('-이지챗 매니저-');
        } else if (res.result.application === 'eggdome' && (res.result.username === 'sellchina' || res.result.username === 'cninsider')) {
            setAdminType('-상담 매니저-');
        } else {
            setAdminType('-상담원-');
        }
    }

    /**表单关闭 */
    const closeForm = () => {
        setDrawOpen(false);
        setHeaderimg(profileInfo.headerimg);
    }

    /**表单提交，添加和编辑分别处理 */
    const onSubmit = () => {
        formData.current.validateFields().then(async res => {
            if (sending === 1) return false;
            sending = 1;

            res['id'] = profileInfo.id;
            res['channel_type_id'] = profileInfo.channel_type_id;
            res['username'] = profileInfo.username;
            res['headerimg'] = headerimg;

            const response = await ManagerActions.update(res);
            if (response) {
                message.success(response.message);
                setProfileInfo({
                    ...profileInfo,
                    nickname: res.nickname,
                    headerimg: res.headerimg
                });
                setHeaderimg(res.headerimg);
                closeForm();
            }
        }).catch(err => {
            message.error(err.response.data.message);
        });
    }

    /**文件上传 */
    const uploadProps = {
        multiple: true,
        showUploadList: false,
        beforeUpload: () => false,
        onChange: async (e) => {
            setLoading(true);
            CommonActions.fileUpload(e.file, res => {
                res && res.code === '' && setHeaderimg(res.result.url);
                setLoading(false);
            });
        }
    }

    const form = (
        <Form layout="vertical" hideRequiredMark ref={formData}>
            <Form.Item
                name="username"
                label="아이디"
                initialValue={profileInfo.username}
            >
                <Input placeholder="아이디" disabled={true} autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="nickname"
                label="닉네임"
                initialValue={profileInfo.nickname}
                rules={[
                    {
                        required: true,
                        message: '닉네임을 입력해주세요',
                    },
                ]}
            >
                <Input placeholder="닉네임" autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="password"
                label="비밀번호"
            >
                <Input.Password placeholder="입력하지 않으면 변경하지 않습니다" autoComplete="off" />
            </Form.Item>

            <Form.Item label="헤더이미지">
                <Upload {...uploadProps} listType="picture-card" className="avatar-uploader">
                    {headerimg ? (
                        <img src={headerimg} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                        <div>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }} >Upload</div>
                        </div>
                    )}
                </Upload>
            </Form.Item>
        </Form>
    );

    return (
        <div className="tab-pane fade show active" id="nav-tab-user" role="tabpanel">

            <Logo />

            <div className="card border-0 text-center pt-3 mb-4">
                <div className="card-body">
                    <div className="card-user-avatar">
                        <img src={profileInfo.headerimg ? profileInfo.headerimg : require('../../../assets/admin/img/loading.png')} alt="avatar" className={styles.portrait} />
                        <button type="button" className={`btn btn-primary ${styles.edit_btn}`} onClick={() => {setDrawOpen(true)}}>
                            <i className="icon iconfont">&#xe609;</i>
                        </button>
                    </div>
                    <div className="card-user-detail mt-4">
                        <h4>{profileInfo.username}</h4>
                        <span className="text-muted">{profileInfo.nickname}</span>
                        <p className="mt-3 fs16">{adminType}</p>
                    </div>
                </div>
            </div>

            <div className="card border-0 pd10">
                <ul className="list-group custom list-group-flush">
                    <li className="list-group-item border-0 mt-3">
                        <Link to="/admin/chat/all" className="link quick-right-icon pointer">
                            <i className="icon iconfont fs12">&#xe61f;</i>
                            채팅창 바로가기
                        </Link>
                    </li>
                    <li className="list-group-item border-0 mt-3">
                        <Link to="/admin/setting" className="link quick-right-icon pointer">
                            <i className="icon iconfont fs12">&#xe61f;</i>
                            설정 바로가기
                        </Link>
                    </li>
                </ul>
            </div>

            <DrawLeft
                status={drawOpen}
                change={() => { sending = 0 }}
                title="프로필 수정"
                form={form}
                close={closeForm}
                submit={onSubmit}
                btn_name="수정하기"
            />
        </div>
    );
}

export default Profile;