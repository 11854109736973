import React from 'react';
import { Button, Drawer, Space } from 'antd';

function DrawLeft(props) {
    return (
        <Drawer
            title={props.title}
            width={450}
            placement="left"
            onClose={props.close}
            open={props.status}
            afterOpenChange={props.change}
            bodyStyle={{
                paddingBottom: 80,
            }}
            extra={
                <Space>
                    <Button onClick={props.close}>취소</Button>
                    <Button type="primary" onClick={props.submit}>{props.btn_name}</Button>
                </Space>
            }
            destroyOnClose={true}
        >
            {props.form}
        </Drawer>
    );
}

export default DrawLeft;