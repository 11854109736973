import React, { useEffect } from 'react';
import { loadImage } from '../../../../utils/functions';
import Messages from './Messages';

function HistoryList(props) {
    useEffect(() => {
        scrollToAnchor();
    }, [props.anchor]);// eslint-disable-line react-hooks/exhaustive-deps

    /**锚点信息滚动条定位 */
    const scrollToAnchor = () => {
        if (props.anchor === 0) return false;

        setTimeout(() => {
            const messageId = 'history-content-'+props.anchor;
            const anchorMessage = document.getElementById(messageId);

            if (anchorMessage) {
                anchorMessage.scrollIntoView(false);
                loadImage(() => {//图片加载完成后再次调整滚动条
                    anchorMessage.scrollIntoView(false);
                });
            }
        }, 100);
    }

    return (
        <div className="chat-content">
            <div className="container-xxl">
                <ul className="list-unstyled py-4">
                    {props.messages.map(item=><Messages keyword={props.keyword} key={item.message_id} message={item} />)}
                </ul>
            </div>
        </div>
    );
}

export default HistoryList;