import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import styles from './main.module.css';
import { connect } from 'react-redux';
import { ChannelActions } from '../../../actions/admin/ChannelActions';
import { EggdomeActions } from '../../../actions/admin/EggdomeAction';

function ResetChannel(props) {
    const params = useParams();
    const navigate = useNavigate();
    const bot_userid = 'eggchat_bot_1';

    useEffect(() => {
        resetCurrentChannel();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**根据chatInfo中的c_channel_url请求 channel，如果请求不到则新创建channel，并保存到redux后跳转到chat/all页面 */
    const resetCurrentChannel = async () => {
        const chatInfoRes = await EggdomeActions.chatInfo({c_id: params.c_id});

        if (chatInfoRes.code !== '') {
            message.error(chatInfoRes.message, 2, ()=>{
                navigate('/admin');
            });
            return false;
        }

        const chatInfo = chatInfoRes.result;
        let channelRes;

        if (!chatInfo.c_channel_url || chatInfo.c_channel_url === '') {
            channelRes = await createChannel(chatInfo);
        } else {
            try {
                channelRes = await ChannelActions.getChannel(chatInfo.c_channel_url);

                //channel中补充客服
                let invitingUser = [...chatInfo.ad_user_all];
                channelRes.members.forEach(item=>{
                    if (invitingUser.includes(item.user_id)) {
                        invitingUser.splice(invitingUser.indexOf(item.user_id), 1);
                    }
                });

                if (invitingUser.length > 0) {
                    await ChannelActions.inviteChannel(channelRes.channel_url, invitingUser);
                } 
            } catch (err) {
                if (err.response && err.response.data.code !== 400201) {
                    message.error(err.response.data.message, 2, ()=>{
                        navigate('/admin');
                    });
                    return false;
                }

                channelRes = await createChannel(chatInfo);
            }
        }

        if (channelRes.code) {
            message.error(channelRes.message, 2, ()=>{
                navigate('/admin');
            });
            return false;
        }

        const sendbirdUser = JSON.parse(localStorage.getItem('s_user'));
        const isMyChannel = channelRes.members.find(item=>item.user_id === sendbirdUser.user_id);
        if (!isMyChannel) {
            message.error('채널에 존재하지않는 상담원입니다.', 2, ()=>{
                navigate('/admin');
            });
            return false;
        }

        props.setCurrentChannel(channelRes);
        navigate('/admin/chat/all');
    }

    /**生成新的 channel 并其中加入 bot */
    const createChannel = async (chatInfo) => {
        const response = await EggdomeActions.createChannel(chatInfo);

        if (!response.code) {
            await EggdomeActions.updateChannelUrl({c_id: chatInfo.c_id, channel_url: response.channel_url});
            await ChannelActions.botJoinChannel({bot_userid: bot_userid, channel_url: response.channel_url});
        }

        return response;
    }

    return (
        <div className={styles.reset}>
            <img src={require('../../../assets/common/img/loading.gif')} alt="" />
            <p>채팅창 리셋중입니다...</p>
        </div>
    );
}

const mapStateToProps = ({
    channelReducer: { current_channel }
}) => {
    return {
        current_channel
    }
}

const mapDispatchToProps = {
    setCurrentChannel(channel) {
        return {
            type: 'set_current_channel',
            payload: channel
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetChannel);