import { ExclamationCircleFilled } from '@ant-design/icons';

export const _CONFIRM_ = (params) => {
    return {
        title: params.title,
        width: params.width || 420,
        icon: <ExclamationCircleFilled />,
        content: params.content,
        okText: '확인',
        cancelText: '취소',
        onOk() {
            params.ok && params.ok();
        },
        onCancel() {
            params.cancel && params.cancel();
        }
    }
}