import React from 'react';
import { getUserId, timestampToTime } from '../../../../utils/functions';
import styles from './message.module.css';
import { allowedFiles, allowedVideo, EMOJI } from '../../../../Constants';
import { connect } from 'react-redux';
import { Image } from 'antd';

function Message(props) {
    /**判断消息是否属于管理员（管理员的消息放右侧） */
    const isMe = () => {
        //没有发送者直接返回false
        if (!props.message.user) return false;

        //在channel 数据中包含用户id信息，则直接用该用户id判断
        return props.message.user.user_id !== getUserId(props.current_channel);
    }

    //文本消息
    const textMessage = () => {
        let msg = props.message.message;
        const reg = new RegExp(`${EMOJI.symbolReg}(\\d{3})`, 'g');
        msg = msg ? msg.replace(reg, EMOJI.link(EMOJI.symbol+'$1')) : '';
        msg = msg.replace(props.keyword, '<span style="color:#b70b1b;font-weight:bold;">'+props.keyword+'</span>')

        return <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.message_id} dangerouslySetInnerHTML={{ __html: msg }} />
    };

    /**文件消息 */
    const fileMessage = () => {
        const fileUrl = props.message.file.url;
        const fileData = fileUrl.split('/');
        const fileName = fileData[fileData.length - 1];
        const files = fileName.split('.');
        const fileExtension = files[files.length - 1].toLowerCase();

        let msgComponent;
        if (allowedFiles.hasOwnProperty(fileExtension)) {//可下载或可浏览文件
            msgComponent = (
                <a href={fileUrl} target="_blank" rel="noreferrer" className="attachment">
                    <div className="media mt-2">
                        <div className="avatar me-2">
                            <img className={styles.file_type} src={allowedFiles[fileExtension]} alt="" />
                        </div>
                        <div className="media-body overflow-hidden">
                            <h6 className="text-truncate mb-0">{props.message.file.name}</h6>
                            {/* <span className="file-size">2.7 mb</span> */}
                        </div>
                    </div>
                </a>
            );
        } else if (allowedVideo.includes(fileExtension)) {//视频文件
            msgComponent = (
                <div className="attachment right-file">
                    <video width="184" controls>
                        <source src={fileUrl} />
                    </video>
                </div>
            );
        } else {//图片文件
            msgComponent = (
                <div className="attachment right-file">
                    <Image width={120} src={fileUrl} />
                </div>
            );
        }

        return <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.messageId}>{msgComponent}</div>;
    }

    /**截图发送的消息 */
    const clipboardImageMessage = () => {
        let imageData = JSON.parse(props.message.data);
        let images = imageData.hasOwnProperty('clipboardImages') ? imageData.clipboardImages : imageData;

        return (
            <div className={`message-content border p-3 ${styles.message_reformat}`} id={'history-content-'+props.message.message_id}>
                <div className="attachment right-file">
                    {images.map((item, key)=>(
                        <Image key={key} width={120} src={item} />
                    ))}
                </div>
            </div>
        );
    };

    /**判断消息类型，并组合jsx */
    const messageRender = () => {
        let msgContent;

        if (props.message.custom_type === 'attachmentFilename') {
            msgContent = fileMessage();
        } else if (props.message.custom_type === 'clipboardImages') {
            msgContent = clipboardImageMessage();
        } else {
            msgContent = textMessage();
        }   

        return msgContent;
    }

    return (
        <li className={`d-flex message ${isMe() ? 'right' : ''}`} id={'history-'+props.message.message_id}>

            {!isMe() && 
                <div className="mr-lg-3 me-2">
                    <img className={`sm ${styles.headerimg}`} src={props.current_channel.cover_url} alt="avatar" />
                </div>
            }
            
            <div className="message-body">
                <div className={`date-time text-muted ${isMe() ? 'align-right' : ''}`}>
                    {isMe() ? props.message.user.user_id : props.current_channel.name} {timestampToTime(props.message.created_at)}
                </div>
                <div className={`message-row d-flex align-items-center ${isMe() ? 'justify-content-end' : ''}`}>
                    {messageRender()}
                </div>
            </div>
        </li>
    );
}

const mapStateToProps = ({
    channelReducer: { current_channel }
}) => {
    return {
        current_channel
    }
}

export default connect(mapStateToProps)(Message);