import React, { useEffect, useState } from 'react';
import { WordsActions } from '../../../actions/admin/WordsActions';
import styles from './sidebar.module.css';
import { connect } from 'react-redux';

function Words(props) {
    const [commonWords, setCommonWords] = useState([]);//常用语数据
    const [content, setContent] = useState('');//添加文本域数据

    let sending = 0;

    useEffect(() => {
        getWordsList();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取常用语数据 */
    const getWordsList = async () => {
        const datas = await WordsActions.list();
        setCommonWords(datas.result);
    }

    /**添加常用语数据 */
    const wordsStore = async () => {
        if (sending === 1) return false;
        sending = 1;

        const res = await WordsActions.store({content});
        if (res) {
            setCommonWords([
                res.result,
                ...commonWords
            ]);

            setContent('');

            props.setWord({
                action: 'add',
                data: res.result
            });
        }

        sending = 0;
    }

    /**删除常用语 */
    const wordsDel = async (id) => {
        if (sending === 1) return false;
        sending = 1;

        const res = await WordsActions.del({id});
        if (res) {
            setCommonWords(commonWords.filter(item => item.id !== id));

            props.setWord({
                action: 'del',
                data: id
            });
        }

        sending = 0;
    }

    return (
        <div id="tab-word" role="tabpanel" className={styles.tab}>
            <div className={styles.tab_head}>
                <div className="header border-bottom pb-4 d-flex justify-content-between align-items-center p-1">
                    <div>
                        <h6 className="mb-0 font-weight-bold">자주쓰는 문구</h6>
                        <span className="text-muted">자주쓰는 문구를 저장해두세요.</span>
                    </div>
                    <div>
                        <button className="btn btn-link close-sidebar text-muted" onClick={props.tabHide}><i className="icon iconfont">&#xe64d;</i></button>
                    </div>
                </div>
                <div className="body mt-4">
                    <div className="add-note">
                        <div className="input-group mb-2">
                            <textarea rows="3" className="form-control" placeholder="내용을 입력해주세요" onChange={(e)=>{setContent(e.target.value)}} value={content} />
                        </div>
                        <button className={`btn btn-primary ${styles.addnote}`} onClick={wordsStore}>추가</button>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled mt-4">
                {commonWords.map((item)=>(
                    <li key={item.id} className={`card border-0 mb-2 ${styles.word_list}`}>
                        <span>{item.content}</span>
                        <button className={`btn btn-sm btn-link ${styles.word_del_btn}`} onClick={()=>{wordsDel(item.id)}}><i className="icon iconfont text-danger">&#xe611;</i></button>
                    </li>
                ))}
                
            </ul>
        </div>
    );
}

const mapStateToProps = ({ wordReducer: { word } }) => {
    return {
        word
    }
}

const mapDispatchToProps = {
    setWord(word) {
        return {
            type: 'set_word',
            payload: word
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Words);