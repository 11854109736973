import { message } from 'antd';
import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';

export const WordsActions = {
    /**
     * 获取常用语列表
     * @returns
     */
    list: async () => {
        const response = await Requests.get({
            url: EgchatApi.words_list,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 存储常用语
     * @param {*} param 
     * @returns 
     */
    store: async (param) => {
        if (param.content === '') {
            message.error('내용을 입력해주세요');
            return false;
        }

        const response = await Requests.post({
            url: EgchatApi.words_store,
            withAuth: true,
            datas: param,
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 删除常用语
     * @param {*} param 
     * @returns 
     */
    del: async (param) => {
        const response = await Requests.delete({
            url: EgchatApi.words_del(param.id),
            withAuth: true,
            datas: param
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    }
};