import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Modal, Radio, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MainActions } from '../../../actions/admin/MainActions';
import Navigation from '../../../components/admin/navigation/Navigation';
import Channel from './Channel';
import Sidebar from '../../../components/admin/sidebar/Sidebar';
import Message from './Message';
import Write from './Write';
import ManagerList from './ManagerList';
import ManagerAdd from './ManagerAdd';
import ChatHistory from './ChatHistory';
import styles from './chat.module.css';
import { connect } from 'react-redux';
import { getChannelHeaderImage, getUserId, loadImage, timeFormat } from '../../../utils/functions';
import { ChannelActions } from '../../../actions/admin/ChannelActions';
import { _CONFIRM_ } from '../../../System';
import { ManagerActions } from '../../../actions/admin/ManagerActions';
import { EggdomeActions } from '../../../actions/admin/EggdomeAction';
import Loading from "../../../components/loading";

function Chat(props) {
    const [tabPos, setTabPos] = useState('-450px');
    const [currentTab, setCurrentTab] = useState('');
    const [profileInfo, setProfileInfo] = useState({});
    const [currentChannel, setCurrentChannel] = useState(props.current_channel ? props.current_channel : {});
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [firstMessageId, setFirstMessageId] = useState('');
    const [hasMoreMessage, setHasMoreMessages] = useState(true);//是否有更多消息（控制上拉滚动事件）
    const [chatBodyWidth, setChatBodyWidth] = useState('100%');
    const [yyMessage, setYyMessage] = useState(null); //当前引用消息
    const [yyMessageLoading, setYyMessageLoading] = useState(false) //引用消息Loading显示状态
    const [quoteBtnView,setQuoteBtnView] = useState(false);  //跳回原来 控件显示与否
    const yyMessageIdRef = useRef(null); //点击的引用消息id，用于加载该条消息后，控制滚动条到这个位置
    const curMessageIdRef = useRef(null); //点击引用后 当前消息id，用于返回到这个位置
    const lastYytimeRef = useRef(Date.now()); //引用消息列表中第一条消息的时间，用于引用消息加载更多api的参数
    const yyMsgRef = useRef([]); //用于存储点击引用消息后 到当前消息的列表
    const yyCreateTimeRef = useRef(null); //用于存储点击引用消息后 到当前消息的列表
    const quoteDisRef = useRef(false); //用于开启显示与隐藏回到引用位置div 滚动条事件

    const params = useParams();
    const navigate = useNavigate();
    const { confirm } = Modal;

    const messagesEndRef = useRef(null);//消息框底部标记，初始化或接受新消息时滚动到这个位置
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    let selectedType = '';

    useEffect(() => {
        getProfile();
        window.addEventListener('keydown', keyboardEvent);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**监听channel的状态改变事件 */
    useEffect(() => {
        setCurrentChannel(props.current_channel ? props.current_channel : {});
    }, [props.current_channel]);// eslint-disable-line react-hooks/exhaustive-deps

    /**监听切换channel事件 */
    useEffect(() => {
        getMessages(props.current_channel);
    }, [currentChannel.channel_url]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听接收消息 */
    useEffect(() => {
        messageReceive();
    }, [props.message_receive]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听删除消息 */
    useEffect(() => {
        messageDelete();
    }, [props.message_delete]);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取管理员用户数据 */
    const getProfile = async () => {
        const res = await MainActions.profile();
        setProfileInfo(res.result);
    }

    /**处理接收新消息事件 */
    const messageReceive = () => {
        //props.message_receive 值为null时不做处理
        if (!props.message_receive) return false;

        //不是当前的channel则不处理
        if (props.message_receive.channel.url !== currentChannel.channel_url) return false;

        let _message = { ...props.message_receive.message ,mask_show:false};
        _message['readers'] = [];
        setMessages([...messages, _message]);
        scrollToBottom();
    }

    /**处理删除消息事件 */
    const messageDelete = () => {
        //props.message_delete 值为null时不做处理
        if (!props.message_delete) return false;

        //不是当前的channel则不处理
        if (props.message_delete.channel.url !== currentChannel.channel_url) return false;

        setMessages(messages.filter(item => item.messageId !== props.message_delete.messageId));
    }

    /**获取消息数据 */
    const getMessages = (current_channel, timestamp = new Date().getTime(), limit = 30, init = true) => {
        if (!current_channel) return false;

        if (!yyMessageIdRef.current){
            setLoading(true);
        }


        let sb = props.sendbird;
        const msgCount = limit;

        sb.GroupChannel.getChannel(current_channel.channel_url, function (groupChannel, error) {
            if (error) return false;

            const msgParams = new sb.MessageListParams();
            msgParams.prevResultSize = msgCount;
            msgParams.nextResultSize = 0;
            msgParams.isInclusive = false;
            msgParams.reverse = false;
            // params.replyType = 'NONE';
            msgParams.includeThreadInfo = false;
            msgParams.includeParentMessageInfo = false;
            groupChannel.getMessagesByTimestamp(timestamp, msgParams, (msgs, error) => {
                if (error) return false;
                const msgWithReaders = msgs.map((msg, index) => {
                    if (index === 0) setFirstMessageId('msg-' + msg.messageId);

                    const readers = groupChannel.getReadMembers(msg);
                    return { ...msg, readers, mask_show:false }
                });
                //判断滚动条顶部是否触发加载更多事件
                setHasMoreMessages(msgs.length === msgCount);

                lastYytimeRef.current = msgWithReaders[0].createdAt; //设置获取引用消息 第一条消息时间

                yyMsgRef.current = [...msgWithReaders,...yyMsgRef.current];

                if (!yyMessageIdRef.current){
                    if (init) {
                        setMessages(msgWithReaders);
                        scrollToBottom('instant');
                    } else {
                        setMessages([...msgWithReaders, ...messages]);
                        scrollToFirstMessage();
                    }
                }else {
                    if (parseInt(lastYytimeRef.current) > parseInt(yyCreateTimeRef.current)){
                        getMessages(currentChannel, lastYytimeRef.current, limit,false);
                    }else {
                        handleAllYyMsg();
                    }
                }
                if (!yyMessageIdRef.current){
                    setLoading(false);
                }

            });
        });
    }

    /**鼠标滚动到顶部事件 */
    const scrollToTop = (e) => {
        const curYyDiv = document.getElementById(curMessageIdRef.current);
        if (curYyDiv){
            toggleGoToQuoteButton();
        }
        if (e.target.scrollTop > 0 || !hasMoreMessage) return false;

        const time = messages.length > 0 ? messages[0].createdAt : new Date().getTime();
        getMessages(currentChannel, time, 30,false);
    }

    /**聊天窗滚动到底部 */
    const scrollToBottom = (behavior = "smooth") => {
        setTimeout(() => {
            messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: behavior });
            loadImage(() => {//图片加载完成后继续滚动一次
                messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: behavior });
            });
        }, 100);
    }

    /**聊天窗滚动到指定位置 */
    const scrollToFirstMessage = () => {
        setTimeout(() => {
            const _firstMessage = document.getElementById(firstMessageId);

            if (_firstMessage) {
                _firstMessage.scrollIntoView();
                loadImage(() => {//图片加载完成后再次调整滚动条
                    _firstMessage.scrollIntoView();
                });
            }
        }, 100);
    }

    /**消息已被删除时聊天窗里的消息也删除 */
    const delMessageInBoard = (messageId) => {
        setMessages(messages.filter(item => item.messageId !== messageId));
    }

    /**聊天已结束事件 */
    const channelUpdateToEnd = () => {
        confirm(_CONFIRM_({
            title: '상담을 종료하시겠습니까?',
            ok: () => {
                if (currentChannel.custom_type === 'end') return false;

                ChannelActions.updateChannel(currentChannel, { custom_type: 'end' });

                //加入菜单筛选中全部或聊天已结束则不再进行跳转
                if (params.state === 'all' || params.state === 'end') return false;

                //跳转到聊天进行中的菜单选项
                navigate('/admin/chat/end');
            }
        }));
    }

    /**修改聊天窗类型 */
    // eslint-disable-next-line
    const channelChangeType = () => {
        const types = JSON.parse(localStorage.getItem('types'));

        const channel_data = props.current_channel.data.split('|');
        if (channel_data[1]) {
            selectedType = channel_data[1];
        }

        confirm(_CONFIRM_({
            title: '변경하실 대화창 문의유형을 선택해주세요.',
            width: 480,
            content: (
                <Radio.Group onChange={(e)=>{selectedType = e.target.value}} defaultValue={selectedType}>
                    {types.map(item=><Radio key={item.key} value={item.label}>{item.label}</Radio>)}
                </Radio.Group>
            ),
            ok: setChannelType
        }));
    }

    /**调用api，更新channel类型 */
    const setChannelType = async () => {
        //没有数据的类型不做处理
        if (selectedType === '') return false;

        //相同的类型不做处理
        const currentData = currentChannel.data.split('|');
        if (selectedType === currentData[1]) return false;

        message.loading({content: '문의유형 업데이트중...', duration: 0});

        // 查看eggdome chat 数据表中是否有该会员将要替换的channel 类型（主要是publicA, publicB）
        const token = JSON.parse(localStorage.getItem('token'));
        const typeMap = {'수입대행': 'publicA', '구매대행': 'publicB'};
        const mb_id = getUserId(currentChannel);
        if (token.application === 'eggdome') {
            const checkRes = await EggdomeActions.checkLevel({level: typeMap[selectedType], mb_id});

            if (checkRes.code !== '') {
                message.destroy();
                message.warning(checkRes.message);
                return false;
            }
        }

        //获取待更换的管理员数据
        const managers = await ManagerActions.getReplaceManagers();
        if (!managers) return false;

        const types = JSON.parse(localStorage.getItem('types'));
        const type = types.find(item=>item.label === selectedType);

        const joiningUsers = managers[type.key];//待加入管理员
        let leavingUsers = [];//待离开管理员
        for (let i in managers) {
            if (parseInt(i) === type.key) continue;

            leavingUsers = [...leavingUsers, ...managers[i]];
        }

        await ChannelActions.inviteChannel(currentChannel.channel_url, joiningUsers);
        await ChannelActions.leaveChannel(currentChannel.channel_url, leavingUsers);

        const channel_data = getUserId(currentChannel) + '|' + type.label;
        const res = await ChannelActions.updateChannel(currentChannel, { data: channel_data });

        if (!res.code) {
            message.destroy();
            message.success('문의유형 업데이트 완료되었습니다.');
        }

        // 更新eggdome chat 数据表中的channel 类型，
        if (token.application === 'eggdome') {
            await EggdomeActions.updateLevel({level: typeMap[selectedType], mb_id, channel_url: currentChannel.channel_url});
        }
    }

    /**右上角菜单点击弹出右侧抽屉 */
    const tabView = (e, tab) => {
        setTabPos('54px');
        setCurrentTab(tab);
    }

    /**关闭右侧抽屉，这个方法在抽屉组件调用 */
    const tabHide = () => {
        setTabPos('-450px');
        setCurrentTab('');
    }

    //处理键盘事件
    const keyboardEvent = (e) => {
        if (e.keyCode === 27) {
            tabHide();
        }
    }

    //引用消息回调
    const handleMesssageObj = (curMess)=>{
        setYyMessage({...curMess});
    }

    //删除引用消息回调
    const handleDelYyMsg = ()=>{
        setYyMessage(null);
    }

    //引用消息点击事件回调
    const handleYyMsg = async (yy_id,creat_time,ms_id) => {
        yyMessageIdRef.current = 'msg-' + yy_id;
        curMessageIdRef.current = 'msg-' + ms_id;
        yyCreateTimeRef.current = creat_time;
        let yymsg = messages.find((ele)=>{
            return ele.messageId == yy_id
        })
        if(yymsg){
            yymsg.mask_show = true;
            setMessages([...messages]);
            // scrollToYyMsg();
        }else {
            if (parseInt(lastYytimeRef.current) > parseInt(creat_time)){
                setYyMessageLoading(true);
                getMessages(currentChannel, lastYytimeRef.current, 50,false);
            }
        }
    }

    //处理获取的引用消息
    const handleAllYyMsg = () => {
           const yy_id = yyMessageIdRef.current.split('-')[1];
            yyMsgRef.current.forEach((ele)=>{
                if (ele.messageId == yy_id) {
                    ele.mask_show = true;
                }
            })
            setYyMessageLoading(false);
            setMessages([...yyMsgRef.current]);
    }



//得要引用消息后 跳到引用消息处
    useEffect(()=>{
        if (yyMessageIdRef.current){
            scrollToYyMsg();
        }
    },[messages])


    /**
     * 滚动条滚动到引用消息的地方
     */

    const scrollToYyMsg = () => {

        let _yyMessage = document.getElementById(yyMessageIdRef.current);
        if (_yyMessage) {
            _yyMessage.scrollIntoView();
            let yyMsgId = yyMessageIdRef.current.split('-')[1];
            setTimeout(()=>{
                setMessages((msgs)=>{
                    let yyMsgObj = msgs.find((item)=>{
                        return  item.messageId == yyMsgId
                    })
                    yyMsgObj.mask_show = false;
                    return [...msgs]
                })
            },1000)
        }
        quoteDisRef.current = true;
        yyMessageIdRef.current = null;
    }

    //页面滚动到 开始点击引用消息的位置

    const goToQuote = ()=>{
        const curYyDiv = document.getElementById(curMessageIdRef.current);
        if (curYyDiv){
            curYyDiv.scrollIntoView();
            setQuoteBtnView(false);
            curMessageIdRef.current = null;
            quoteDisRef.current = false;
        }
    }

    //显示与隐藏 回到引用位置div
    const toggleGoToQuoteButton = ()=>{
        const curYyDiv = document.getElementById(curMessageIdRef.current);
        if (curYyDiv && quoteDisRef.current){
            const chatBox = document.getElementById('chat-content');
            const scrollTop = chatBox.scrollTop;

            if (parseInt(curYyDiv.offsetTop) - parseInt(scrollTop) <= parseInt(chatBox.clientHeight)) {
                setQuoteBtnView(false);
                curMessageIdRef.current = null;
                quoteDisRef.current = false;
            }else {
                setQuoteBtnView(true);
            }
        }

    }

    /**右侧弹窗组件里面渲染的内容 */
    const setTab = () => {
        let tab;

        switch (currentTab) {
            case 'ManagerList':
                tab = <ManagerList tabHide={tabHide} />;
                break;
            case 'ManagerAdd':
                tab = <ManagerAdd tabHide={tabHide} />;
                break;
            case 'ChatHistory':
                tab = <ChatHistory current_channel={currentChannel} tabHide={tabHide} />;
                break;
            default:
                tab = '';
                break;
        }

        return tab;
    }

    let chatBody;
    if (props.current_channel) {
        const _channel_data = props.current_channel.data.split('|');
        const channel_type = _channel_data[1] ? _channel_data[1] : ''

        chatBody = (
            <div className="chat-body" style={{width: chatBodyWidth}}>
                <div className="chat-header border-bottom py-xl-4 py-md-3 py-2">
                    <div className="container-xxl">
                        <div className="row align-items-center">

                            <div className="col-6 col-xl-4">
                                <div className="media">
                                    <div className="me-3 show-user-detail">
                                        <span className="status rounded-circle"></span>
                                        <img className="avatar rounded-circle" src={currentChannel && currentChannel.members && getChannelHeaderImage(currentChannel)} alt="avatar" />
                                    </div>
                                    <div className="media-body overflow-hidden">
                                        <div className="d-flex align-items-center mb-1">
                                            <h6 className="text-truncate mb-0 me-auto">{currentChannel.name}</h6>
                                        </div>
                                        <div className="text-truncate">{channel_type + ' | ' + timeFormat(currentChannel.created_at)}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-xl-8 text-end">
                                <ul className="nav justify-content-end">

                                    <li className="nav-item list-inline-item pointer">
                                        <Tooltip placement="bottom" title="대화종료">
                                            <b className="nav-link text-muted px-3" onClick={() => { channelUpdateToEnd() }}>
                                                <i className="icon iconfont">&#xe642;</i>
                                            </b>
                                        </Tooltip>
                                    </li>

                                    {/* {profileInfo.is_admin === 1 &&
                                        <li className="nav-item list-inline-item pointer">
                                            <b className="nav-link text-muted px-3" onClick={() => { channelChangeType() }}>
                                                <i className="icon iconfont">&#xe6c0;</i>
                                            </b>
                                        </li>
                                    } */}

                                    <li className="nav-item list-inline-item pointer">
                                        <Tooltip placement="bottom" title="상담원 리스트">
                                            <b className="nav-link text-muted px-3" onClick={(e) => { tabView(e, 'ManagerList') }}>
                                                <i className="icon iconfont">&#xe60a;</i>
                                            </b>
                                        </Tooltip>
                                    </li>

                                    <li className="nav-item list-inline-item pointer" onClick={(e) => { tabView(e, 'ManagerAdd') }}>
                                        <Tooltip placement="bottom" title="상담원 추가">
                                            <b className="nav-link text-muted px-3">
                                                <i className="icon iconfont">&#xe605;</i>
                                            </b>
                                        </Tooltip>
                                    </li>

                                    <li className="nav-item list-inline-item pointer">
                                        <Tooltip placement="bottom" title="대화기록 검색">
                                            <b className="nav-link text-muted px-3" onClick={(e) => { tabView(e, 'ChatHistory') }}>
                                                <i className="icon iconfont">&#xe61c;</i>
                                            </b>
                                        </Tooltip>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chat-content scroll" id="chat-content" onScroll={(e) => { scrollToTop(e) }}>
                    <div className={`container-xxl ${styles.msg_list_wrap}`}>
                        <div className={`${styles.loading_wrap}`}>
                            <Spin spinning={loading} indicator={antIcon} />
                        </div>
                        <ul className="list-unstyled py-4">
                            {messages.map((item) => {
                                if (item._sender.userId === 'eggchat_bot_1') {
                                    return null;
                                } else {
                                    return <Message key={item.messageId} message={item} channel={currentChannel} del={delMessageInBoard} handleMessage={handleMesssageObj} handleYyMsg={handleYyMsg} />;
                                }
                            })}
                        </ul>
                        <Loading loading={yyMessageLoading ? 'block' : 'none'} />
                        <div ref={messagesEndRef} />
                    </div>
                </div>
                { quoteBtnView ? <div className={styles.goToQuoteButton} id="goToQuoteButton" onClick={goToQuote}><img src={require('../../../assets/admin/img/goback.png')} alt="" />인용위치로 돌아가기</div> : ''}
                <Write channel={currentChannel} yyMessage={yyMessage} handleDelYyMsg={handleDelYyMsg} />

                <div className="addnew-user-sidebar py-xl-4 py-3 px-xl-4 px-3" style={{ right: tabPos }}>
                    {setTab()}
                </div>
            </div>
        );
    } else {
        chatBody = (
            <div className="chat-body">
                <div className="chat d-flex justify-content-center align-items-center h-100 text-center py-xl-4 py-md-3 py-2">
                    <div className="container-xxl">
                        <div className="avatar lg avatar-bg me-auto ms-auto mb-3">
                            <img className="avatar lg rounded-circle border" src={profileInfo.headerimg ? profileInfo.headerimg : require('../../../assets/admin/img/loading.png')} alt="" />
                        </div>
                        <h5 className="font-weight-bold">{profileInfo.username}</h5>
                        <p>채널 선택하고 채팅을 시작해 보세요.</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="layout" className="theme-cyan">

            <Navigation />

            <div className="sidebar border-end py-xl-4 py-3 px-xl-4 px-3">
                <div className="tab-content">

                    <Channel />

                </div>
            </div>

            <Sidebar setWidth={setChatBodyWidth} />

            <div className="main px-3">{chatBody}</div>
        </div>
    );
}

const mapStateToProps = ({
    channelReducer: { current_channel },
    sendbirdReducer: { sendbird },
    messageReceiveReducer: { message_receive },
    messageDeleteReducer: { message_delete }
}) => {
    return {
        current_channel,
        sendbird,
        message_receive,
        message_delete
    }
}

export default connect(mapStateToProps)(Chat);