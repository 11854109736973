export const readReceiptReducer = (prevState={read_receipt:null}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_read_receipt':
            let newState = {...prevState};
            newState.read_receipt = payload;
            return newState;
        default: 
            return prevState;
    }
}