import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { connect } from 'react-redux';
import { _CONFIRM_ } from '../../../System';
import { ChannelActions } from '../../../actions/admin/ChannelActions';
import { getUserId } from '../../../utils/functions';

function ManagerList(props) {
    const [managerList, setManagerList] = useState([]);//channel中的管理员列表

    useEffect(() => {
        getManagerlist();
    }, [props.current_channel]);// eslint-disable-line react-hooks/exhaustive-deps

    const { confirm } = Modal;

    const getManagerlist = () => {
        const members = props.current_channel ? props.current_channel.members : [];
        let managers = members.filter(item=>item.user_id !== getUserId(props.current_channel));
        setManagerList(managers);
    }

    /**channel中移除管理员 */
    const removeManager = (user_id) => {
        confirm(_CONFIRM_({
            title: '상담원을 삭제하시겠습니까?',
            ok: async () => {
                const channel_url = props.current_channel.channel_url;
                const user_ids = [user_id];

                const res = await ChannelActions.leaveChannel(channel_url, user_ids);
                
                if (res.message) {
                    message.error(res.message);
                    return false;
                }

                message.success('SUCCESS');
            }
        }));
    }

    return (
        <div className="d-flex flex-column">
            <div className="header border-bottom d-flex justify-content-between align-items-center">
                <div>
                    <h6 className="mb-0 font-weight-bold">그룹상담원</h6>
                </div>
                <div>
                    <button className="btn btn-link text-muted close-chat-sidebar" onClick={props.tabHide}><i className="icon iconfont">&#xe64d;</i></button>
                </div>
            </div>
            <div className="body mt-4">
                <ul className="chat-list">
                    {managerList.map(item=>(
                        <li key={item.user_id}>
                            <div className="hover_action">
                                <button  className="btn btn-link text-info" onClick={() => {removeManager(item.user_id)}}><i className="icon iconfont">&#xe601;</i></button>
                            </div>
                            <b className="card">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="avatar me-3">
                                            <img className="avatar rounded-circle" src={item.profile_url !== '' ? item.profile_url : require('../../../assets/admin/img/headerimg.png')} alt="avatar" />
                                        </div>
                                        <div className="media-body overflow-hidden">
                                            <div className="d-flex align-items-center mb-1">
                                                <h6 className="text-truncate mb-0 me-auto">{item.nickname}</h6>
                                            </div>
                                            <div className="text-truncate">
                                                {item.user_id}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b>
                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = ({
    channelReducer: { current_channel }
}) => {
    return {
        current_channel
    }
}

export default connect(mapStateToProps)(ManagerList);