import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import * as echarts from 'echarts';
import Navigation from '../../../components/admin/navigation/Navigation';
import Profile from '../../../components/admin/profile/Profile';
import Sidebar from '../../../components/admin/sidebar/Sidebar';
import '../../../assets/admin/css/custom.css';
import styles from './main.module.css';
import { ChannelActions } from '../../../actions/admin/ChannelActions';
import { MainActions } from '../../../actions/admin/MainActions';
import { mainChartOption } from '../../../utils/functions';
import { Statistic } from 'antd';

function Main(props) {
    const [channels, setChannels] = useState({});
    const [connections, setConnections] = useState({
        ccu: '-',
        ycu: '-',
        mcu: '-',
    });

    const formatter = (value) => <CountUp end={value} duration={1} separator="," />

    useEffect(() => {
        getChannelData();
        getConnectData();
        dailyConnections();
        dailyChat();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //channel 状态统计数据
    const getChannelData = async () => {
        const channelData = await ChannelActions.channelMain();
        setChannels(channelData);
    }

    //当前连接聊天窗数据
    const getConnectData = async () => {
        const connectData = await MainActions.connections();
        setConnections(connectData);
    }

    //每日最高连接数折线图
    const dailyConnections = async () => {
        const dailyConnect = await MainActions.dailyConnect();
        let option = mainChartOption({
            x_name: '날짜',
            y_name: '접속자수',
            x_data: dailyConnect.dates,
            y_data: dailyConnect.connections,
        });

        renderGraph('e-graph-connect', option);
    }

    //每日聊天数量折线图
    const dailyChat = async () => {
        const dailyChat = await MainActions.dailyChat();
        let option = mainChartOption({
            x_name: '날짜',
            y_name: '상담수',
            x_data: dailyChat.dates,
            y_data: dailyChat.chan_num,
        });

        renderGraph('e-graph-chat', option);
    }

    //渲染graph
    const renderGraph = (id, option) => {
        const chartDom = document.getElementById(id);
        if (!chartDom) return false;

        const myChart = echarts.init(chartDom);

        option && myChart.setOption(option);
    }

    return (
        <div id="layout" className="theme-cyan">

            <Navigation />

            <div className="sidebar border-end py-xl-4 py-3 px-xl-4 px-3">
                <div className="tab-content">

                    <Profile />

                </div>
            </div>

            <Sidebar />

            <div className={`main px-xl-5 px-lg-4 px-3 ${styles.main_box}`}>
                <div className="chat-body">

                    <div className={styles.dashboard}>
                        <div className={styles.board_card}>
                            <h2>현재상담</h2>
                            <section>
                                <div className={styles.chat_status_total}>
                                    <Link to="/admin/chat/all">
                                        <i className={`icon iconfont ${styles.total_icon}`}>&#xe643;</i>
                                        <span className={styles.total_num}>전체({channels.total})</span>
                                    </Link>
                                    <Link to="/admin/chat/wait">
                                        <i className={`icon iconfont ${styles.total_icon}`}>&#xe665;</i>
                                        <span className={styles.total_num}>대기중({channels.wait})</span>
                                    </Link>
                                    <Link to="/admin/chat/going">
                                        <i className={`icon iconfont ${styles.total_icon}`}>&#xe626;</i>
                                        <span className={styles.total_num}>진행중({channels.going})</span>
                                    </Link>
                                    <Link to="/admin/chat/end">
                                        <i className={`icon iconfont ${styles.total_icon}`}>&#xe642;</i>
                                        <span className={styles.total_num}>완료({channels.end})</span>
                                    </Link>
                                </div>
                            </section>
                        </div>

                        <div className={styles.board_card}>
                            <h2>전체 접속자수</h2>
                            <section>
                                <ul className={styles.active_total}>
                                    <li>
                                        <Statistic value={connections.ccu} formatter={formatter} />
                                        <span>현재 접속자수</span>
                                    </li>
                                    <li>
                                        <Statistic value={connections.ycu} formatter={formatter} />
                                        <span>어제 접속자수</span>
                                    </li>
                                    <li>
                                        <Statistic value={connections.mcu} formatter={formatter} />
                                        <span>지난달 접속자수</span>
                                    </li>
                                </ul>
                            </section>
                        </div>

                        <div className={styles.board_card}>
                            <h2>매일 최대 접속자수</h2>
                            <section>
                                <div className={styles.e_graph} id="e-graph-connect"></div>
                            </section>
                        </div>

                        <div className={styles.board_card}>
                            <h2>매일 상담수</h2>
                            <section>
                                <div className={styles.e_graph} id="e-graph-chat"></div>
                            </section>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Main;