import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { EggdomeActions } from '../../../../actions/admin/EggdomeAction';
import { addComma, timeFormat } from '../../../../utils/functions';
import { LEAVING_LEVEL, LEAVING_STATE, LIP_ORDER } from './Constants';
import styles from './leaving.module.css';

function LeavingView(props) {
    const [leaving] = useState(props.data.leaving ? props.data.leaving : {});
    const [leavingProduct] = useState(props.data.leaving_product ? props.data.leaving_product : []);
    const [admin] = useState(props.data.admin ? props.data.admin : {});
    const [memo, setMemo] = useState(props.data.memo ? props.data.memo : []);
    const [userLink, setUserLink] = useState('');
    const [memoText, setMemoText] = useState('');

    const HOST = 'https://eggdome.ggook.com';
    const VIEWLINK = HOST + '/views';
    const HOMEPATH = HOST + '/home';

    useEffect(()=>{
        switch (leaving.li_level) {
            case 'import': setUserLink(HOST + '/admin/leaving/list.php?sk=mb_user&sv=' + leaving.mb_user); break;
            case 'deliurl': setUserLink(HOST + '/admin/leaving/list_deliurl.php?sk=mb_user&sv=' + leaving.mb_user); break;
            case 'delivery': setUserLink(HOST + '/admin/leaving/list_delivery.php?sk=mb_user&sv=' + leaving.mb_user); break;
            default:setUserLink('');
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //添加备注
    const saveMemo = async (e) => {
        const param = {
            ad_id: admin.ad_id,
            ad_user: admin.ad_user,
            mb_id: leaving.mb_id,
            mb_user: leaving.mb_user,
            li_no: leaving.li_no,
            li_id: leaving.li_id,
            cm_memo: memoText,
        }

        const res = await EggdomeActions.saveMemo(param);
        if (res) {
            message.success(res.message);
            setMemo([...memo, res.result]);
        }

        document.querySelector('#memo_text').value = '';
    }

    return (
        <div className={styles.leaving}>
            <h2>신청정보</h2>
            <section className={styles.card}>
                <div className={styles.list_item}>
                    <section>
                        <strong>문의유형</strong>
                        <span>{LEAVING_LEVEL[leaving.li_level]}</span>
                    </section>
                    <section>
                        <strong>문의번호</strong>
                        <i className={styles.list_btn} onClick={()=>{window.open(HOST + '/admin/leaving/write.php?id='+leaving.li_id, 0 ,'height=900, width=9999, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, sutus=no')}}>{leaving.li_id}</i>
                    </section>
                    <section>
                        <strong>문의자ID</strong>
                        <i className={styles.list_btn} onClick={()=>{window.open(userLink)}}>{leaving.mb_user}</i>
                    </section>
                    <section>
                        <strong>이름</strong>
                        <span>{leaving.mb_name}</span>
                    </section>
                    <section>
                        <strong>날짜</strong>
                        <span>{timeFormat(leaving.li_ctime, 3)}</span>
                    </section>
                </div>
            </section>

            <h2>현재대행상태</h2>
            <section className={styles.card}>
                <div className={styles.list_item}>
                    <section>
                        <strong>상태</strong>
                        <span>{LEAVING_STATE[leaving.li_state]}</span>
                    </section>
                    <section>
                        {leaving.li_send_budget === 'Y' && <i className={styles.list_btn} onClick={()=>{window.open(HOMEPATH + '/clear/budget.php?id='+leaving.li_id+'&k='+admin.ad_id+'&v='+admin.ad_pass, '_popup', 'height=900,resizable=no,scrollbars=yes, width=1000')}}>견적서확인</i>}
                        {leaving.li_send_invoice === 'Y' && <i className={styles.list_btn} onClick={()=>{window.open(HOMEPATH + '/clear/invoice.php?id='+leaving.li_id+'&k='+admin.ad_id+'&v='+admin.ad_pass, '_popup', 'height=900,resizable=no,scrollbars=yes, width=1000')}}>인보이스확인</i>}
                    </section>
                    <section>
                        <strong>상품구매비</strong>
                        <strong className="red">{addComma(leaving.li_price_kr)}원</strong>
                    </section>
                    <section>
                        <strong>배송비</strong>
                        <span>{leaving.li_price_post > 0 ? addComma(leaving.li_price_post) + '원' : '미산정'}</span>
                    </section>
                </div>
            </section>

            <h2>신청상품목록</h2>
            <ul className={styles.items}>
                {leavingProduct.map(item=>{
                    let options = item.lip_opt.split('&gt;');
                    let optionJSX = options.map((opt, index)=>(
                        <section key={index}>{opt}</section>
                    ));
                    
                    let btnJSX;
                    if (['BB', 'BC'].includes(leaving.li_state) && ['A', 'C'].includes(item.lip_order)) {
                        if (item.lip_express !== '' && item.lip_express_order !== '' && item.lip_express_address2 !== '' && item.lip_order === 'C') {
                            btnJSX = <i className={styles.over_btn} onClick={() => {window.open(VIEWLINK + '/kuaidi100.php?order='+item.lip_express_order+'&id='+admin.ad_id, 100, 100, 'kuaidi100')}}>배송조회</i>;
                        }
                        
                        else if ([item.lip_express, item.lip_express_order, item.lip_express_address2].includes('') && item.lip_order === 'A') {
                            btnJSX = <i className={styles.over_btn} onClick={() => {window.open(HOST + '/admin/leaving/write.php?id='+leaving.li_id, 'newwindow'+item.lip_no ,'height=900, width=9999, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, sutus=no')}}>T/N입력</i>;
                        }
                    }

                    else if (['BC', 'BD', 'BE', 'BF', 'BH', 'BI', 'BJ', 'BK'].includes(leaving.li_state) && ['B', 'D'].includes(item.lip_order)) {
                        btnJSX = <i className={styles.over_btn} onClick={() => {window.open(VIEWLINK + '/files.php?no='+item.lip_id+'&l=enterstock&sid='+admin.ad_id, '_popup', 'height=800,resizable=no,scrollbars=yes, width=1200')}}>실사확인</i>;
                    }

                    else if (['BC', 'BD', 'BE'].includes(leaving.li_state) && item.lip_order === 'E') {
                        btnJSX = <i className={styles.over_btn} onClick={() => {window.open(HOST + '/admin/issue/details.php?id='+item.lip_id, 'height=800,resizable=no,scrollbars=yes, width=1200')}}>이슈확인</i>;
                    }

                    return (
                        <li key={item.lip_no}>
                            <img src={item.lip_image} alt="" />
                            <div className={styles.item_info}>
                                <div className={styles.item_state}>
                                    <em>{LIP_ORDER[item.lip_order]}</em>
                                    <span>{item.lip_code}</span>
                                </div>
                                <h3 title={item.lip_name}>{item.lip_name}</h3>
                                <div className={styles.item_option}>
                                    <div>{optionJSX}</div>
                                    <p className="red">x {item.lip_num}</p>
                                </div>
                            </div>
                            {btnJSX}
                        </li>
                    );
                })}
            </ul>

            <h2>상담메모</h2>
            <ul className={styles.memo_list}>
                {memo.map(item=>(
                    <li>
                        <section>
                            <strong>{item.ad_user}</strong>
                            <span>{timeFormat(item.cm_ctime, 3)}</span>
                        </section>
                        <div>{item.cm_memo}</div>
                    </li>
                ))}
            </ul>
            <section className={styles.memo}>
                <textarea id="memo_text" onInput={(e) => {setMemoText(e.target.value)}} />
                <div className={styles.memo_btn}>
                    <button className="btn btn-primary" onClick={saveMemo}>저장</button>
                    <button onClick={() => {props.reback()}}>돌아가기</button>
                </div>
            </section>
        </div>
    );
}

export default LeavingView;