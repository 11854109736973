import React, { useEffect, useState } from 'react';
import SearchList from './chathistory/SearchList';
import HistoryList from './chathistory/HistoryList';
import Waiting from './chathistory/Waiting';
import styles from './chat.module.css';
import { message } from 'antd';
import { HistoryActions } from '../../../actions/admin/HistoryAction';

function ChatHistory(props) {
    const [keyword, setKeyword] = useState('');
    const [searchMessages, setSearchMessages] = useState([]);
    const [listMessages, setListMessages] = useState([]);
    const [anchorMessageId, setAnchorMessageId] = useState(0);
    const [searchStatus, setSearchStatus] = useState('');

    useEffect(() => {
        document.querySelector('#history-keyword').focus();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**历史消息搜索关键字输入，并返回包含消息内容 */
    const searchMessage = async () => {
        // setSearchStatus('search');
        if (keyword === '') {
            message.warning('검색 Keyword 를 입력해주세요.');
            setSearchStatus('');
            return false;
        }

        const res = await HistoryActions.searchMessages(props.current_channel.channel_url, keyword);

        if (!res || res.results.length === 0) {
            message.warning('검색된 데이터가 없습니다.');
            setSearchStatus('');
            return false;
        }
        
        setSearchMessages(res.results);
        setSearchStatus('search');
    }

    /**sendbird中获取选中消息前后的对话内容 */
    const listMessage = async (message_id) => {
        const res = await HistoryActions.listMessages(props.current_channel.channel_url, message_id);

        if (res) {
            setListMessages(res.messages);
            setSearchStatus('list');
            setAnchorMessageId(message_id);
        }
    }

    /**设置引入的组件 */
    const loadComponent = () => {
        let component;
        switch (searchStatus) {
            case 'search':
                component = <SearchList keyword={keyword} messages={searchMessages} listMessage={listMessage} />;
                break;
            case 'list':
                component = <HistoryList keyword={keyword} messages={listMessages} anchor={anchorMessageId} />;
                break;
            default:
                component = <Waiting />;
        }

        return component;
    }

    /**  键盘事件，enter查询数据 */
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchMessage();
        }
    }

    return (
        <div id="tab-history" role="tabpanel" className={`${styles.tab} scroll-light`}>
            <div className={styles.tab_head}>
                <div className="header border-bottom d-flex justify-content-between align-items-center p-1">
                    <div>
                        <h6 className="mb-0 font-weight-bold">대화기록 검색</h6>
                    </div>
                    <div>
                        <button className="btn btn-link close-sidebar text-muted" onClick={props.tabHide}><i className="icon iconfont">&#xe64d;</i></button>
                    </div>
                </div>
                <div className="body mt-1">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="form-group mt-3 mb-3">
                                <input type="text" value={keyword} className="form-control" id="history-keyword" placeholder="Search..." onInput={(e) => {setKeyword(e.target.value)}} onKeyDown={_handleKeyDown} />
                            </div>
                            <div className="align-right">
                                <button className="btn btn-primary" onClick={searchMessage}>검색</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loadComponent()}

        </div>
    );
}

export default ChatHistory;