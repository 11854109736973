export const messageReceiveReducer = (prevState={message_receive:null}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_message_receive':
            let newState = {...prevState};
            newState.message_receive = payload;
            return newState;
        default: 
            return prevState;
    }
}