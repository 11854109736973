import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, message, Modal, Select, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import DrawLeft from '../../../components/admin/draw/DrawLeft';
import { ManagerActions } from '../../../actions/admin/ManagerActions';

import { _CONFIRM_ } from '../../../System';
import { CommonActions } from '../../../actions/admin/CommonActions';

function Manager(props) {
    const [managerData, setManagerData] = useState([]);//channel type 数据
    const [channelTypeData, setChannelTypeData] = useState([]);//channel type 数据
    const [channelTypeMap, setChannelTypeMap] = useState([]);//channel type 映射表
    const [mode, setMode] = useState('add');//表单模式，add添加，edit编辑
    const [editItem, setEditItem] = useState(null);//待编辑数据
    const [drawOpen, setDrawOpen] = useState(false);//表单打开状态
    const [loading, setLoading] = useState(false);
    const [headerimg, setHeaderimg] = useState(null);
    const formData = useRef(null);

    const { confirm } = Modal;

    let sending = 0;

    useEffect(() => {
        getManagerData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**获取manager数据 */
    const getManagerData = async () => {
        const res = await ManagerActions.list();
        if (res) {
            setManagerData(res.result.managers);

            const _channelType = res.result.types;
            setChannelTypeData(_channelType);

            let _channelTypeMap = {};
            _channelType.forEach((item)=>{
                _channelTypeMap[item.value] = item.label;
            });
            setChannelTypeMap(_channelTypeMap);
        }
    }

    /**打开数据添加表单 */
    const addForm = () => {
        setMode('add');
        setDrawOpen(true);
    }

    /**打开数据编辑表单 */
    const editForm = (item) => {
        setMode('edit');
        setDrawOpen(true);
        setEditItem(item);
        setHeaderimg(item.headerimg);
    }

    /**表单关闭 */
    const closeForm = () => {
        setMode('add');
        setDrawOpen(false);
        setEditItem(null);
        setHeaderimg(null);
    }

    /**表单提交，添加和编辑分别处理 */
    const onSubmit = () => {
        formData.current.validateFields().then(async res => {
            if (sending === 1) return false;
            sending = 1;

            res['headerimg'] = headerimg;

            if (res.mode === 'add') {
                const response = await ManagerActions.store(res);
                if (response) {
                    message.success(response.message);
                    closeForm();
                    setManagerData([...managerData, response.result]);
                }
            } else {
                const response = await ManagerActions.update(res);
                if (response) {
                    message.success(response.message);
                    closeForm();
                    setManagerData(managerData.map(item => item.id === response.result.id ? response.result : item));
                }
            }
        }).catch(err => {
            message.error(err.response.data.message);
        });
    }

    /**数据删除 */
    const delData = (data) => {
        confirm(_CONFIRM_({
            title: '데이터를 삭제하시겠습니까?',
            ok: async () => {
                if (sending === 1) return false;
                sending = 1;

                const res = await ManagerActions.del(data);
                if (res) {
                    message.success(res.message);
                    setManagerData(managerData.filter(item => item.id !== data.id));
                }

                sending = 0;
            }
        }));
    }

    /**文件上传 */
    const uploadProps = {
        multiple: true,
        showUploadList: false,
        beforeUpload: () => false,
        onChange: async (e) => {
            setLoading(true);
            CommonActions.fileUpload(e.file, res => {
                res && res.code === '' && setHeaderimg(res.result.url);
                setLoading(false);
            });
        }
    }

    const form = (
        <Form layout="vertical" hideRequiredMark ref={formData}>
            <Form.Item name="mode" initialValue={mode} hidden={true}><Input /></Form.Item>
            <Form.Item name="id" initialValue={editItem ? editItem.id : ''} hidden={true}><Input /></Form.Item>

            <Form.Item
                name="channel_type_id"
                label="문의유형"
                initialValue={editItem ? editItem.channel_type_id : (channelTypeData.length > 0 ? channelTypeData[0]['value'] : '')}
            >
                <Select options={[...channelTypeData]} />
            </Form.Item>

            <Form.Item
                name="username"
                label="아이디"
                initialValue={editItem ? editItem.username : ''}
                rules={[
                    {
                        required: true,
                        message: '아이디를 입력해주세요',
                    },
                ]}
            >
                <Input placeholder="아이디" disabled={mode === 'edit'} autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="nickname"
                label="닉네임"
                initialValue={editItem ? editItem.nickname : ''}
                rules={[
                    {
                        required: true,
                        message: '닉네임을 입력해주세요',
                    },
                ]}
            >
                <Input placeholder="닉네임" autoComplete="off" />
            </Form.Item>

            <Form.Item
                name="password"
                label="비밀번호"
                initialValue={editItem ? editItem.password : ''}
                rules={mode === 'add'
                ?[
                    {
                        required: true,
                        message: '비밀번호를 입력해주세요',
                    },
                ]
                :[]}
            >
                <Input.Password placeholder={`비밀번호 ${mode === 'edit' ? '(입력하지 않으면 변경하지 않습니다)' : ''}`} autoComplete="off" />
            </Form.Item>

            <Form.Item label="헤더이미지">
                <Upload {...uploadProps} listType="picture-card" className="avatar-uploader">
                    {headerimg ? (
                        <img src={headerimg} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                        <div>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }} >Upload</div>
                        </div>
                    )}
                </Upload>
            </Form.Item>
        </Form>
    );

    return (
        <div className="tab-pane fade show active" id="chat-manager" role="tabpanel">
            <div className="row">
                <div className="col-12">

                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-title mb-0">상담원관리</h6>
                        </div>
                        <div className="list-group custom list-group-flush my-md-3">
                            {managerData.map(item => (
                                <div className="list-group-item" key={item.id}>
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <b className="avatar"><img src={item.headerimg !== '' ? item.headerimg : require('../../../assets/admin/img/headerimg.png')} alt="..." className="avatar rounded-circle" /></b>
                                        </div>
                                        <div className="col-5 ms-2">
                                            <h6 className="mb-1">{item.username}</h6>
                                            <p className="small text-muted mb-0">{item.nickname}</p>
                                        </div>
                                        <div className="col-auto ms-auto mr-md-3">{channelTypeMap[item.channel_type_id]}</div>
                                        <div className="col-auto">
                                            <b className="manager-btn pointer mr8" onClick={() => { editForm(item) }}>
                                                <i className="icon iconfont">&#xe6b0;</i>
                                            </b>
                                            {item.channel_type_id > 0 &&
                                                <b className="manager-btn pointer" onClick={() => { delData(item) }}>
                                                    <i className="icon iconfont">&#xe611;</i>
                                                </b>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-4">
                <b className="btn btn-sm btn-primary" onClick={addForm}>상담원추가</b>
            </p>
            <DrawLeft
                status={drawOpen}
                change={() => { sending = 0 }}
                title={`상담원 ${mode === 'add' ? '추가' : '수정'}`}
                form={form}
                close={closeForm}
                submit={onSubmit}
                btn_name={mode === 'add' ? '추가하기' : '수정하기'}
            />
        </div>
    );
}

export default Manager;