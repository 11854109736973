import { message } from 'antd';
import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';
import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';

export const ManagerActions = {
    /**
     * 获取管理员列表
     * @returns
     */
    list: async () => {
        const response = await Requests.get({
            url: EgchatApi.manager_list,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 存储管理员（sendbird + egchat 后台）
     * @param {*} param 
     * @returns 
     */
    store: async (param) => {
        const resSendbird = await SendbirdReq.post({//sendbird 返回的错误会在页面Manager.js表单中处理
            url: SendbirdApi.create_user,
            datas: {
                user_id: param.username,
                nickname: param.nickname,
                profile_url: param.headerimg,
                issue_access_token: true,
                metadata: {
                    role: "admin"
                }
            }
        });

        const resEgchat = await Requests.post({
            url: EgchatApi.manager_store,
            withAuth: true,
            datas: {
                ...param,
                user_id: resSendbird.user_id,
                sendbird_token: resSendbird.access_token,
            },
        });

        if (resEgchat.code !== '') {
            message.error(resEgchat.message);
            return false;
        }

        return resEgchat;
    },

    /**
     * 更新管理员（sendbird + egchat 后台）
     * @param {*} param 
     * @returns 
     */
    update: async (param) => {
        await SendbirdReq.put({//sendbird 返回的错误会在页面Manager.js表单中处理
            url: SendbirdApi.update_user(param.username),
            datas: {
                nickname: param.nickname,
                profile_url: param.headerimg
            }
        });

        const resEgchat = await Requests.put({
            url: EgchatApi.manager_update(param.id),
            withAuth: true,
            datas: param,
        });

        if (resEgchat.code !== '') {
            message.error(resEgchat.message);
            return false;
        }

        return resEgchat;
    },

    /**
     * 删除管理员（sendbird + egchat 后台）
     * @param {*} param 
     * @returns 
     */
    del: async (param) => {
        const resEgchat = await Requests.delete({
            url: EgchatApi.manager_del(param.id),
            withAuth: true,
            datas: param
        });

        if (resEgchat.code !== '') {
            message.error(resEgchat.message);
            return false;
        }

        try {
            await SendbirdReq.delete({
                url: SendbirdApi.delete_user(param.username)
            });
        } catch (err) {
            message.error(err.response.data.message);
        }

        return resEgchat;
    },

    /**
     * 获取待替换管理员列表
     * @returns
     */
    getReplaceManagers: async () => {
        const response = await Requests.get({
            url: EgchatApi.replace_managers,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        //管理员根据channel类型分组
        let managers = {};
        response.result.forEach(item => {
            let users = managers[item.channel_type_id] || [];
            users.push(item.user_id);
            managers[item.channel_type_id] = users;
        });

        return managers;
    },

    /**
     * 获取所有管理员列表
     * @returns
     */
    getAllManagers: async () => {
        const response = await Requests.get({
            url: EgchatApi.all_managers,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 新增一个用户（非管理员）
     * @param {*} param 
     * @returns 
     */
    createUser: async (param) => {
        return await SendbirdReq.post({//sendbird 返回的错误会在页面Manager.js表单中处理
            url: SendbirdApi.create_user,
            datas: {
                user_id: param.username,
                nickname: param.nickname,
                profile_url: '',
                metadata: {
                    role: "user"
                }
            }
        });
    }
};