export const channelChangeReducer = (prevState={channel_change:null}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_channel_change':
            let newState = {...prevState};
            newState.channel_change = payload;
            return newState;
        default: 
            return prevState;
    }
}