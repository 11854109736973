import React, { useEffect, useState } from 'react';
import Navigation from '../../../components/admin/navigation/Navigation';
import Profile from '../../../components/admin/profile/Profile';
import Sidebar from '../../../components/admin/sidebar/Sidebar';
import Manager from './Manager';
import ChatBot from './ChatBot';
import ChannelType from './ChannelType';
import '../../../assets/admin/css/custom.css';

function Setting(props) {
    const defaultClass = 'nav-link setting-tab';
    const [currentTab, setCurrentTab] = useState('ChannelType');
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        setIsAdmin(token['is_admin'] === 1);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const tabView = (e, tab) => {
        let tabs = document.querySelectorAll('b.setting-tab');
        for (let i=0;i<tabs.length;i++) {
            tabs[i].className = defaultClass;
        }
        e.currentTarget.className += ' active';

        setCurrentTab(tab);
    }

    const setTab = () => {
        let tab;

        switch (currentTab) {
            case 'ChannelType':
                tab = <ChannelType />;
                break;
            case 'ChatBot':
                tab = <ChatBot />;
                break;
            case 'Manager':
                tab = <Manager />;
                break;
            default:
                tab = '';
                break;
        }

        return tab;
    }

    return (
        <div id="layout" className="theme-cyan">

            <Navigation />

            <div className="sidebar border-end py-xl-4 py-3 px-xl-4 px-3">
                <div className="tab-content">

                    <Profile />

                </div>
            </div>

            <Sidebar />

            <div className="main px-xl-5 px-lg-4 px-3">
                <div className="main-body height100">
                    <div className="body-page d-flex py-xl-3 py-2 height100">
                        <div className="container px-0 d-flex flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-overflow page-header-tabs mb-4 mt-md-5 mt-3">
                                        <li className="nav-item pointer"><b className="nav-link setting-tab active" onClick={(e)=>{tabView(e, 'ChannelType')}}>문의유형</b></li>
                                        <li className="nav-item pointer"><b className="nav-link setting-tab" onClick={(e)=>{tabView(e, 'ChatBot')}}>자동응답</b></li>
                                        {isAdmin &&
                                            <li className="nav-item pointer"><b className="nav-link setting-tab" onClick={(e)=>{tabView(e, 'Manager')}}>상담원관리</b></li>
                                        }
                                        
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content scroll-y">

                                {setTab()}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Setting;