import { message } from 'antd';
import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';

export const ChannelTypeActions = {
    /**
     * 获取channel type列表
     * @returns
     */
    list: async () => {
        const response = await Requests.get({
            url: EgchatApi.channel_type_list,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 存储channel type
     * @param {*} param 
     * @returns 
     */
    store: async (param) => {
        const response = await Requests.post({
            url: EgchatApi.channel_type_store,
            withAuth: true,
            datas: param,
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 更新channel type
     * @param {*} param 
     * @returns 
     */
     update: async (param) => {
        const response = await Requests.put({
            url: EgchatApi.channel_type_update(param.id),
            withAuth: true,
            datas: param,
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 删除channel type
     * @param {*} param 
     * @returns 
     */
    del: async (param) => {
        const response = await Requests.delete({
            url: EgchatApi.channel_type_del(param.id),
            withAuth: true,
            datas: param
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    }
};