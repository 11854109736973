import React from 'react';
import { getAdminUrl } from '../functions';

function ConsultLeavingId(props) {
    const messageData = JSON.parse(props.message.data);

    if (messageData === '') {
        return <div className="msg_spec" id={'msg-content-' + props.message.messageId}></div>;
    }

    let adminUrl = getAdminUrl(messageData['consultLeavingId']['leavingLevel'], messageData['consultLeavingId']['leavingId']);

    return (
        <div className="msg_spec" id={'msg-content-' + props.message.messageId}>
            {props.isAdmin
                ? <a className="underline" href={adminUrl} target="_blank" rel="noreferrer">{props.message.message}</a>
                : <strong>{props.message.message}</strong>
            }
        </div>
    );
}

export default ConsultLeavingId;