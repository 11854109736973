import React from 'react';
import styles from './leaving.module.css';

function Waiting(props) {
    return (
        <div className={styles.waiting}>
            <span>Waiting...</span>
        </div>
    );
}

export default Waiting;