import { message } from 'antd';
import { EggdomeApi } from '../../utils/eggdome.api';
import { Requests } from '../../utils/Request';
import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';
import { ManagerActions } from './ManagerActions';

export const EggdomeActions = {
    /**에그돔 문의내역리스트 검색 */
    leavingList: async (param) => {
        const response = await Requests.get({
            url: EggdomeApi.leaving_list,
            datas: param,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**에그돔 문의내역 상세보기 */
    leavingView: async (param) => {
        const response = await Requests.get({
            url: EggdomeApi.leaving_view,
            datas: param,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**에그돔 문의내역 상세보기 */
    saveMemo: async (param) => {
        const response = await Requests.post({
            url: EggdomeApi.save_memo,
            datas: param,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**에그돔 견적서 보내기 */
    sendBudget: async (param) => {
        const response = await Requests.post({
            url: EggdomeApi.send_budget,
            datas: param,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**检测该类型的聊天窗口是否已存在 */
    checkLevel: async (param) => {
        const response = await Requests.get({
            url: EggdomeApi.check_level,
            datas: param,
            withAuth: true
        });

        return response;
    },

    /**更换eggdome数据库里的level类型 publicA / publicB */
    updateLevel: async (param) => {
        const response = await Requests.post({
            url: EggdomeApi.udpate_level,
            datas: param,
            withAuth: true
        });

        return response;
    },

    /**删除eggdome数据库里的channel记录 */
    removeChannel: async (channel_urls) => {
        await Requests.delete({
            url: EggdomeApi.remove_channels,
            datas: {channel_urls: channel_urls},
            withAuth: true
        });
    },

    /**에그돔 chat 정보 */
    chatInfo: async (param) => {
        return await Requests.get({
            url: EggdomeApi.chat_info,
            datas: param,
            withAuth: true
        });
    },

    /**创建一个新的channel */
    createChannel: async (chatInfo) => {
        const PHOTO_MAP = {
            'eggdome':'https://egchat.ggook.com/avatar/avatar_e.png',
            'domeme':'https://egchat.ggook.com/avatar/avatar_e.png',
            'kakao':'https://egchat.ggook.com/avatar/avatar_k.png',
            'cafe24':'https://egchat.ggook.com/avatar/avatar_c.png'
        };

        let channelData = {};
        let user_ids = [chatInfo.mb_id, chatInfo.ad_user, chatInfo.ad_com_code];

        const nickname = chatInfo.mb_name + '('+chatInfo.mb_user+'.'+chatInfo.mb_level+')';

        let userRes;
        try {
            userRes = await ManagerActions.createUser({username: chatInfo.mb_id, nickname: nickname});
        } catch (err) {
            userRes = {};
            userRes['code'] = err.response.data.code;
            userRes['message'] = err.response.data.message;
        }

        if (userRes.code && userRes.code !== 400202) {
            return userRes;
        }

        user_ids = [...user_ids,...chatInfo.ad_user_all]

        user_ids = [...new Set(user_ids)];

        channelData['user_ids'] = user_ids;
        channelData['name'] = nickname;
        channelData['cover_url'] = PHOTO_MAP[chatInfo.mb_level];
        channelData['custom_type'] = 'end';
        channelData['is_distinct'] = true;

        let response;
        try {
            response = await SendbirdReq.post({
                url: SendbirdApi.create_channel,
                datas: channelData
            });
        } catch (err) {
            let datas = {};
            datas['code'] = err.response.data.code;
            datas['message'] = err.response.data.message;
            return datas;
        }

        return response;
    },

    /**에그돔 chat 정보에 c_channel_url 업데이트 */
    updateChannelUrl: async (param) => {
        return await Requests.put({
            url: EggdomeApi.update_channel_url,
            datas: param,
            withAuth: true
        });
    }
};