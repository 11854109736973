export const userInvitedReducer = (prevState={user_invited_channel:null}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_user_invited':
            let newState = {...prevState};
            newState.user_invited_channel = payload;
            return newState;
        default: 
            return prevState;
    }
}