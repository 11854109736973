import { message } from 'antd';
import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';

export const HistoryActions = {
    /**
     * 搜索包含指定字符串的消息
     * @param {*} channel_url 
     * @param {*} keyword 
     * @returns 
     */
    searchMessages: async (channel_url, keyword) => {
        const response =  await SendbirdReq.get({
            url: SendbirdApi.search_messages,
            datas: {
                channel_url: channel_url,
                query: keyword,
                limit: 99
            }
        });

        if (response.code) {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 获取包含指定消息的消息列表（前后各200条）
     * @param {*} channel_url 
     * @param {*} message_id 
     * @returns 
     */
    listMessages: async (channel_url, message_id) => {
        const response =  await SendbirdReq.get({
            url: SendbirdApi.messages(channel_url),
            datas: {
                message_id: message_id,
                prev_limit: 200,
                next_limit: 200
            }
        });

        if (response.code) {
            message.error(response.message);
            return false;
        }

        return response;
    }
};