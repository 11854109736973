//문의상태
export const LEAVING_STATE = {
    'AA': '견적대기',
    'AB': '견적제출보류',
    'AC': '결제대기(견적완료)',
    'AD': '결제실패',
    'AE': '입금확인중',
    'AF': '상품결제완료',
    'AZ': '신청취소(상품)',

    'BA': '미완료신청건',
    'BB': '입고대기',
    'BC': '부분입고',
    'BD': '입고완료(견적대기)',
    'BE': '물류비결제대기',
    'BF': '물류비입금확인중',
    'BG': '물류비결제실패',
    'BH': '물류비결제완료',
    'BI': '출고대기',
    'BJ': '부분출고',
    'BK': '출고완료',
    'BX': '신청취소(배송)',
    'BZ': '배송취소',

    'CA': '배송완료',
    'CB': '전액환불'
};

//문의유형
export const LEAVING_LEVEL = {
    'import': '수입대행',
    'deliurl': '구매대행',
    'delivery': '배송대행'
};

//상태상태
export const LIP_ORDER = {
    'A': '중국내출고대기',
    'C': '중국내배송중',
    'E': '이상처리(답변대기)',
    'F': '이상처리(답변완료)',
    'B': '입고완료(출고작업중)',
    'J': '상품취소(인증필요)',
    'K': '상품취소(납기일 이슈)',
    'L': '상품취소(단가변동)',
    'M': '상품취소(품절)',
    'N': '상품취소',
    'D': '출고대기',
    'Y': '출고완료',
    'Z': '결제완료'
};