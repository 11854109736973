export const channelReducer = (prevState={current_channel:null}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_current_channel':
            let newState = {...prevState};
            newState.current_channel = payload;
            return newState;
        default: 
            return prevState;
    }
}