import { EgchatApi } from '../../utils/egchat.api';
import { getDate } from '../../utils/functions';
import { Requests } from '../../utils/Request';
import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';

export const MainActions = {
    /**
     * 获取管理员信息
     * @returns
     */
    profile: async () => {
        return await Requests.get({
            url: EgchatApi.profile,
            withAuth: true
        });
    },

    /**
     * 获取连接数统计
     * @returns
     */
    connections: async () => {
        let datas = {};

        //获取当前连接数
        const ccuRes = await SendbirdReq.get({
            url: SendbirdApi.ccu
        });

        //获取昨天连接数
        const yeaterday = getDate('yesterday');
        const ycuRes = await SendbirdReq.get({
            url: SendbirdApi.connections,
            datas: {
                time_dimension: 'daily',
                start_year: yeaterday.y,
                start_month: yeaterday.m,
                start_day: yeaterday.d,
                end_year: yeaterday.y,
                end_month: yeaterday.m,
                end_day: yeaterday.d
            }
        });

        //获取上个月连接数
        const lastMonth = getDate('lastMonth');
        const mcuRes = await SendbirdReq.get({
            url: SendbirdApi.connections,
            datas: {
                time_dimension: 'monthly',
                start_year: lastMonth.y,
                start_month: lastMonth.m,
                end_year: lastMonth.y,
                end_month: lastMonth.m,
            }
        });

        datas['ccu'] = ccuRes.ccu;
        datas['ycu'] = ycuRes.peak_connections[0].peak_connections;
        datas['mcu'] = mcuRes.peak_connections[0].peak_connections;

        return datas;
    },

    /**
     * 获取每日最高连接数
     * @returns 
     */
    dailyConnect: async () => {
        let datas = {
            dates: [],
            connections: [],
        };

        const period = 10;
        const dateFrom = getDate(period);
        const dateTo = getDate(1);
    
        const response = await SendbirdReq.get({
            url: SendbirdApi.connections,
            datas: {
                time_dimension: 'daily',
                start_year: dateFrom.y,
                start_month: dateFrom.m,
                start_day: dateFrom.d,
                end_year: dateTo.y,
                end_month: dateTo.m,
                end_day: dateTo.d
            }
        });

        response.peak_connections.forEach((v)=>{
            datas.dates.push(v.date);
            datas.connections.push(v.peak_connections);
        });

        return datas;
    },

    /**
     * 获取每日聊天数量
     * @returns 
     */
    dailyChat: async () => {
        let datas = {
            dates: [],
            chan_num: [],
        };

        const period = 10;
        const response = await Requests.get({
            url: EgchatApi.daily_chat,
            datas: {dates: period},
            withAuth: true
        });

        response.result.forEach((v)=>{
            datas.dates.push(v.date);
            datas.chan_num.push(v.chat_num);
        });

        return datas;
    }
};