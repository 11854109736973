import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginActions } from '../../../actions/admin/LoginActions';
import Loading from '../../../components/loading';
import './login.css';

function Login(props) {
    const navigate = useNavigate();

    const [app, setApp] = useState('eggdome');//平台选择
    const [username, setUsername] = useState('');//用户名
    const [password, setPassword] = useState('');//密码
    const [loading, setLoading] = useState('none');//加载转圈圈

    useEffect(() => {
        //打开页面后如果有登录信息，则跳转到首页
        const token = localStorage.getItem('token');
        if (token) {
            props.set_admin_auth(token);
            navigate('/admin');
        } else {
            props.set_admin_auth(null);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /** 用户登录 */
    const adminLogin = async () => {
        setLoading('block');
        let res = await LoginActions.auth({app, username, password});
        
        if (res) {
            props.set_admin_auth(res.result.token);
            navigate('/admin');
        } else {
            setLoading('none');
        }
    }

    /** 键盘回车执行用户登录 */
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            adminLogin();
        }
    }

    return (
        <div id="layout" className="theme-blush">
            <div className="authentication">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center no-gutters min-vh-100">
                        <div className="col-12 col-md-7 col-lg-5 col-xl-5 py-md-11">
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">

                                    <h3 className="login-logo">
                                        <img src={require('../../../assets/common/img/logo.png')} alt="" />
                                    </h3>

                                    <p className="login-title">
                                        <strong>이지챗</strong>
                                        <span>관리자로그인</span>
                                    </p>

                                    <div className="mb-4 mt-3 login-box">
                                        <div className="input-group mb-2">
                                            <select className="form-control form-control-lg bg-grey" onKeyDown={_handleKeyDown} onChange={(e)=>{setApp(e.target.value)}}>
                                                <option value="eggdome">에그돔</option>
                                                <option value="domeme">도매매</option>
                                            </select>
                                        </div>

                                        <div className="input-group mb-2">
                                            <input type="text" className="form-control form-control-lg bg-grey" placeholder="아이디를 입력하세요" onKeyDown={_handleKeyDown} onChange={(e)=>{setUsername(e.target.value)}} />
                                        </div>

                                        <div className="input-group mb-2">
                                            <input type="password" className="form-control form-control-lg bg-grey" placeholder="비밀번호를 입력하세요" onKeyDown={_handleKeyDown} onChange={(e)=>{setPassword(e.target.value)}} />
                                        </div>

                                        <div className="text-center mt-4">
                                            <button className="btn btn-lg btn-primary btn-block" title="" onClick={()=>{adminLogin()}}>로그인</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Loading loading={loading} tip="로그인중..." />
        </div>
    );
}

export default Login;