import { SendbirdApi } from '../../utils/sendbird.api';
import { SendbirdReq } from '../../utils/SendbirdReq';
import { store } from '../../redux/store';
import { Requests } from '../../utils/Request';
import { EgchatApi } from '../../utils/egchat.api';
import { EggdomeActions } from './EggdomeAction';

export const ChannelActions = {
    //首页channel数据列表，和channelListData内容一样，但不能合并（数据请求未完成的情况下页面切换时数据一直叠加，导致数据不准确）
    _channelMainData: [],

    //channel组件的channel数据列表
    _channelListData: [],

    //保存所有channel数据和channel状态统计数据
    _channelAll: {},

    /**获取首页channel状态统计数据 */
    channelMain: async () => {
        const channelData = await ChannelActions._channels(ChannelActions._channelMainData);

        if (channelData.unread > 0) {
            store.dispatch({
                type: 'set_unread',
                payload: true
            });
        }

        return {
            total: channelData.total,
            end: channelData.end,
            wait: channelData.wait,
            going: channelData.going
        };
    },

    /**获取channel 列表 */
    channelList: async () => await ChannelActions._channels(ChannelActions._channelListData, 'true'),

    /**
     * 获取所有channel信息及channel状态的统计数据
     * @param Array listContainer channel数据列表容器
     * @param String custom_types channel聊天状态
     * @param String members channel数据是否带member
     * @param String next_token
     * @param Boolean init 是否初始化容器（用于递归）
     * @returns Array
     */
    _channels: async (listContainer, members = 'false', next_token = '', init = true) => {
        //第一次调用方法时 channelList 属性清空，否则页面切换时数据一直叠加
        if (init) {
            listContainer = [];
            ChannelActions._channelAll = {};
        }

        const s_user = JSON.parse(localStorage.getItem('s_user'));
        // const channel_type = JSON.parse(localStorage.getItem('channel_type'));

        const response = await SendbirdReq.get({
            url: SendbirdApi.my_group_channels(s_user.user_id),
            datas: {
                limit: '100',
                order: 'latest_last_message',
                show_member: members,
                token: next_token
            }
        });

        listContainer.push(...response.channels);

        // if (channel_type.type_id > 0) {
        //     listContainer.push(...response.channels.filter(item => {
        //         if (item.data !== '') {//由于原先eggdome的结构（data值全部为''），分别处理
        //             const datas = item.data.split('|');
        //             return datas[1] === channel_type.type_name;
        //         }
        //         return true;
        //     }));
        // } else {
        //     listContainer.push(...response.channels);
        // }

        if (response.next !== '') {
            await ChannelActions._channels(listContainer, members, response.next, false);
        } else {
            ChannelActions._channelAll['total'] = listContainer.length;

            let endCount = 0;
            let waitCount = 0;
            let goingCount = 0;
            let unreadCount = 0;
            let expired = [];
            const expire_day = 180;
            const expiredMiSec = Date.now() - expire_day * 86400 * 1000;
            const expiredSec = Math.floor(Date.now() / 1000 - expire_day * 86400);
            const currentChannel = store.getState().channelReducer.current_channel;

            listContainer.forEach((v, index) => {
                //统计聊天状态合计
                if (v.custom_type === 'end' || v.custom_type === '') endCount++;
                if (v.custom_type === 'wait') waitCount++;
                if (v.custom_type === 'going') goingCount++;

                //统计未读消息总数
                unreadCount += v.unread_message_count;

                //已过期的channel（180天以上都没有说话的）
                if (
                    (v.last_message && v.last_message.created_at < expiredMiSec)
                    || (!v.last_message && v.created_at < expiredSec)
                ) {
                    expired.push(v.channel_url);
                }

                //当前被点击的channel删除，循环结束后加到channel列表最前面
                if (currentChannel && currentChannel.channel_url === v.channel_url) {
                    listContainer.splice(index, 1);
                }
            });

            if (currentChannel) {
                listContainer.unshift(currentChannel);
            }

            ChannelActions._channelAll['end'] = endCount;
            ChannelActions._channelAll['wait'] = waitCount;
            ChannelActions._channelAll['going'] = goingCount;
            ChannelActions._channelAll['unread'] = unreadCount;
            ChannelActions._channelAll['expired'] = expired;

            ChannelActions._channelAll['channels'] = listContainer;
        }

        return ChannelActions._channelAll;
    },

    /**根据channel_url获取指定channel */
    getChannel: async (channel_url) => {
        let datas = {};
        let response;

        try {
            response = await SendbirdReq.get({
                url: SendbirdApi.group_channel(channel_url),
                datas: { show_member: true }
            });
        } catch (err) {
            datas['code'] = err.response.data.code;
            datas['message'] = err.response.data.message;
            return datas;
        }

        return response;
    },

    /**
     * 所有消息设置为已读
     * @param {*} channel_url 
     * @param {*} users 
     * @returns 
     */
    markAsRead: async (channel_url, users) => {
        if (users.length === 0) return false;

        const user = users.shift();

        await SendbirdReq.put({
            url: SendbirdApi.mark_as_read(channel_url),
            datas: {
                user_id: user.user_id
            }
        });

        ChannelActions.markAsRead(channel_url, users);
    },

    /**
     * 在sendbird中channel状态修改
     * @param {*} channel 
     * @param {*} datas 
     */
    updateChannel: async (channel, datas) => {
        const response = await SendbirdReq.put({
            url: SendbirdApi.group_channels(channel.channel_url),
            datas: datas
        });

        if (datas.custom_type) {
            await ChannelActions.updateChannelState(channel, datas);
        }

        return response;
    },

    /**
     * 本地数据库的channel状态修改
     * @param {*} channel 
     * @param {*} datas 
     */
    updateChannelState: async (channel, datas) => {
        await Requests.post({
            url: EgchatApi.channel_state,
            withAuth: true,
            datas: {
                'channel_url': channel.channel_url,
                'last_message_time': channel.last_message.created_at,
                'custom_type': datas.custom_type
            },
        });
    },

    /**
     * channel中添加管理员
     * @param {*} channel_url
     * @param {*} user_ids 
     */
    inviteChannel: async (channel_url, user_ids) => {
        return await SendbirdReq.post({
            url: SendbirdApi.invite(channel_url),
            datas: { user_ids }
        });
    },

    /**
     * channel中删除管理员
     * @param {*} channel_url
     * @param {*} user_ids 
     */
    leaveChannel: async (channel_url, user_ids) => {
        return await SendbirdReq.put({
            url: SendbirdApi.leave(channel_url),
            datas: { user_ids }
        });
    },

    /**
     * 删除180天以上不说话的channel
     * @param {*} channel_urls 
     */
    removeChannel: async (channel_urls) => {
        for (let i = 0; i < channel_urls.length; i++) {
            await SendbirdReq.delete({
                url: SendbirdApi.delete_channel(channel_urls[i])
            });
        }

        await Requests.delete({
            url: EgchatApi.channel_state_delete,
            withAuth: true,
            datas: {channel_urls: channel_urls}
        });

        //eggdome需要额外删除数据库里的记录
        const token = JSON.parse(localStorage.getItem('token'));
        if (token.application === 'eggdome') {
            await EggdomeActions.removeChannel(channel_urls);
        }
    },

    /**新创建的channel中加入bot */
    botJoinChannel: async (param) => {
        return await SendbirdReq.post({
            url: SendbirdApi.bot_join_channel(param.bot_userid),
            datas: { channel_urls: [param.channel_url] }
        });
    }
};