export const sendbirdReducer = (prevState={sendbird:null, uuid:null}, action) => {
    // console.log(action);
    let {type, payload} = action;

    switch (type) {
        case 'set_sendbird':
            let newSendbird = {...prevState};
            newSendbird.sendbird = payload;
            return newSendbird;
        case 'set_uuid':
            let newUUID = {...prevState};
            newUUID.uuid = payload;
            return newUUID;
        default: 
            return prevState;
    }
}