import { message } from 'antd';
import { EgchatApi } from '../../utils/egchat.api';
import { Requests } from '../../utils/Request';

export const BotMessageActions = {
    /**
     * 获取自动回复列表
     * @returns
     */
    list: async () => {
        const response = await Requests.get({
            url: EgchatApi.bot_message_list,
            withAuth: true
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 存储自动回复
     * @param {*} param 
     * @returns 
     */
    store: async (param) => {
        const response = await Requests.post({
            url: EgchatApi.bot_message_store,
            withAuth: true,
            datas: param,
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 更新自动回复
     * @param {*} param 
     * @returns 
     */
     update: async (param) => {
        const response = await Requests.put({
            url: EgchatApi.bot_message_update(param.id),
            withAuth: true,
            datas: param,
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    },

    /**
     * 删除自动回复
     * @param {*} param 
     * @returns 
     */
    del: async (param) => {
        const response = await Requests.delete({
            url: EgchatApi.bot_message_del(param.id),
            withAuth: true,
            datas: param
        });

        if (response.code !== '') {
            message.error(response.message);
            return false;
        }

        return response;
    }
};