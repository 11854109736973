import axios from 'axios';
import { jsonToUrlParam } from './functions';

export const SendbirdReq = {
    host: () => {
        const sendbirdConfig = JSON.parse(localStorage.getItem('sendbird'));
        return `https://api-${sendbirdConfig.app_id}.sendbird.com/v3`;
    },

    /**
     * 组合 header 数据
     * @param {*} params {extraHeader}
     * @returns Json Object
     */
     headers: (params) => {
        let extraHeader = params.extraHeader ? params.extraHeader : {};
        const sendbirdConfig = JSON.parse(localStorage.getItem('sendbird'));

        return {
            ...extraHeader,
            'Content-Type': 'application/json; charset=utf8',
            'Api-Token': sendbirdConfig.app_token,
        }
    },
    
    /**
     * GET 模式请求 api
     * @param {*} params {url, headers}
     * @returns Json Object
     */
    get: async (params) => {
        let url = params.url;
        if (params.datas) {
            url += '?'+jsonToUrlParam(params.datas)
        }

        let result = await axios.get(
            SendbirdReq.host() + url,
            {headers: SendbirdReq.headers({extraHeader: params.headers})}
        );

        return result.data;
    },

    /**
     * POST 模式请求 api
     * @param {*} params {url, datas, headers}
     * @returns Json Object
     */
    post: async (params) => {
        let result = await axios.post(
            SendbirdReq.host() + params.url,
            params.datas,
            {headers: SendbirdReq.headers({extraHeader: params.headers})}
        );

        return result.data;
    },

    /**
     * PUT 模式请求 api
     * @param {*} params {url, datas, headers}
     * @returns Json Object
     */
    put: async (params) => {
        let result = await axios.put(
            SendbirdReq.host() + params.url,
            params.datas,
            {headers: SendbirdReq.headers({extraHeader: params.headers})}
        );

        return result.data;
    },

    /**
     * DELETE 模式请求 api
     * @param {*} params {url, datas, headers}
     * @returns Json Object
     */
    delete: async (params) => {
        let param = params.datas ? params.datas : {}
        let result = await axios.delete(
            SendbirdReq.host() + params.url,
            {
                data: param,
                headers: SendbirdReq.headers({extraHeader: params.headers})
            }
        );

        return result.data;
    }
}