import { fillZero } from "../../utils/functions";

const ADMIN_PATH = 'https://eggdome.ggook.com/admin';

/**根据天数获取具体日期 */
const getStandTimer = (num) => {
    let standTime = null;
    let thisData = new Date();
    let msec = thisData.getTime() + 86400000 * num;
    let data = new Date(msec)
    let year = data.getFullYear();
    let month = data.getMonth() + 1;
    let day = data.getDate();
    standTime = year + '-' + fillZero(month) + '-' + fillZero(day);
    return standTime;
}

/**获取管理链接地址 */
export const getAdminUrl = (leavingLevel, leavingId) => {
    var nowDate = getStandTimer(0);
    var lastThreeMonthDate = getStandTimer(-90);

    var adminLink = '';
    if (leavingLevel === 'import') {
        adminLink = ADMIN_PATH + '/leaving/list.php?sk=li_id&sv=' + leavingId + '&st1=' + lastThreeMonthDate + '&st2=' + nowDate;
    } else if (leavingLevel === 'deliurl') {
        adminLink = ADMIN_PATH + '/leaving/list_deliurl.php?sk=li_id&sv=' + leavingId + '&st1=' + lastThreeMonthDate + '&st2=' + nowDate;
    } else {
        adminLink = ADMIN_PATH + '/leaving/list_delivery.php?sk=li_id&sv=' + leavingId + '&st1=' + lastThreeMonthDate + '&st2=' + nowDate;
    }

    return adminLink;
}