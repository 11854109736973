import React, { useEffect, useState } from 'react';
import { Dropdown, Image, message } from 'antd';
import { getUserId, messageWithUrl, timestampToTime, addComma } from '../../../utils/functions';
import styles from './chat.module.css';
import { allowedFiles, allowedVideo, EMOJI } from '../../../Constants';
import { ChatActions } from '../../../actions/admin/ChatActions';
import { connect } from 'react-redux';
import ConsultLeavingId from '../../../components/eggdome/messages/ConsultLeavingId';
import Utis from '../../../components/eggdome/messages/Utis';
import ConsultLeavingOverview from '../../../components/eggdome/messages/ConsultLeavingOverview';
import ConsultLeavingProduct from '../../../components/eggdome/messages/ConsultLeavingProduct';

function Message(props) {
    const [currentMessage, setCurrentMessage] = useState(props.message);
    /**处理监听消息发送到group channel */
    useEffect(() => {
        deliveryReceipt();
    }, [props.delivery_receipt]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听消息已读状态 */
    useEffect(() => {
        readReceipt();
    }, [props.read_receipt]);// eslint-disable-line react-hooks/exhaustive-deps

    /**处理监听消息发送到group channel事件 */
    const deliveryReceipt = () => {
        if (!props.delivery_receipt) return false;

        const channel = props.delivery_receipt.channel;
        
        //只处理最后一条消息
        if (!channel.lastMessage || channel.lastMessage.messageId !== currentMessage.messageId) return false;

        //消息已传到group channel则不再处理
        if (currentMessage.readers.length > 0) return false;

        //reader中放进聊天对象以外的用户
        const readers = channel.members.filter(item=>item.userId !== getUserId(props.channel));
        currentMessage.readers = readers;
        setCurrentMessage({...currentMessage});
    }

    /**处理监听消息已读事件 */
    const readReceipt = () => {
        if (!props.read_receipt) return false;

        const channel = props.read_receipt.channel;
        
        //只处理最后一条消息
        if (!channel.lastMessage || channel.lastMessage.messageId !== currentMessage.messageId) return false;

        //readers中包含getUserId()返回的值（当前用户已读该消息），则不再处理
        if (currentMessage.readers.find(item=>item.userId === getUserId(props.channel))) return false;

        const s_user = JSON.parse(localStorage.getItem('s_user'));
        const cacheReaders = channel.cachedReadReceiptStatus;

        //比较当前管理员和用户的已读时间点，不相同则未读
        if (cacheReaders[s_user.user_id] !== cacheReaders[getUserId(props.channel)]) return false;

        currentMessage.readers = channel.members;
        setCurrentMessage({...currentMessage});
    }

    /**判断消息是否属于管理员（管理员的消息放右侧） */
    const isMe = () => {
        //没有发送者直接返回false
        if (!currentMessage._sender) return false;

        //在channel 数据中包含用户id信息，则直接用该用户id判断
        return currentMessage._sender.userId !== getUserId(props.channel);
    }

    /**标记已读状态 */
    const readerCheck = () => {
        if (
            !isMe()
            || !currentMessage.readers
            || currentMessage.readers.length === 0
        ) return '';

        if (!currentMessage.readers.find(item=>item.userId === getUserId(props.channel)))
            return <i className={`icon iconfont ${styles.read_check} ${styles.unread}`}>&#xe6f2;</i>;

        if (currentMessage.readers && currentMessage.readers.find(item=>item.userId === getUserId(props.channel)))
            return <i className={`icon iconfont ${styles.read_check} ${styles.has_read}`}>&#xe6f2;</i>;
    }

    //文本消息
    const textMessage = () => {
        let msg = currentMessage.message;
        msg = messageWithUrl(msg);

        const reg = new RegExp(`${EMOJI.symbolReg}(\\d{3})`, 'g');
        msg = msg ? msg.replace(reg, EMOJI.link(EMOJI.symbol+'$1')) : '';
        
        msg = msg.replace(/\n/g, '<br/>');

        return <div className="msg_spec" id={'msg-content-'+currentMessage.messageId} dangerouslySetInnerHTML={{ __html: msg }} />
    };

    /**文件消息 */
    const fileMessage = () => {
        const fileUrl = currentMessage.plainUrl;
        const fileData = fileUrl.split('/');
        const fileName = fileData[fileData.length - 1];
        const files = fileName.split('.');
        const fileExtension = files[files.length - 1].toLowerCase();

        let msgComponent;
        if (allowedFiles.hasOwnProperty(fileExtension)) {//可下载或可浏览文件
            msgComponent = (
                <a href={fileUrl} target="_blank" rel="noreferrer" className="attachment">
                    <div className="media mt-2">
                        <div className="avatar me-2">
                            <img className={styles.file_type} src={allowedFiles[fileExtension]} alt="" />
                        </div>
                        <div className="media-body overflow-hidden">
                            <h6 className="text-truncate mb-0">{currentMessage.name}</h6>
                            {/* <span className="file-size">2.7 mb</span> */}
                        </div>
                    </div>
                </a>
            );
        } else if (allowedVideo.includes(fileExtension)) {//视频文件
            msgComponent = (
                <div className="attachment right-file">
                    <video width="184" controls>
                        <source src={fileUrl} />
                    </video>
                </div>
            );
        } else {//图片文件
            msgComponent = (
                <div className="attachment right-file">
                    <Image width={120} src={fileUrl} />
                </div>
            );
        }

        return <div className="msg_spec" id={'msg-content-'+currentMessage.messageId}>{msgComponent}</div>;
    }

    /**截图发送的消息 */
    const clipboardImageMessage = () => {
        let imageData = JSON.parse(currentMessage.data);
        let images = imageData.hasOwnProperty('clipboardImages') ? imageData.clipboardImages : imageData;

        return (
            <div className="msg_spec" id={'msg-content-'+currentMessage.messageId}>
                <div className="attachment right-file">
                    {images.map((item, key)=>(
                        <Image key={key} width={120} src={item} />
                    ))}
                </div>
            </div>
        );
    };

    //引用消息 文件类型
    const fileYyMessage = (info) => {
        return (
            <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                <div className={styles.yydis_div}>
                    <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                </div>
                {fileMsgYyComponent(info)}
            </div>
        );
    }
    //引用消息 截图类型
    const clipboardImageYyMessage = (info) => {
        let imgObj = typeof info.data === 'string' ? JSON.parse(info.data) : info.data;
        let images = imgObj.hasOwnProperty('clipboardImages') ? imgObj.clipboardImages : imgObj;
        return (
            <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id}  created_at={info.created_at}>
                <div className={styles.yydis_div}>
                    <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                </div>
                {images.map((item, key_img) => {
                    return (
                        <img key={key_img} src={item} width="80px" alt="" />
                    );
                })}
            </div>
        );
    }

    //引用消息  consultLeavingId类型
    const consultLeavingIdYyMessage = (info) => {
        let msg = info.message || '';
        return (
            <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                <div className={styles.yydis_div}>
                    <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                </div>
                <div>
                    <strong>{msg}</strong>
                </div>
            </div>
        );
    }

    //引用消息  utis类型
    const utisYyMessage = (info) => {
        if (info.data == '' || info.data == undefined) return ''
        const _data = JSON.parse(info.data);
        return (
            <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                <div className={styles.yydis_div}>
                    <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                </div>
                <div className={styles.yyutis}>
                    <img src={_data.image} alt="" />
                    <div className={styles.product_info}>
                        <h4 title={_data.title}>{_data.title}</h4>
                        <section>
                            <div className={styles.product_price}>
                                <strong>{_data.unit_price ? addComma(_data.unit_price) : ''}</strong>
                                {_data.unit_price ? <em>원</em> : ''}
                            </div>
                            {/* <span>x {productData.productNum}</span> */}
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    //引用消息 consultLeavingOverview类型
    const consultLeavingOverviewYyMessage = (info) => {
        const _data = JSON.parse(info.data);
        const overviewData = _data.hasOwnProperty('consultLeavingOverview') && _data['consultLeavingOverview'].hasOwnProperty('leavingOverview') ? _data['consultLeavingOverview']['leavingOverview'] : {};
      return (
          <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
              <div className={styles.yydis_div}>
                  <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
              </div>
              <div className={styles.yyutis}>
                  <div className={styles.overview}>
                      <h3>{info.message}</h3>
                      <div className={styles.overview_list}>
                          <a href="javascript:void(0);">
                              <span>견적대기</span>
                              <strong>{overviewData['AA']}</strong>
                          </a>
                          <a href="javascript:void(0);">
                              <span>상품결제대기</span>
                              <strong>{overviewData['AC']}</strong>
                          </a>
                          <a href="javascript:void(0);">
                              <span>입고대기</span>
                              <strong>{overviewData['BB']}</strong>
                          </a>
                          <a href="javascript:void(0);">
                              <span>물류결제대기</span>
                              <strong>{overviewData['BE']}</strong>
                          </a>
                          <a href="javascript:void(0);">
                              <span>출고완료</span>
                              <strong>{overviewData['BK']}</strong>
                          </a>
                          <a href="javascript:void(0);">
                              <span>미완료신청건</span>
                              <strong>{overviewData['BA']}</strong>
                          </a>
                      </div>
                </div>
              </div>
          </div>
      );
    }
    //引用消息 consultLeavingProduct类型
    const consultLeavingProductYyMessage = (info) => {
        const _data = JSON.parse(info.data);
        const productData = _data.hasOwnProperty('consultLeavingProduct') ? _data['consultLeavingProduct'] : {};
        return (
            <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                <div className={styles.yydis_div}>
                    <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                </div>
                <div className={styles.yyutis}>
                    <img src={productData.productImage} alt="" />
                    <div className={styles.product_info}>
                        <h4 title={productData.productName}>{productData.productName}</h4>
                        <div className={styles.product_opt} title={productData.productOption.replace('&gt;', '\n')}>{productData.productOption}</div>
                        <section>
                            <div className={styles.product_price}>
                                <strong>{productData.productPriceKr ? addComma(productData.productPriceKr) : ''}</strong>
                                {productData.productPriceKr ? <em>원</em> : ''}
                            </div>
                            <span>x {productData.productNum}</span>
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    const textYyMessage = (info) => {
        let msg = info.message || '';
        return  (
            <div className={styles.yydis} onClick={()=>{props.handleYyMsg(info.message_id,info.created_at,currentMessage.messageId)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                <div className={styles.yydis_div}>
                    <span className={styles.yydis_name} style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                </div>
                <div className={styles.basic_msg} dangerouslySetInnerHTML={{ __html: msg.replace(/\n/g, '<br/>') }}></div>
            </div>
        );
    }

    /**
     * 消息列表中 引用 文件类型组件
     */

    const fileMsgYyComponent = (item) => {
        let fileUrl = item.plainUrl || '';
        let fileData = fileUrl.split('/');
        let fileName = fileData[fileData.length - 1];
        let files = fileName.split('.');
        let fileExtension = files[files.length - 1].toLowerCase();
        if (allowedFiles.hasOwnProperty(fileExtension)) {
            return (
                <div className={styles.yy_des}>
                    <img src={allowedFiles[fileExtension]} alt="" />
                    <em>{item.fileName}</em>
                </div>
            );
        } else if (allowedVideo.includes(fileExtension)) {
            return (
                <video width="30" controls>
                    <source src={fileUrl} />
                </video>
            );
        } else {
            return (
                <div>
                    <img src={fileUrl} width="80px" alt="" />
                </div>
            );
        }
    }

    /**判断消息类型，并组合jsx */
    const messageRender = () => {
        // 组合 引用消息
        let yyCompents = '';

        const masklayer =  props.message.mask_show ? <div className={styles.mask_layer}></div> : '';


        if(currentMessage.data !== '' && currentMessage.data !== undefined){
            const msg_data = JSON.parse(currentMessage.data);
            if(msg_data.hasOwnProperty('yyInfo')){
                const info = msg_data.yyInfo;
                switch (info.custom_type) {
                    /**通用消息类型（本地文件，截图） */
                    case 'attachmentFilename': yyCompents = fileYyMessage(info); break;
                    case 'clipboardImages': yyCompents = clipboardImageYyMessage(info); break;

                    /**eggdome专属消息类型 */
                    case 'consultLeavingId': yyCompents = consultLeavingIdYyMessage(info); break;
                    case 'utis': yyCompents = utisYyMessage(info); break;
                    case 'consultLeavingOverview': yyCompents = consultLeavingOverviewYyMessage(info); break;
                    case 'consultLeavingProduct': yyCompents = consultLeavingProductYyMessage(info); break;

                    /**通用消息类型（文本消息） */
                    default: yyCompents = textYyMessage(info); break;
                }
            }
        }


        let msgContent;

        switch (currentMessage.customType) {
            /**通用消息类型（本地文件，截图） */
            case 'attachmentFilename': msgContent = fileMessage(); break;
            case 'clipboardImages': msgContent = clipboardImageMessage(); break;

            /**eggdome专属消息类型 */
            case 'consultLeavingId': msgContent = <ConsultLeavingId isAdmin={true} message={currentMessage} />; break;
            case 'utis': msgContent = <Utis message={currentMessage} />; break;
            case 'consultLeavingOverview': msgContent = <ConsultLeavingOverview isAdmin={true} message={currentMessage} />; break;
            case 'consultLeavingProduct': msgContent = <ConsultLeavingProduct isAdmin={true} message={currentMessage} />; break;

            /**通用消息类型（文本消息） */
            default: msgContent = textMessage(); break;
        }  

        return (
            <div className="message-content border p-3">
                {yyCompents}
                {masklayer}
                {msgContent}
            </div>
        )
        // msgContent;
    }

    /**节点选择并复制 */
    const selectAndCopy = (domNode) => {
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.selectNodeContents(domNode); // 需要选中的dom节点
        selection.addRange(range);
        document.execCommand("Copy");
    }

    /**功能菜单内容和事件 */
    const items = [
        {
            key: '001',
            label: (<b>복사</b>),
            onClick: () => {
                const dom = document.getElementById('msg-content-'+currentMessage.messageId);
                selectAndCopy(dom);
            }
        },
        {
          key: '002',
          label: (<b>인용</b>),
          onClick:  ()=>{
              props.handleMessage(currentMessage);
          }
        },
        {
            key: '003',
            label: (<b>취소하기</b>),
            disabled : !isMe(),
            onClick: async () => {
                const expired = 3600000;//消息可撤回时间为1小时
                
                if (parseInt(currentMessage.createdAt) + expired < Date.now()) {
                    message.error('기한이 지났습니다.');
                    return false;
                }

                const res = await ChatActions.deleteMessage(props.channel.channel_url, currentMessage.messageId);

                res && props.del(currentMessage.messageId);
            }
        }
    ];

    /**管理员发送的消息左侧功能菜单（复制和删除） */
    const dropdown = (
        <div className="dropdown">
            <Dropdown
                className="text-muted ms-1 p-2 text-muted pointer"
                menu={{ items }}
                placement="bottom"
                trigger={['click']}
            >
                <i className="icon iconfont">&#xe606;</i>
            </Dropdown>
        </div>
    );

    return (
        <li className={`d-flex message ${isMe() ? 'right' : ''}`} id={'msg-'+currentMessage.messageId}>

            {!isMe() && 
                <div className="mr-lg-3 me-2">
                    <img className={`sm ${styles.headerimg}`} src={currentMessage._sender.plainProfileUrl !== '' ? currentMessage._sender.plainProfileUrl : props.channel.cover_url} alt="avatar" />
                </div>
            }
            
            <div className="message-body">
                <div className={`date-time text-muted ${isMe() ? 'align-right' : ''}`}>
                    {isMe() ? currentMessage._sender.userId : props.channel.name} {timestampToTime(currentMessage.createdAt)}
                    {readerCheck()}
                </div>
                <div className={`message-row d-flex align-items-center ${isMe() ? 'justify-content-end' : ''}`}>

                    {/*{isMe() && dropdown}*/}
                    {dropdown}

                    {messageRender()}

                </div>
            </div>
        </li>
    );
}

const mapStateToProps = ({
    deliveryReceiptReducer: { delivery_receipt },
    readReceiptReducer: { read_receipt }
}) => {
    return {
        delivery_receipt,
        read_receipt
    }
}

export default connect(mapStateToProps)(Message);