// 把对象转换为url参数
export const jsonToUrlParam = json => Object.keys(json).map(key => key + '=' + json[key]).join('&');

/**
 * 获取某个时间的年月日
 * @param String|int mode 
 * @returns Object
 */
export const getDate = (mode = 0) => {
    const datetime = new Date();

    if (mode.toString() === 'yesterday') {
        datetime.setDate(datetime.getDate() - 1);
    } else if (mode.toString() === 'lastMonth') {
        datetime.setDate(datetime.getMonth() - 1);
    } else if (mode.toString() === 'lastYear') {
        datetime.setDate(datetime.getYear() - 1);
    } else {
        datetime.setDate(datetime.getDate() - parseInt(mode));
    }

    const y = datetime.getFullYear();
    const m = datetime.getMonth() + 1;//获取当前月份的日期 
    const d = datetime.getDate();

    return { y, m, d }
}

/**
 * 首页折线图配置
 * @param Object option 
 * @returns Object
 */
export const mainChartOption = (option) => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        xAxis: {
            type: 'category',
            name: option.x_name,
            boundaryGap: false,
            data: option.x_data,
            axisLabel: {
                rotate: 60,
                margin: 12
            }
        },
        yAxis: {
            type: 'value',
            name: option.y_name,
            axisPointer: {
                snap: true
            }
        },
        series: [
            {
                data: option.y_data,
                type: 'line',
                areaStyle: {}
            }
        ]
    };
}

/**
* 小于10数字前补0
* @param {*} num 
* @returns 
*/
export const fillZero = (num) => {
    return num < 10 ? '0' + num : num + '';
}

/**
 * 数字添加千位分隔符
 * @param {*} num 
 * @returns 
 */
export const addComma = (num) => {
    var symbol = num >= 0 ? 1 : -1;
    num = Math.abs(num);

    var initNum = (num || 0).toString(), result = '', formatNum = '';
    if (initNum.indexOf('.') > -1) formatNum = (num || 0).toString().split('.')
    var _num = formatNum ? formatNum[0] : initNum;
    while (_num.length > 3) {
        result = ',' + _num.slice(-3) + result;
        _num = _num.slice(0, _num.length - 3);
    }
    if (_num) { result = formatNum ? _num + result + '.' + formatNum[1] : _num + result; }

    return symbol === -1 ? '-' + result : result;
}

/**
 * 时间戳转各种时间格式（用于channel列表中最后一条消息的事件）
 * @param {*} timestamp 
 * @returns 
 */
export const timestampToTime = (timestamp) => {
    let date = new Date(timestamp);
    let Y = date.getFullYear() + '';
    let M = (date.getMonth() + 1);
    let D = date.getDate();
    let h = date.getHours();
    let i = date.getMinutes();

    let time = Date.now();
    if (timestamp > time - 5 * 60 * 1000) {//5分钟内
        return '방금';

    } else if (timestamp <= time - 5 * 60 * 1000 && timestamp > time - 60 * 60 * 1000) {//1小时内
        return Math.ceil((time - timestamp) / 1000 / 60) + '분전';

    } else if (timestamp <= time - 60 * 60 * 1000 && timestamp > new Date(new Date().toLocaleDateString()).getTime()) {//今天
        return fillZero(h) + ':' + fillZero(i);

    } else if (timestamp <= new Date(new Date().toLocaleDateString()).getTime() && timestamp > new Date(new Date().toLocaleDateString()).getTime() - 86400 * 1000) {//昨天
        return '어제';

    } else if (timestamp <= new Date(new Date().toLocaleDateString()).getTime() - 86400 * 1000 && timestamp > new Date(new Date().toLocaleDateString()).getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000) {//本周
        return getWeek(new Date(Y + '-' + M + '-' + D));

    } else if (timestamp <= new Date(new Date().toLocaleDateString()).getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000 && timestamp > new Date(new Date().getFullYear(), 0).getTime()) {//今年
        return M + '/' + D;

    } else {
        return Y.slice(-2) + '/' + M + '/' + D;
    }
}

/**
 * 根据时间戳获取星期
 * @param {*} date 
 * @returns 
 */
const getWeek = (date) => {
    let week;
    if (date.getDay() === 0) week = "일요일"
    if (date.getDay() === 1) week = "월요일"
    if (date.getDay() === 2) week = "화요일"
    if (date.getDay() === 3) week = "수요일"
    if (date.getDay() === 4) week = "목요일"
    if (date.getDay() === 5) week = "금요일"
    if (date.getDay() === 6) week = "토요일"
    return week;
}

/**
 * 根据时间戳返回日期
 * @param int timestamp 
 * @param int mode 返回数据模式
 * @returns 
 */
export const timeFormat = (timestamp, mode = 1) => {
    if (!timestamp) return '-';

    let _timestamp = timestamp < 9999999999 ? timestamp * 1000 : timestamp;
    let time = new Date(_timestamp);

    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getDate();
    let h = time.getHours();
    let mm = time.getMinutes();
    let s = time.getSeconds();

    let datas;
    if (mode === 1) {
        datas = y + '-' + fillZero(m) + '-' + fillZero(d);
    } else if (mode === 2) {
        datas = y + '-' + fillZero(m) + '-' + fillZero(d) + ' ' + fillZero(h) + ':' + fillZero(mm) + ':' + fillZero(s);
    } else if (mode === 3) {
        datas = fillZero(m) + '-' + fillZero(d) + ' ' + fillZero(h) + ':' + fillZero(mm);
    }

    return datas;
}

/**
 * 生成channel handler 句柄的唯一id
 * @returns 
 */
export const generateUUID = () => {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
    return uuid;
}

/**
 * 图片加载完成后的事件，初始化消息列表时滚动条滚动到底部，加载更多消息时滚动到指定位置
 * @param {*} callback 
 */
export const loadImage = (callback) => {
    const chatContent = document.getElementById('chat-content');

    if (!chatContent) return false;

    const images = chatContent.querySelectorAll('img');
    images.forEach((item) => {
        item.onload = () => {
            callback && callback();
        }
    });
}

/**
 * 处理消息内容中的所有的html标签转文本并且保留换行
 * @param {*} msg 
 * @returns 
 */
export const messageFormat = (msg) => {
    let _msg = msg;

    _msg = _msg.replace(/<style([\s\S]*?)<\/style>/gi, '');
    _msg = _msg.replace(/<script([\s\S]*?)<\/script>/gi, '');
    _msg = _msg.replace(/<\/div>/ig, '\n');
    _msg = _msg.replace(/<\/p>/ig, '\n');
    _msg = _msg.replace(/<\/h1>/ig, '\n');
    _msg = _msg.replace(/<\/h2>/ig, '\n');
    _msg = _msg.replace(/<\/h3>/ig, '\n');
    _msg = _msg.replace(/<\/h4>/ig, '\n');
    _msg = _msg.replace(/<\/h5>/ig, '\n');
    _msg = _msg.replace(/<\/h6>/ig, '\n');
    _msg = _msg.replace(/<\/p>/ig, '\n');
    _msg = _msg.replace(/<\/li>/ig, '\n');
    _msg = _msg.replace(/<\/dt>/ig, '\n');
    _msg = _msg.replace(/<\/dd>/ig, '\n');
    _msg = _msg.replace(/<\/header>/ig, '\n');
    _msg = _msg.replace(/<\/footer>/ig, '\n');
    _msg = _msg.replace(/<\/section>/ig, '\n');
    _msg = _msg.replace(/<\/article>/ig, '\n');
    _msg = _msg.replace(/<\/ul>/ig, '\n');
    _msg = _msg.replace(/<\/ol>/ig, '\n');
    _msg = _msg.replace(/<\/dl>/ig, '\n');
    // eslint-disable-next-line no-useless-escape
    _msg = _msg.replace(/<br[^>]*[\/]?>/gi, "\n");
    // eslint-disable-next-line no-useless-escape
    _msg = _msg.replace(/<img([^>]*)[\/]?>/gi, "\n$1\n");
    _msg = _msg.replace(/<[^>]+>/ig, '');
    _msg = _msg.replace(/&nbsp;/ig, ' ');

    return _msg;
}

/**
 * 输入框的消息根据图片和文字拆分成数组
 * @param {*} msg 
 * @returns 
 */
export const messageToArray = (msg) => {
    let msgsArr = msg.split('\n');
    let msgsData = [];

    msgsArr.forEach((val, index) => {
        if (val.indexOf('src="') !== -1) {
            //假如消息为图片，则提取图片链接放入到数组
            // eslint-disable-next-line no-useless-escape
            let src = val.match(/src=[\'|\"][^\'|\"]*[\'|\"]/gi);
            let imageUrl = src[0].replace('src="', '').replace('src=\'', '').replace('"', '').replace('\'', '');
            let _obj = {
                type: 'image',
                value: imageUrl
            };

            msgsData.push(_obj);
        } else {
            let nextMsg = msgsArr[index + 1];//下一次即将要处理的消息
            let lastData = msgsData[msgsData.length - 1];//已被处理的上一次消息

            if (val.trim() === '') {
                //文字消息内容为空字符串，并且下一个要处理的消息不为图片，上一个已处理的消息为文字消息，则上一次消息的最后只添加换行\n
                if ((nextMsg && nextMsg.indexOf('src="') === -1) && (lastData && lastData['type'] === 'text')) {
                    let _obj = {
                        type: 'text',
                        value: lastData['value'] + '\n'
                    };
                    msgsData[msgsData.length - 1] = _obj;
                }
            } else {
                if (lastData && lastData['type'] === 'text') {
                    //文字消息不为空字符串，并且上一个已处理的消息为文字消息，则两条消息以换行\n合并
                    let _obj = {
                        type: 'text',
                        value: lastData['value'] + '\n' + val
                    };
                    msgsData[msgsData.length - 1] = _obj;
                } else {
                    //文字消息不为空字符串，并且上一个已处理的消息不为文字消息，则追加该消息
                    let _obj = {
                        type: 'text',
                        value: val
                    };
                    msgsData.push(_obj)
                }
            }
        }
    });

    return msgsData;
}

/**
 * 光标移动到输入框末尾
 * @param {*} contentEditableElement 
 */
export const setEndOfContenteditable = (contentEditableElement) => {
    var range, selection;
    if (document.createRange) {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
    } else if (document.selection) {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
}

/**
 * 转换json对象key，dataKey -> data_key
 * @param {*} data sendbird 的 sdk json数据
 * @returns 
 */
export const formatChannelData = (data) => {
    const blackList = [
        'constructor',
        'cachedDeliveryReceiptStatus',
        'cachedReadReceiptStatus',
        'memberMap',
        '_cachedLastDeliveredReceipt'
    ];
    const rename = {
        'url': 'channel_url'
    };

    let datas = {};

    for (let key in data) {
        if (blackList.includes(key) || typeof (key) !== 'string') continue;

        let val = data[key];

        if (key === 'lastMessage' && data[key]) {
            val = {};
            val['channel_type'] = data[key]['channelType'];
            val['channel_url'] = data[key]['channelUrl'];
            val['created_at'] = data[key]['createdAt'];
            val['custom_type'] = data[key]['customType'];
            val['custom_type'] = data[key]['customType'];
            val['data'] = data[key]['data'];
            val['message'] = data[key]['message'];
            val['message_id'] = data[key]['messageId'];
            val['type'] = data[key]['messageType'];
        }

        if (key === 'members') {
            val = data[key].map(item => {
                return {
                    is_active: item.isActive,
                    is_blocked_by_me: item.isBlockedByMe,
                    is_blocking_me: item.isBlockingMe,
                    is_muted: item.isMuted,
                    is_online: true,
                    last_seen_at: item.lastSeenAt,
                    nickname: item.nickname,
                    profile_url: item.plainProfileUrl,
                    role: item.role,
                    state: item.state,
                    user_id: item.userId
                };
            });
        }

        if (rename[key]) {
            datas[rename[key]] = val;
        } else {
            datas[_toUnderbar(key)] = val;
        }
    }

    return datas;
}

/**
 * 小驼峰格式的字符串转为下划线_格式的字符串，例：dataString -> data_string
 * @param {*} str 
 * @returns 
 */
const _toUnderbar = (str) => {
    let arr = [];

    str.split('').forEach((ele) => {
        if (/[A-Z]+/.test(ele)) {
            arr.push('_' + ele)
        } else {
            arr.push(ele)
        }
    });

    return arr.join("").toLowerCase();
}

/**获取客户端用户sendbird id */
export const getUserId = (channel) => {
    const channelData = channel.data;
    let userId;
    if (channelData.indexOf('|') > -1) {
        let datas = channelData.split('|');
        userId = datas[0];
    } else {//这个逻辑只适用于eggdome
        const member = channel.members.find(item => [26, 27].includes(item.user_id.length));
        userId = member.user_id;
    }

    return userId;
}

/**文本中url添加a标签 */
export const messageWithUrl = (msg) => {
    if (msg === undefined) return '';

    // eslint-disable-next-line no-useless-escape
    let reg = /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
    let result = msg.replace(reg, (item) => {
        return '<a href="' + item + '" target="_blank" style="color:#1a1a1a;">' + item + '</a>';
    });
    return result;
}

/**获取channel头像，先获取用户头像，没有则获取channel的cover_url */
export const getChannelHeaderImage = (channel) => {
    const member = channel.members.find(item => [26, 27].includes(item.user_id.length));

    return member && member.profile_url && member.profile_url !== '' ? member.profile_url : channel.cover_url;
}