import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Chat from '../pages/user/chat/Chat';
import Type from '../pages/user/type/Type';
import Error from '../pages/user/error/Error';

function RouterUser(props) {
    return (
        <Routes>
            <Route exact path="/chat/:channel_url" element={<Chat />} />
            <Route exact path="/type/:chat_info" element={<Type />} />
            <Route exact path="/error/:type" element={<Error />} />
        </Routes>
    );
}

export default RouterUser;