import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';

function Navigation(props) {
    const location = useLocation();
    const [unread, setUnread] = useState(false);

    useEffect(() => {
        resetProps();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        listenUnread();
        listenMessageReceive();
    }, [props.unread, props.message_receive]);// eslint-disable-line react-hooks/exhaustive-deps

    /**进入聊天页面就重置 redux 中的 unread 和 message_receive状态 */
    const resetProps = () => {
        if (location.pathname.indexOf('/admin/chat') > -1) {
            props.setUnread(false);
            props.setMessageReceive(null);
            setUnread(false);
        }
    }

    /**监听首页的未读消息数量（获取channel数据时触发） */
    const listenUnread = () => {
        if (location.pathname === '/admin') {
            setUnread(props.unread);
        }
    }

    /**监听聊天页面以外的页面socket接收消息事件 */
    const listenMessageReceive = () => {
        if (location.pathname.indexOf('/admin/chat') === -1 && props.message_receive) {
            setUnread(true);
        }
    }

    return (
        <div className="navigation navbar justify-content-center py-xl-4 py-md-3 py-0 px-3">

            {/* <Link to="/admin" title="e.g chat" className="brand">
                <img src={require('../../../assets/admin/img/logo.png')} alt="" />
            </Link> */}

            <div className="nav flex-md-column nav-pills flex-grow-1" role="tablist" aria-orientation="vertical">
                <Link to="/admin" className={`mb-xl-3 mb-md-2 nav-link ${location.pathname === '/admin' ? 'active' : ''}`} data-toggle="pill" role="tab">
                    <i className="icon iconfont">&#xe673;</i>
                </Link>
                <Link to="/admin/chat/all" className={`mb-xl-3 mb-md-2 nav-link relative ${location.pathname.indexOf('/admin/chat') !== -1 ? 'active' : ''}`} data-toggle="pill" role="tab">
                    <i className="icon iconfont">&#xe72a;</i>
                    {unread && <em className="nav-badge"></em>}
                </Link>
            </div>

            <div className="nav flex-md-column nav-pills flex-grow-2" role="tablist" aria-orientation="vertical">
                <Link to="/admin/setting" className={`mt-xl-3 mt-md-2 nav-link d-none d-sm-block ${location.pathname === '/admin/setting' ? 'active' : ''}`} role="tab">
                    <i className="icon iconfont">&#xe8b8;</i>
                </Link>
            </div>

            <button className="btn sidebar-toggle-btn shadow-sm"><i className="icon iconfont">&#xe61e;</i></button>
        </div>
    );
}

const mapStateToProps = ({
    unreadMessageReducer: { unread },
    messageReceiveReducer: { message_receive }
}) => {
    return {
        unread,
        message_receive
    }
}

const mapDispatchToProps = {
    setUnread(unread) {
        return {
            type: 'set_unread',
            payload: unread
        }
    },
    setMessageReceive(message_receive) {
        return {
            type: 'set_message_receive',
            payload: message_receive
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);