import React from 'react';
import { addComma } from '../../../utils/functions';
import { getAdminUrl } from '../functions';
import styles from './messages.module.css';

function ConsultLeavingProduct(props) {
    const messageData = JSON.parse(props.message.data);

    let productData = messageData.hasOwnProperty('consultLeavingProduct') ? messageData['consultLeavingProduct'] : {};
    let productUrl = getAdminUrl(messageData['consultLeavingProduct']['leavingLevel'], messageData['consultLeavingProduct']['leavingId']);

    return (
        <div className="msg_spec" id={'msg-content-' + props.message.messageId}>
            <a href={props.isAdmin ? productUrl : productData.productLink} target="_blank" rel="noreferrer" className={styles.product}>
                <img src={productData.productImage} alt="" />
                <div className={styles.product_info}>
                    <h4 title={productData.productName}>{productData.productName}</h4>
                    <div className={styles.product_opt} title={productData.productOption.replace('&gt;', '\n')}>{productData.productOption}</div>
                    <section>
                        <div className={styles.product_price}>
                            <strong>{productData.productPriceKr ? addComma(productData.productPriceKr) : ''}</strong>
                            {productData.productPriceKr ? <em>원</em> : ''}
                        </div>
                        <span>x {productData.productNum}</span>
                    </section>
                </div>
            </a>
        </div>
    );
}

export default ConsultLeavingProduct;