import {Image, Popover, Upload} from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChatActions } from '../../../actions/admin/ChatActions';
import { WordsActions } from '../../../actions/admin/WordsActions';
import { allowedFiles, allowedVideo, allowedMIME, EMOJI } from '../../../Constants';
import { getUserId, messageFormat, messageToArray, setEndOfContenteditable, addComma } from '../../../utils/functions';
import styles from './chat.module.css';
import { connect } from 'react-redux';
import { ChannelActions } from '../../../actions/admin/ChannelActions';
import { InboxOutlined } from '@ant-design/icons';

function Write(props) {
    const [channelDraft, setChannelDraft] = useState({});//草稿箱映射表
    const [emojis, setEmojis] = useState([]);//表情列表
    const [words, setWords] = useState([]);//常用语列表
    const [wordWrapOpen, setWordWrapOpen] = useState(false);//常用语列表显示状态
    const [messageSending, setMessageSending] = useState(false);//消息发送loading显示状态，也防止消息重复发送
    const clipInputRef = useRef(null); //消息输入框

    const params = useParams();
    const navigate = useNavigate();
    const { Dragger } = Upload;

    useEffect(() => {
        getEmojis();
        getWords();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**监听常用语添加和删除 */
    useEffect(() => {
        resetWords(props.word);
    }, [props.word]);// eslint-disable-line react-hooks/exhaustive-deps

    /**监听channel切换事件 */
    useEffect(() => {
        setInputWithDraft();
    }, [props.channel.channel_url]);// eslint-disable-line react-hooks/exhaustive-deps

    /**监听引用消息添加和删除 */
    useEffect(() => {
        if (props.yyMessage){
            clipInputRef.current.focus();
        }
    },[props.yyMessage])


    /**输入框填入草稿箱内容 */
    const setInputWithDraft = () => {
        const draftContent = channelDraft[props.channel.channel_url] || '';
        document.querySelector('#clipInput').innerHTML = draftContent;
    }

    /**重置常用语（监听redux） */
    const resetWords = (word) => {
        if (!word) return false;

        if (word.action === 'add') setWords([word.data, ...words]);

        if (word.action === 'del') setWords(words.filter(item => word.data !== item.id));
    }

    /**获取表情数据 */
    const getEmojis = async () => {
        const res = await ChatActions.emojis();
        setEmojis(res)
    }

    /**获取常用语数据 */
    const getWords = async () => {
        const res = await WordsActions.list();
        setWords(res.result);
    }

    /** 发送基本消息（包含clip image） */
    const sendMsg = async () => {
        if (messageSending) return false;

        const clipInput = document.querySelector('#clipInput');
        const msg = messageFormat(clipInput.innerHTML).trim();

        if (msg === '') return false;

        let yy_obj = {};
        if(props.yyMessage){
            yy_obj.custom_type = props.yyMessage.customType;
            yy_obj.created_at = props.yyMessage.createdAt;
            yy_obj.message_id = props.yyMessage.messageId;
            yy_obj.name = props.yyMessage._sender.userId !== getUserId(props.channel) ? props.yyMessage._sender.userId : props.channel.name;
            yy_obj.message = props.yyMessage.message || '';
            if (props.yyMessage.customType === 'attachmentFilename'){
                yy_obj.plainUrl = props.yyMessage.plainUrl;
                yy_obj.fileName = props.yyMessage.name;
                yy_obj.data = props.yyMessage.data;
            }else if (props.yyMessage.customType === 'clipboardImages'){
                yy_obj.data = JSON.parse(props.yyMessage.data);
            } else{
                yy_obj.data = (props.yyMessage.hasOwnProperty('data') && props.yyMessage.data !== undefined && props.yyMessage.data !== "") ? props.yyMessage.data : '';
            }
            if (yy_obj.data.hasOwnProperty('yyInfo')){
                delete yy_obj.data.yyInfo;
            }
        }

        setMessageSending(true);

        const msgsData = messageToArray(msg);
        // eslint-disable-next-line
        const lastMessage = msgsData[msgsData.length - 1]['value'];//用于发送fcm
        await ChatActions.sendMessage(props.channel.channel_url, msgsData, yy_obj, () => {
            setMessageSending(false);
            if (props.yyMessage){
                props.handleDelYyMsg();
            }
            //清空输入框
            clearInput();
            //重置channel聊天进行状态
            resetChannelState();
            //发送fcm
            // sendFcm(lastMessage);
        });
    }

    /** 发送本地图片 */
    const sendLocalFile = async (files) => {
        setMessageSending(true);
        let fileDatas = [];

        for (let i = 0; i < files.length; i++) {
            if (!allowedMIME.includes(files[i].type)) continue;

            fileDatas.push({
                type: 'file',
                value: files[i],
            });
        }

        if (fileDatas.length === 0) {
            setMessageSending(false);
            return false;
        }

        await ChatActions.sendMessage(props.channel.channel_url, fileDatas, {}, () => {
            setMessageSending(false);
            //清空文件选择表单
            document.querySelector('#fileupload').value = '';
            //重置channel聊天进行状态
            resetChannelState();
            //发送fcm
            // sendFcm('파일메세지를 받았습니다.');
        });
    }

    /**文件拖拽上传 */
    const uploadProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        beforeUpload: () => false,
        onDrop(e) {
            // console.log(e.dataTransfer.files);
            sendLocalFile(e.dataTransfer.files);
        },
    }

    /** 清空输入框 */
    const clearInput = () => {
        const _key = props.channel.channel_url;
        let _channelDraft = { ...channelDraft };
        _channelDraft[_key] = '';
        setChannelDraft(_channelDraft);

        document.querySelector('#clipInput').innerHTML = "";
    }

    /**重新设置channel聊天进行状态 */
    const resetChannelState = () => {
        if (props.channel.custom_type === 'going') return false;

        ChannelActions.updateChannel(props.channel, { custom_type: 'going' });

        //加入菜单筛选中全部或聊天进行中则不再进行跳转
        if (params.state === 'all' || params.state === 'going') return false;

        //跳转到聊天进行中的菜单选项
        navigate('/admin/chat/going');
    }

    /**发送 firebase cloud message */
    // eslint-disable-next-line
    const sendFcm = async (content) => {
        const user_id = getUserId(props.channel);
        const token = JSON.parse(localStorage.getItem('token'));

        await ChatActions.sendFcm({
            application: token.application,
            user_id,
            channel_url: props.channel.channel_url,
            content
        });
    }

    /**
     * 键盘事件，设置 shift+enter为换行，enter为发送消息
     * @param {*} e 
     */
    const _handleKeyDown = (e) => {
        let cursorPosition = getCursorPosition(e.target);
        if (e.key === 'Backspace' && cursorPosition === 0) {
            props.handleDelYyMsg();
            return;
        }

        if (!e.shiftKey && e.key === 'Enter') {
            sendMsg();
        }
    }

    // 获取光标位置的函数
    function getCursorPosition(element) {
        let selection = window.getSelection();
        if (selection.rangeCount > 0) {
            let range = selection.getRangeAt(0);
            if (range.startContainer === element) {
                return range.startOffset;
            }
        }
        return -1;
    }

    /**打开常用语列表框事件 */
    const handleOpenChange = (newOpen) => {
        setWordWrapOpen(newOpen);
    };

    /** 消息输入框中输入（粘贴）内容时，把内容添加到草稿箱 */
    const setDraft = () => {
        const _key = props.channel.channel_url;
        const _val = document.querySelector('#clipInput').innerHTML;

        let _channelDraft = { ...channelDraft };
        _channelDraft[_key] = _val;
        setChannelDraft(_channelDraft);
    }

    const emojiWrap = (
        <div className={styles.emojis}>
            {emojis.map(item => (<b className="pointer" title={EMOJI.symbol + item.key} key={item.key} onClick={() => { addEmoji(item.key) }}><img src={item.url} alt="" /></b>))}
        </div>
    );

    /**输入框添加表情 */
    const addEmoji = (key) => {
        const clipInput = document.querySelector('#clipInput');
        const msg = clipInput.innerHTML.trim();

        clipInput.innerHTML = msg + EMOJI.symbol + key;
        setEndOfContenteditable(clipInput);

        setDraft();
    }

    const wordWrap = (
        <div className={styles.words}>
            {words.map(item => (<b className="pointer" key={item.id} onClick={() => { addWord(item.content) }}>{item.content}</b>))}
        </div>
    );

    /**输入框添加常用语 */
    const addWord = (content) => {
        const clipInput = document.querySelector('#clipInput');
        const msg = clipInput.innerHTML.trim();

        clipInput.innerHTML = msg + content;
        setEndOfContenteditable(clipInput);
        handleOpenChange(false);

        setDraft();
    }

    /**
     * 引用消息类型组件中文件类型组件
     */
    const fileYyComponent = (item) => {
        const fileUrl = item.plainUrl;
        const fileData = fileUrl.split('/');
        const fileName = fileData[fileData.length - 1];
        const files = fileName.split('.');
        const fileExtension = files[files.length - 1].toLowerCase();
        if (allowedFiles.hasOwnProperty(fileExtension)) {
            return (
                <div className={styles.yy_des}>
                    <img src={allowedFiles[fileExtension]} alt="" />
                    <em>{item.name}</em>
                </div>
            );
        } else if (allowedVideo.includes(fileExtension)) {
            return (
                    <video width="30" controls>
                        <source src={fileUrl} />
                    </video>
            );
        } else {
            return (
                    <img src={fileUrl} alt="" width="70px" />
            );
        }
    }

    //引用消息 文件类型
    const fileMessage = (item, name)=>{
        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                {fileYyComponent(item)}
            </div>
        );
    }

    //引用消息 截图类型
    const clipboardImageMessage = (item, name)=>{
        let imageData = JSON.parse(item.data);
        let images = imageData.hasOwnProperty('clipboardImages') ? imageData.clipboardImages : imageData;
        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                {images.map((item, key)=>(
                    <img key={key} width="70px" src={item} />
                ))}
            </div>
        );
    }

    //引用消息 consultLeavingId
    const consultLeavingIdMsg = (item, name)=>{
        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <p class={styles.yydis_context}>{item.message}</p>
            </div>
        );
    }

    //引用消息 utis
    const utisMsg = (item, name)=>{
        const _data = JSON.parse(item.data);

        if (_data === '') return <div key={item.message_id}></div>;

        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className={styles.yyutis}>
                    <img src={_data.image} alt="" />
                    <div className={styles.product_info}>
                        <h4 title={_data.title}>{_data.title}</h4>
                        <section>
                            <div className={styles.product_price}>
                                <strong>{_data.unit_price ? addComma(_data.unit_price) : ''}</strong>
                                {_data.unit_price ? <em>원</em> : ''}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    //引用消息 consultLeavingOverview
    const consultLeavingOverviewMsg = (item, name)=>{

        const _data = JSON.parse(item.data);

        if (_data === '') return <div key={item.message_id}></div>;

    let overviewData = _data.hasOwnProperty('consultLeavingOverview') && _data['consultLeavingOverview'].hasOwnProperty('leavingOverview') ? _data['consultLeavingOverview']['leavingOverview'] : {};


        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className={styles.yyutis}>
                          <div className={styles.overview}>
                                <h3>{item.message}</h3>
                                <div className={styles.overview_list}>
                                    <a href="javascript:void(0);">
                                        <span>견적대기</span>
                                        <strong>{overviewData['AA']}</strong>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <span>상품결제대기</span>
                                        <strong>{overviewData['AC']}</strong>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <span>입고대기</span>
                                        <strong>{overviewData['BB']}</strong>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <span>물류결제대기</span>
                                        <strong>{overviewData['BE']}</strong>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <span>출고완료</span>
                                        <strong>{overviewData['BK']}</strong>
                                    </a>
                                    <a href="javascript:void(0);">
                                        <span>미완료신청건</span>
                                        <strong>{overviewData['BA']}</strong>
                                    </a>
                                </div>
                          </div>
                </div>
            </div>
        );
    }

    //引用消息 consultLeavingProduct
    const consultLeavingProductMsg = (item, name)=>{
        const _data = JSON.parse(item.data);

        if (_data === '') return <div key={item.message_id}></div>;

        let productData = _data.hasOwnProperty('consultLeavingProduct') ? _data['consultLeavingProduct'] : {};


        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className={styles.yyutis}>
                    <img src={productData.productImage} alt="" />
                    <div className={styles.product_info}>
                        <h4 title={productData.productName}>{productData.productName}</h4>
                        <div className={styles.product_opt} title={productData.productOption.replace('&gt;', '\\n')}>{productData.productOption}</div>
                        <section>
                            <div className={styles.product_price}>
                                <strong>{productData.productPriceKr ? addComma(productData.productPriceKr) : ''}</strong>
                                {productData.productPriceKr ? <em>원</em> : ''}
                            </div>
                            <span>x {productData.productNum}</span>
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    //引用消息
    const textMessage = (item, name)=>{
        let msg = item.message || '';
        const reg = new RegExp(`${EMOJI.symbolReg}(\\d{3})`, 'g');
        msg = msg ? msg.replace(reg, EMOJI.link(EMOJI.symbol+'$1')) : '';

        msg = msg.replace(/\n/g, '<br/>');
        return (
            <div className={styles.yy} suppressContentEditableWarning contentEditable="false">
                <div className={styles.yy_div}>
                    <span className={styles.yy_name} style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <strong className={styles.yydis_context} dangerouslySetInnerHTML={{ __html: msg }}></strong>
            </div>
        );
    }

    //组合引用消息类型(显示在输入框内) JSX
    const messageRender = (currentMessage) => {
        let msgContent;
        let name = currentMessage._sender.userId !== getUserId(props.channel) ? currentMessage._sender.userId : props.channel.name;
        switch (currentMessage.customType) {
            /**通用消息类型（本地文件，截图） */
            case 'attachmentFilename': msgContent = fileMessage(currentMessage, name); break;
            case 'clipboardImages': msgContent = clipboardImageMessage(currentMessage, name); break;

            /**eggdome专属消息类型 */
            case 'consultLeavingId': msgContent = consultLeavingIdMsg(currentMessage, name); break;
            case 'utis': msgContent = utisMsg(currentMessage, name); break;
            case 'consultLeavingOverview': msgContent = consultLeavingOverviewMsg(currentMessage, name); break;
            case 'consultLeavingProduct': msgContent = consultLeavingProductMsg(currentMessage, name); break;

            /**通用消息类型（文本消息） */
            default: msgContent = textMessage(currentMessage, name); break;
        }

        return msgContent;
    }

    return (
        <div className="chat-footer pb-3 d-flex align-items-center justify-content-start relative">
            <Dragger {...uploadProps} className={styles.drag_upload_top}></Dragger>
            <div className={`${styles.write}`} style={{ width: '100%' }}>
                <input type="file" id="fileupload" name="fileupload" onChange={(e) => sendLocalFile(e.target.files)} multiple hidden />
                <div className={`${styles.chat_tool}`}>
                    <div className={`${styles.chat_tool_l}`}>
                        <Popover placement="top" content={emojiWrap} trigger="click">
                            <i className="icon iconfont pointer">&#xe644;</i>
                        </Popover>
                        <Popover placement="top" content={wordWrap} trigger="click" open={wordWrapOpen} onOpenChange={handleOpenChange}>
                            <i className="icon iconfont pointer">&#xe620;</i>
                        </Popover>
                        <i className="icon iconfont pointer" onClick={() => { document.getElementById("fileupload").click(); }}>&#xe7dc;</i>
                    </div>
                </div>
                <div className={styles.input_box_all}>
                    {props.yyMessage ? messageRender(props.yyMessage) : ''}
                    <div className={`${styles.write_box}`} id="clipInput" ref={clipInputRef} onKeyDown={_handleKeyDown} contentEditable={true} onInput={() => { setDraft() }}></div>
                </div>
                <span className={`${styles.write_link}`} onClick={() => { sendMsg() }}>
                    <i className="icon iconfont">&#xe60d;</i>
                </span>
            </div>
            <Dragger {...uploadProps}>
                <div style={{ display: 'block', width: '150px', height: '96px' }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">drag file to here</p>
                </div>
            </Dragger>
        </div>
    );
}

const mapStateToProps = ({ wordReducer: { word } }) => {
    return {
        word
    }
}

export default connect(mapStateToProps)(Write);