import React from 'react';
import { message } from 'antd';
import { EggdomeActions } from '../../../../actions/admin/EggdomeAction';
import styles from './leaving.module.css';
import { LEAVING_STATE, LEAVING_LEVEL } from './Constants';
import { timeFormat } from '../../../../utils/functions';

function LeavingList(props) {
    let sending = 0;

    const sendBudget = async (param) => {
        if (sending === 1) return false;
        sending = 1;

        message.loading('견적서 보내는 중...');
        const res = await EggdomeActions.sendBudget(param);
        if (res) {
            message.destroy();
            message.success(res.message)
        }
        sending = 0;
    }

    return (
        <div className={styles.leaving}>
            <h2>신청내역(1개월기준)</h2>
            <ul className={styles.list}>
                {props.datas.map(item=>(
                    <li className={styles.card} key={item.li_id}>
                        <div className={styles.list_item}>
                            <section>
                                <strong>문의유형</strong>
                                <span>{LEAVING_LEVEL[item.li_level]}</span>
                            </section>
                            <section>
                                <strong>문의번호</strong>
                                <span className="pointer underline" onClick={()=>{props.leavingView(item.li_id)}}>{item.li_id}</span>
                            </section>
                            <section>
                                <strong>문의자ID</strong>
                                <span>{item.mb_user}</span>
                            </section>
                            <section>
                                <strong>이름</strong>
                                <span>{item.mb_name}</span>
                            </section>
                            <section>
                                <strong>날짜</strong>
                                <span>{timeFormat(item.li_ctime, 3)}</span>
                            </section>
                            <section>
                                <strong>상태</strong>
                                <span>{LEAVING_STATE[item.li_state]}</span>
                            </section>
                        </div>
                        {item.li_send_budget === 'Y' &&
                        <div className={styles.budget_btn} onClick={() => {sendBudget({li_id: item.li_id})}}>
                            <button>
                                <strong>견적서 보내기</strong>
                            </button>
                        </div>
                        }
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default LeavingList;